import { useMemo, useCallback, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import "react-circular-progressbar/dist/styles.css";

import {
  Button,
  Confirmation,
  Loader,
  Modal,
  Pagination,
  ReactResponsiveTable,
} from "@storybook";
import { ASSET_TYPE, dataFilter, MINT_TABLE_HEADER } from "./store/constant";
import { Sba7Details, RejectModal, History } from "./component";
import { useUserPermission } from "hooks/user-permission";
import { formatNumberWithCommas, useDebounce, useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { Json } from "types";
import { getJsonToParams, getObjectFromUseSearchParams } from "utils";
import { useSearchParams } from "react-router-dom";
import { Filter } from "./component/filter";
import {
  selectedAssetState,
  useMintApis,
  assetListCall,
  initalFilterStaate,
  assetListSbaState,
  assetListLoanState,
} from "./store";

import "./sba.scss";
import { assetCounterChangeState } from "views/mint-asset";

export const Sba7 = (props:any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = useRecoilValue(initalFilterStaate);

  const [openDocNumber, setOpenDocNumber] = useState<any>(-1);
  const [approveVisible, setApprovalVisible] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
  const [auctionHistory, setAuctionHistory] = useState<boolean>(false);
  const buttonDisable = useUserPermission();
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });
  const [errorIndexes, setErrorIndexes] = useState<any[]>([]);
  const [isFilterStateUpdated, setIsFilterStateUpdated] = useState(false);

  const { get: getAsset } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { mintAssetAction, loading: mintAssetActionLoading } = useMintApis();

  const [{ data: assetListSba = [], loading:loadingSba }, setAssetList] =
    useRecoilState(assetListSbaState);
     const [{ data: assetListLoan = [], loading:loadingLoan }, setAssetListLoan] =
       useRecoilState(assetListLoanState);
  const [selectedAsset, setSelectedAsset] = useRecoilState(selectedAssetState);
  const assetApiCall = useRecoilValue(assetListCall);
  const assetCounterChange = useRecoilValue(assetCounterChangeState);

  const assetList = props?.type === ASSET_TYPE ? assetListLoan : assetListSba; 
  const loading = props?.type === ASSET_TYPE ? loadingLoan : loadingSba;   

useEffect(() => {
  if (props?.type) {
    const { immediate, ..._filters } = filters;
    setFilters((prev: any) => ({ ...prev, type: props.type }));
    setSearchParams({ ..._filters, type: props.type });
    setIsFilterStateUpdated(true);
  }
}, [props.type]);

  const handleFetchUserAuction = useCallback(async () => {
   props?.type === ASSET_TYPE ?  setAssetListLoan({ loading: true }) :
      setAssetList({ loading: true });
    const { immediate, ..._filters } = filters;
    setSearchParams({ ..._filters });
    const res = await getAsset(
      API_URL.ASSETS + getJsonToParams({ ..._filters })
    );

    if (res?.apiData?.message === "ok") {
      props?.type === ASSET_TYPE
        ? setAssetListLoan({ loading: false, data: res?.apiData?.data })
        : setAssetList({ loading: false, data: res?.apiData?.data });
    } else {
      props?.type === ASSET_TYPE
        ? setAssetListLoan({ loading: false })
        : setAssetList({ loading: false });
      errorNotification(
        res?.apiData?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters, props?.type]);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(()=>{
  setFilters((prev: any) => ({ ...prev, type: props.type }));
  },[props.type])

  useEffect(() => {
    isFilterStateUpdated && handleFetchUserAuction();
  }, [debouncedFilters, assetApiCall, assetCounterChange?.sba, isFilterStateUpdated]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };
  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  const handelOpenDocList = useCallback(
    (index: number) => {
      openDocNumber === index ? setOpenDocNumber(-1) : setOpenDocNumber(index);
    },
    [openDocNumber]
  );

  const handelRow = useCallback(
    (data: any, e: any) => {
      e.preventDefault();
      const selectedData = assetList.filter(
        (item: any) => item?.id === data?.id
      );
      setSelectedAsset(selectedData?.length ? selectedData[0] : {});
      setApprovalVisible(true);
    },
    [assetList]
  );

  const handelApprove = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setConfirm(true);
    setSelectedAsset(item);
  }, []);

  const handelReject = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setRejectVisible(true);
    setSelectedAsset(item);
  }, []);
  const handelHistory = useCallback((e: any, items: any) => {
    // When we work history we will uncomment this
    e.stopPropagation();
    setAuctionHistory(true);
    setSelectedAsset(items);
  }, []);

  const handeCloseApproval = useCallback(() => {
    setApprovalVisible(false);
  }, []);

  const handeCloseReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const handeCloseHistory = useCallback(() => {
    setAuctionHistory(false);
  }, []);

  const handleImageError = (index: number) => {
    setErrorIndexes((prevErrorIndexes) => [...prevErrorIndexes, index]);
  };

  const tableRows = useMemo(() => {
    if (!assetList) return [];
    const rows: any = [];
    assetList?.forEach((items: any, index: number) => {
      if (items) {
        let row: any = {};
        MINT_TABLE_HEADER.forEach(({ format, key }) => {
          row.id = items.id;
          if (key === "actions") {
            const value = () => (
              <>
                <div className="auction-actions">
                  <div className="history-action">
                    {!items?.readStatus && <div className="history-view"></div>}
                    <i
                      onClick={(e) => handelHistory(e, items)}
                      className="ri-history-line"
                    ></i>
                  </div>

                  {items?.status === "PENDING" && (
                    <>
                      <Button
                        label={"Approve"}
                        className="approve"
                        handleClick={(e) => handelApprove(e, items)}
                        disabled={buttonDisable}
                      />
                      <Button
                        label={"Reject"}
                        className="reject"
                        handleClick={(e) => handelReject(e, items)}
                        disabled={buttonDisable}
                      />
                    </>
                  )}

                  {items?.status === "APPROVED" && (
                    <div className={items?.status}>Approved</div>
                  )}
                  {items?.status === "INPROGRESS" && (
                    <div className={items?.status}>In Progress</div>
                  )}
                  {items?.status === "REJECTED" && (
                    <div className={items?.status}>Rejected</div>
                  )}
                </div>
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx" && key === "numberOfLoans") {
            const value = () => <>{formatNumberWithCommas(items[key])}</>;
            return (row[key] = value);
          }

          if (key === "name") {
            const value = () => (
              <div className="asset-name">
                <div className="asset-image" key={index}>
                  {errorIndexes.includes(index) ? (
                    <div>{items[key]?.charAt(0)}</div>
                  ) : (
                    <img
                      src={items["logo"]}
                      onError={() => handleImageError(index)}
                      alt={`Images ${index + 1}`}
                    />
                  )}
                </div>
                <div>
                  <div className="text-ellipsis">{items[key]}</div>
                  <div className="text-ellipsis asset-symbol">
                    {items["symbol"]}
                  </div>
                </div>
              </div>
            );
            return (row[key] = value);
          }
          if (key === "sbaType") {
            const value = () => (
              <div className="memo-view">
                <button className={items[key]}>{items[key]?.replaceAll("_", " ")?.toLowerCase()}</button>
              </div>
            );
            return (row[key] = value);
          }
          if (key === "issuer") {
            const value = () => (
              <>
                {items["issuerDetails"]?.issuerName}
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [
    assetList,
    buttonDisable,
    errorIndexes,
    handelApprove,
    handelHistory,
    handelOpenDocList,
    handelReject,
    openDocNumber,
  ]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={MINT_TABLE_HEADER}
        height="calc(100vh - 285px)"
        showSearch={false}
        showFilter={false}
        handelRowClick={(data, e) => handelRow(data, e)}
        columnHandle={true}
        isLoading={loading }
      />
    );
  }, [tableRows]);

  const handleShowModal = useCallback(
    (isOpen: boolean, value: boolean) => {
      setConfirm(isOpen);
      if (value) {
        if (mintAssetActionLoading) return;
        mintAssetAction(selectedAsset?.id, {
          status: "APPROVED",
        }).then((res: any) => {
          if (res?.success) {
            successNotification("Asset approved successfully");
            handleFilter({ name: "", offset: 0 });
          } else {
            errorNotification(res?.message);
          }
        });
      }
    },

    [
      errorNotification,
      mintAssetAction,
      mintAssetActionLoading,
      selectedAsset?.id,
      successNotification,
    ]
  );
  const onReset = () => {
    setFilters({ ...initialFilter });
  };

  return (
    <div className="super-admin-wrapper-sba">
      <div className="minted-asset-admin">
        <Filter
          inputValue={filters.name || ""}
          handleInputChange={(value: string) =>
            handleFilter({ name: value, offset: 0 })
          }
          data={dataFilter}
          filters={filters}
          handleFilter={(obj: Json) => handleFilter({ ...obj, offset: 0 })}
          onReset ={onReset}
        />
        {renderSuperAdminTable}
        <Pagination
          showCount={true}
          listCount={assetList?.length}
          page={parseInt(filters.offset)}
          perPage={parseInt(filters.limit)}
          onChangePage={(page) => handlePagination(page, "page")}
          onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
          loading={loading}
        />
      </div>
      <Modal
        isOpen={approveVisible}
        modalName={"d"}
        closeModal={handeCloseApproval}
        className="approval-mint-detail-sba"
      >
        <Sba7Details
          selectedAsset={selectedAsset}
          handeCloseApproval={handeCloseApproval}
          handelReject={handelReject}
          handelApprove={handelApprove}
        />
      </Modal>
      <Modal
        isOpen={rejectVisible}
        modalName={"d"}
        closeModal={handeCloseReject}
        className="reject-auction-detail"
      >
        <RejectModal
          selectedAsset={selectedAsset}
          handelFilter={handleFilter}
          handeCloseReject={handeCloseReject}
        />
      </Modal>
      <Modal
        isOpen={auctionHistory}
        modalName={""}
        className="auction-history"
        closeModal={handeCloseHistory}
      >
        <History />
      </Modal>
      <Confirmation
        handleModal={handleShowModal}
        visible={confirm}
        description="I have  reviewed the assets and gone through the documents. "
        label={mintAssetActionLoading ? <Loader dimension={20} /> : "Approve"}
        title="Are you sure?"
        backLabel="Cancel"
        type="success"
      />
    </div>
  );
};
