// import pdfType from "../../../assets/images/pdfType.png";
import xslType from "../../../assets/images/xslType.png";

export const History = () => {
  return (
    <>
      <div className="history-header">
        {" "}
        <h2>History</h2>
        <p>Some text</p>
      </div>

      <div className="history-log">
        <div className="history--log">
          <div className="history-row">
            <div className="history-left">
              <div className="history-icon">
                <i className="ri-user-line"></i>
              </div>
              <div className="history-line"></div>
            </div>

            <div>
              {" "}
              Prateek has <span className="action">
                requested approval{" "}
              </span>{" "}
              for the MAT Private Fund assets.
              <div className="history-date">October 12, 2023 at 9:05 AM</div>
              <div className="support--doc">
                {" "}
                <div className="doc-img">
                  <div className="doc--img">
                    <img src={xslType} alt="" />
                  </div>
                  <div className="support--doc_name">DetailSheet </div>
                  <div className="dot"></div>2.4MB
                </div>
                <div className="support-action">
                  <button>View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="history--log">
          <div className="history-row">
            <div className="history-left">
              <div className="history-icon">
                <i className="ri-checkbox-circle-line"></i>
              </div>
              <div className="history-line"></div>
            </div>

            <div>
              {" "}
              William has <span className="action">approved</span>the MAT
              Private Fund assets.
              <div className="history-date">Today at 9:05 AM</div>
              <div className="support--doc">
                {" "}
                <div className="doc-img">
                  <div className="doc--img">
                    <img src={xslType} alt="" />
                  </div>
                  <div className="support--doc_name">DetailSheet </div>
                  <div className="dot"></div>2.4MB
                </div>
                <div className="support-action">
                  <button>View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="history--log">
          <div className="history-row">
            <div className="history-left">
              <div className="history-icon">
                <i className="ri-checkbox-circle-line"></i>
              </div>
              <div className="history-line"></div>
            </div>

            <div>
              {" "}
              William has <span className="action">approved</span>the MAT
              Private Fund assets.
              <div className="history-date">Today at 9:05 AM</div>
              <div className="support--doc">
                {" "}
                <div className="doc-img">
                  <div className="doc--img">
                    <img src={xslType} alt="" />
                  </div>
                  <div className="support--doc_name">DetailSheet </div>
                  <div className="dot"></div>2.4MB
                </div>
                <div className="support-action">
                  <button>View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="history--log">
          <div className="history-row">
            <div className="history-left">
              <div className="history-icon">
                <i className="ri-checkbox-circle-line"></i>
              </div>
              <div className="history-line"></div>
            </div>

            <div>
              {" "}
              William has <span className="action">approved</span>the MAT
              Private Fund assets.
              <div className="history-date">Today at 9:05 AM</div>
              <div className="support--doc">
                {" "}
                <div className="doc-img">
                  <div className="doc--img">
                    <img src={xslType} alt="" />
                  </div>
                  <div className="support--doc_name">DetailSheet </div>
                  <div className="dot"></div>2.4MB
                </div>
                <div className="support-action">
                  <button>View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
