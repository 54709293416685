import { Header, Body, Footer, Sidebar, IChildrenProps } from "layouts";

export const DefaultLayout = ({
  children,
  title,
  showBtn,
  btnLabel,
  btnClass = "",
  handleClickBtn,
  disabled,
}: IChildrenProps) => {
  return (
    <div className="layout-container">
      <Sidebar />
      <div>
        {title ? (
          <Header
            title={title}
            showBtn={showBtn}
            btnLabel={btnLabel}
            btnClass={btnClass}
            handleClickBtn={handleClickBtn}
            disabled= {disabled}
          />
        ) : (
          <></>
        )}
        <Body children={children} />
        <Footer />
      </div>
    </div>
  );
};
