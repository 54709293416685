import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Button, Modal } from "@storybook";
import noRoles from "../../assets/images/noRole.svg";

import { RoleDetails } from "./component/roleDetails";
import {
  addRoleState,
  createFormState,
  editRoleState,
  inviteUserState,
  moduleListState,
  newRoleState,
  pervissionViewState,
  rolesListtate,
  rolesTemplatetate,
  userListState,
  templateState,
  allAccessState,
} from "./store";
import { InviteUser } from "./component/inviteUser";
import { RoleCards } from "./component/roleCards";

import "./role.scss";
import { useNetwork } from "hooks";
import { API_URL } from "constant";
import { AddRole } from "./component/addRole";
import { useRolesApi } from "hooks";

export const Roles = () => {
  const [visibleNewRole, setVisibleNewRole] = useRecoilState(newRoleState);
  const [visibleAddRole, setVisibleAddRole] = useRecoilState(addRoleState);
  const [visibleInvite, setVisibleInvite] = useRecoilState(inviteUserState);
  const setUserList = useSetRecoilState(userListState);
  const permissionView = useRecoilValue(pervissionViewState);
  const roleList = useRecoilValue(rolesListtate);
  const templateList = useRecoilValue(rolesTemplatetate);
  const setCreateForm = useSetRecoilState(createFormState);
  const setModuleList = useSetRecoilState(moduleListState);
  const setEditRole = useSetRecoilState(editRoleState);
  const setAllAccess = useSetRecoilState(allAccessState);

  const [tempState, setTempState] = useRecoilState(templateState);
  // const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleState);

  const { get: getUsers, data: userData } = useNetwork();
  const { fetchRoleList, fetchRoleTemplate } = useRolesApi();
  const { get: getModule, data: moduleData } = useNetwork();

  const handelAddNewRole = useCallback(() => {
    setVisibleNewRole(true);
  }, []);

  const closeNewRole = useCallback(() => {
    setVisibleNewRole(false);
  }, []);

  const handelTemplate = useCallback(() => {
    const modifiedForm = {
      name: tempState?.name,
      description: tempState?.description,
      permissions: [],
    };
    const selectedPermission = tempState?.permissions;
    for (let i = 0; i < selectedPermission?.length; i++) {
      const subModuleModify = [];
      const selectedPermissionMdule = selectedPermission[i]?.module;
      for (let j = 0; j < selectedPermissionMdule?.subModules?.length; j++) {
        const subModuleObj = {
          create:
            selectedPermissionMdule?.subModules[j]?.create ??
            selectedPermission[i]?.create,
          view:
            selectedPermissionMdule?.subModules[j]?.view ??
            selectedPermission[i]?.view,
          edit:
            selectedPermissionMdule?.subModules[j]?.edit ??
            selectedPermission[i]?.edit,
          subModuleId: selectedPermissionMdule?.subModules[j]?._id,
        };
        subModuleModify.push(subModuleObj);
      }
      const permissionModify = {
        ...selectedPermission[i],
        module: selectedPermission[i]?.module?._id,
        subModules: subModuleModify,
      };
      delete permissionModify._id;
      (modifiedForm?.permissions as any)?.push(permissionModify);
    }
    setCreateForm(modifiedForm);
    setVisibleNewRole(false);
    setVisibleAddRole(true);
    setAllAccess(tempState?.hasAllAccess);
  }, [
    setAllAccess,
    setCreateForm,
    setVisibleAddRole,
    setVisibleNewRole,
    tempState?.description,
    tempState?.hasAllAccess,
    tempState?.name,
    tempState?.permissions,
  ]);

  const handelSelectTemplate = useCallback((role: any) => {
    // setCreateForm(role);
    setTempState(role);
  }, []);

  const closeAddRole = useCallback(() => {
    setCreateForm({});
    setVisibleAddRole(false);
    setEditRole(false);
  }, []);

  const handelCreateNewRole = useCallback(() => {
    setVisibleNewRole(false);
    setVisibleAddRole(true);
    setAllAccess(false);
  }, []);

  useEffect(() => {
    fetchRoleList();
    fetchRoleTemplate();
    getUsers(API_URL.USERS);
  }, [fetchRoleList, fetchRoleTemplate, getUsers]);

  useEffect(() => {
    if (userData) {
      setUserList(userData.data);
    }
  }, [setUserList, userData]);

  useEffect(() => {
    getModule(API_URL.MODULE);
  }, [getModule]);

  useEffect(() => {
    if (moduleData?.data) {
      setModuleList(moduleData?.data);
    }
  }, [moduleData, setModuleList]);

  const tagsView = useCallback((permisssions: any) => {
    const modifyPermission = permisssions?.filter(
      (permission: any) =>
        permission?.create || permission.edit || permission.view
    );
    return (
      modifyPermission?.length > 0 && (
        <div className="templateRoles">
          <div className="template-role--label not-all-access">
            {modifyPermission[0]?.module?.name}{" "}
            {modifyPermission[0]?.edit ? "edit & view" : "view"}
          </div>

          {modifyPermission?.length - 1 > 0 && (
            <div className="template-role--label not-all-access">
              + {modifyPermission?.length - 1}
            </div>
          )}
        </div>
      )
    );
  }, []);

  return (
    <div className="roles-and-permission">
      {roleList?.data?.length > 0 ? (
        <div className="roles-list">
          {permissionView ? <RoleDetails /> : <RoleCards />}
        </div>
      ) : (
        <div className="roles-and-permission--empty-screen">
          <div>
            <img src={noRoles} alt="" />
            <h2>No roles added</h2>
            <p>You have not added any roles yet.</p>
            <Button
              label={"Add new role"}
              className="button__filled--primary"
              handleClick={handelAddNewRole}
            />
          </div>
        </div>
      )}

      <Modal
        isOpen={visibleNewRole}
        modalName={""}
        closeModal={closeNewRole}
        className="addNewRoleModal"
      >
        <h2>Create new role</h2>
        <p>Some text </p>
        <div className="create-new-wrapper">
          <h3>Create new role from scratch or use predefined role template </h3>
          <p>Some text</p>
          <div className="roles-list">
            <div className="add-new-role" onClick={handelCreateNewRole}>
              <div>
                <div className="plus-symbol">+</div>
                Add new role
              </div>
            </div>
            {templateList?.data.map((role: any, index: number) => {
              return (
                <label
                  className="template-role"
                  htmlFor={`selectedTemplate${index}`}
                >
                  <input
                    name="selectedTemplate"
                    type="radio"
                    style={{ transform: "scale(1.5)" }}
                    id={`selectedTemplate${index}`}
                    onChange={() => handelSelectTemplate(role)}
                  />
                  <div>
                    <h3>{role.name}</h3>
                    <p>{role.description}</p>
                    {role.hasAllAccess ? (
                      <div className="template-role--label">All access</div>
                    ) : (
                      <>{tagsView(role?.permissions)}</>
                    )}
                  </div>
                </label>
              );
            })}

            {/* <div className="template-role">
              <input
                id="primary"
                type="radio"
                style={{ transform: "scale(1.5)" }}
              />
              <div>
                <h3>Manager</h3>
                <p>
                  Permissions to create, modify, and view resources and data for
                  selected services.
                </p>
                <div className="template-role--label not-all-access">
                  User edit & view
                </div>
              </div>
            </div>
            <div className="template-role">
              <input
                id="primary"
                type="radio"
                style={{ transform: "scale(1.5)" }}
              />
              <div>
                <h3>Customer support</h3>
                <p>
                  Permissions to view existing resources and data without
                  affecting the state of selected services.
                </p>
                <div className="template-role--label not-all-access">
                  Accounts edit & view
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-btn">
          <Button
            label={"Cancel"}
            className="button__outline"
            handleClick={closeNewRole}
          />
          <Button
            label={"Next"}
            className="button__filled--primary"
            handleClick={handelTemplate}
          />
        </div>
      </Modal>
      <Modal
        isOpen={visibleAddRole}
        modalName={""}
        closeModal={closeAddRole}
        className="addNewRoleModal"
      >
        <AddRole />
      </Modal>
      <Modal
        isOpen={visibleInvite}
        modalName={""}
        closeModal={() => {
          setVisibleInvite(false);
        }}
        className="InviteUserModal"
      >
        <InviteUser />
      </Modal>
    </div>
  );
};
