import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Button, Confirmation, Loader } from "@storybook";
import {
  inviteRoleId,
  inviteUserState,
  pervissionViewState,
  rolesListtate,
  selectedRoleState,
  userListState,
} from "../store";
import { useNetwork, useNotification, useRolesApi } from "hooks";
import { API_URL } from "constant";
import { useUserPermission } from "hooks/user-permission";

export const RoleCards = () => {
  const {
    remove: removeRole,
    data: removeedRole,
    error: removeRoleError,
    apiResponse,
    isLoaded,
  } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const { fetchRoleList } = useRolesApi();

  const setPermissionView = useSetRecoilState(pervissionViewState);
  const roleList = useRecoilValue(rolesListtate);
  const setSelectedRole = useSetRecoilState(selectedRoleState);
  const userList = useRecoilValue(userListState);
  const setVisibleInvite = useSetRecoilState(inviteUserState);
  const [confirmVisble, setConfirmVisble] = useState(false);

  const [openUserNumber, setOpenUserNumber] = useState<any>(-1);
  const [deleteRoleId, setDeleteRoleId] = useState<number>(0);
  const setRoleId = useSetRecoilState(inviteRoleId);

  const buttonDisable = useUserPermission();

  const handelInvite = useCallback(
    (e: { stopPropagation: () => void }, roleId: number) => {
      e.stopPropagation();
      setVisibleInvite(true);
      setRoleId(roleId);
    },
    []
  );

  const handleClick = useCallback(
    (role: any) => {
      setPermissionView(true);
      setSelectedRole(role);
    },
    [setPermissionView, setSelectedRole]
  );

  const handleDeleteRole = useCallback(
    (isOpen: boolean, value: boolean) => {
      if (value) {
        removeRole(`${API_URL.ROLES}/${deleteRoleId}`)
        .then(() => {
          fetchRoleList()
            .then(() => {
              setConfirmVisble(isOpen);
            });
        });
      } else {
        setConfirmVisble(isOpen);
      }
    },
    [deleteRoleId, fetchRoleList, removeRole]
  );

  const handleShowModal = useCallback((e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setConfirmVisble(true);
    setOpenUserNumber(-1);
  }, []);
  useEffect(() => {
    if (removeedRole?.data && apiResponse?.status === 200) {
      successNotification("Role deleted");
    } else {
      errorNotification(removeedRole?.message);
    }
  }, [apiResponse?.status, errorNotification, removeedRole, successNotification]);
  useEffect(() => {
    if (removeRoleError) {
      errorNotification(removeRoleError?.message);
    }
  }, [errorNotification, removeRoleError]);

  const userData = useCallback(
    (roleId: any) => (
      <>
        {userList?.map((users: any) => {
          return (
            <>
              {users?._id === roleId &&
                users?.users?.map((user: any, index: number) => {
                  return (
                    <>
                      {index < 3 && (
                        <div className="circleName">
                          <div className="circleNameS">
                            {" "}
                            {user.firstName.charAt(0)}
                          </div>
                        </div>
                      )}
                      {index === 3 && (
                        <div className="circleName moreDoc">
                          <div className="circleNameS">+{users?.count - 3}</div>
                        </div>
                      )}
                    </>
                  );
                })}
            </>
          );
        })}
      </>
    ),

    [userList]
  );

  const tagsView = useCallback((permisssions: any) => {
    const modifyPermission = permisssions?.filter(
      (permission: any) =>
        permission?.create || permission.edit || permission.view
    );
    return (
      modifyPermission?.length > 0 && (
        <div className="templateRoles">
          <div className="template-role--label not-all-access">
            {modifyPermission[0]?.module?.name}{" "}
            {modifyPermission[0]?.edit ? "edit & view" : "view"}
          </div>

          {modifyPermission?.length - 1 > 0 && (
            <div className="template-role--label not-all-access">
              + {modifyPermission?.length - 1}
            </div>
          )}
        </div>
      )
    );
  }, []);

  const handelOpenuserList = useCallback(
    (e: { stopPropagation: () => void }, index: number, roleId: number) => {
      e.stopPropagation();
      if (openUserNumber === index) {
        setOpenUserNumber(-1);
        setDeleteRoleId(0);
      } else {
        setOpenUserNumber(index);
        setDeleteRoleId(roleId);
      }
    },
    [openUserNumber]
  );
  return (
    <>
      {roleList?.data?.map((role: any, index: number) => {
        return (
          <div className="role-card" onClick={() => handleClick(role)}>
            <div className="role--card">
              <div>
                <div
                  className={`card-more ${buttonDisable ? "disable": ""}`} 
                >
                  <i className={ `ri-more-2-line ${buttonDisable ? "disablebtn": ""}` } onClick={(e) => handelOpenuserList(e, index, role?._id) }></i>
                </div>
                {index === openUserNumber && (
                  <div className="userAction">
                    <div
                      className="userAction-row"
                      onClick={(e) => handleShowModal(e)}
                    >
                      {" "}
                      <i className="ri-delete-bin-line"></i>
                      Delete Role
                    </div>
                  </div>
                )}
                <h3>{role.name}</h3>
                <p>{role.description}</p>
                {role.hasAllAccess ? (
                  <div className="template-role--label">All access</div>
                ) : (
                  <>{tagsView(role?.permissions)}</>
                )}
              </div>
              <div className="card-footer">
                <div className="circleNames">
                  {userData(role?._id)}
                  {/* <div className="circleName">
                    <div className="circleNameS">R</div>
                  </div>
                  <div className="circleName">
                    <div className="circleNameS">V</div>
                  </div>
                  <div className="circleName">
                    <div className="circleNameS">C</div>
                  </div>
                  <div className="circleName moreDoc">
                    <div className="circleNameS">+5</div>
                  </div> */}
                </div>

                <Button
                  label={"Invite user"}
                  className="button"
                  handleClick={(e) => handelInvite(e, role?._id)}
                  disabled={buttonDisable}
                />
              </div>
            </div>
          </div>
        );
      })}

      <Confirmation
        handleModal={handleDeleteRole}
        visible={confirmVisble}
        description="Deleting this role will remove user’s permissions from the system. "
        boldDescription="Do you still want to delete this role?"
        label={!isLoaded ? <Loader className="loader-white" dimension={20} /> : "Delete"}
        title="Are you sure?"
        backLabel="Cancel"
      />
    </>
  );
};
