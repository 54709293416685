import { useCallback } from "react";
import { AssignedUser } from "./assignedUser";
import { PermissionView } from "./permissionView";
import { useSetRecoilState } from "recoil";
import { pervissionViewState, selectedRoleState } from "../store";

export const RoleDetails = () => {
  const setPermissionView = useSetRecoilState(pervissionViewState);
  const setSelectedRole = useSetRecoilState(selectedRoleState);

  const handleClick = useCallback(() => {
    setSelectedRole({});
    setPermissionView(false);
  }, []);

  return (
    <>
      <div className="role-details">
        <div className="backTo" onClick={handleClick}>
          <span>&lt;</span>Back to Roles and permission
        </div>
        <div className="role--details">
          <PermissionView />
          <AssignedUser />
        </div>
      </div>
    </>
  );
};
