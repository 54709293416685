import { useNetwork } from "hooks";
import { Json } from "types";
import { API_URL } from "constant";
import { useCallback } from "react";
import { getJsonToParams } from "utils";
import { useSetRecoilState } from "recoil";
import { ActivityDataState } from "./state";

const UseActivityApi = () => {
  const { get: getAllActivity, isLoaded } = useNetwork();
  const setActivityData = useSetRecoilState<any>(ActivityDataState);

  const fetchActivity = useCallback((query: Json = {}, options?: any) => {
    setActivityData((prev: Json) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const resData: Json = { loading: false };
    getAllActivity(API_URL.ACTIVITY_LOG + getJsonToParams(query)).then(
      (res: Json) => {
        if (res) {
          if (options.updateNewData === false) {
            setActivityData({ data: res?.apiData?.data });
          } else {
            resData.data = res.data;
            setActivityData((prev: any) => ({
              ...prev,
              loading: false,
              data: [...prev.data, ...res?.apiData?.data],
            }));
          }
        }
      }
    );
  }, []);

  return {
    fetchActivity,
    isLoaded,
  };
};

export default UseActivityApi;
