import { useEffect, useState } from 'react';
import PowerBIReport from './PowerBIReport';
import { Loader } from '@storybook';
import { useNotification, useNetwork } from "../../hooks";
import { API_URL } from "../../constant";

interface ReportData {
  accessToken: string;
  embedUrl: string;
  reportId: string;
  tokenType: string; // '1' for Embed, '0' for Aad
}

export function Revenue() {
  const { errorNotification } = useNotification();
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const {get} = useNetwork();

  // Fetch the embed token and report details
  useEffect(() => {
    const fetchReportData = async () => {
      const response = await get(API_URL.REVENUE);
      const {apiData} = response || {};
      if (apiData?.message === 'ok') {
        // API returns an object with the necessary fields
        const reportDetails: ReportData = {
          accessToken: apiData?.data?.[0]?.embedToken,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${apiData?.data?.[0]?.reportId}&groupId=ca65d343-9835-48d0-bb4a-5bc1e283c345&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtRS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
          reportId: apiData?.data?.[0]?.reportId,
          tokenType: '1', // For example, '1' for Embed
        };
        setReportData(reportDetails);
        setLoading(false);
      } else {
        errorNotification(apiData?.message)
        setLoading(false);
      }
    };

    fetchReportData();
  }, []);

  if (loading) {
    return <Loader className="loader-blue" dimension={22} />;
  }

  return (
    <div className="revenue-container">
      {reportData && (
        <PowerBIReport
          accessToken={reportData.accessToken}
          embedUrl={reportData.embedUrl}
          reportId={reportData.reportId}
          tokenType={reportData.tokenType} // Example: '1' for Embed, '0' for Aad
        />
      )}
    </div>
  );
}


/*
import { RevenueSummary } from "./summary";
import { PrivateEquity } from "./privateEquity";
import { SbaChart } from "./sba";

import "./revenue.scss";
import { SbaClassic } from "./sbaClassic";
import { SbaDutch } from "./sbaDutch";
import { DatePicker } from "@storybook";
import { formatNumberWithCommas } from "hooks";

export const Revenue = () => {
  return (
    <div className="revenue">
      <div className="revenue__calender-btn">
        <DatePicker />
      </div>
      <div className="revenue-summary">
        <RevenueSummary iconClass="dollar" />
        <RevenueSummary
          totalAmount={`$${formatNumberWithCommas("49284.50")}`}
          profit="(+12.2%)"
          icon="ri-coin-fill"
          iconClass="coin"
          revenueLabel= "Trading"
        />
        <RevenueSummary icon="ri-auction-line" iconClass="auction"  revenueLabel= "Auction" />
      </div>

      <div className="revenue-charts">
        <div className="revenue-chart">
          {" "}
          <PrivateEquity />
        </div>
        <div className="revenue-chart">
          <SbaChart />
        </div>
        <div className="revenue-chart">
          <SbaClassic />
        </div>
        <div className="revenue-chart">
          <SbaDutch />
        </div>
      </div>
    </div>
  );
};
*/
