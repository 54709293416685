import { useMemo, useCallback, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "react-circular-progressbar/dist/styles.css";

import {
  Button,
  Confirmation,
  Loader,
  Modal,
  Pagination,
  ReactResponsiveTable,
} from "@storybook";
import { FILE_EXTENSION_URL, MINT_TABLE_HEADER } from "./store/constant";
import pdfType from "../../assets/images/PDF.svg";
import xslType from "../../assets/images/FileType.svg";
import { AuctionDetail, RejectModal, History } from "./component";
import "./auction.scss";
import { useUserPermission } from "hooks/user-permission";
import { formatNumberWithCommas, useDebounce, useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { Json } from "types";
import { getJsonToParams, getObjectFromUseSearchParams } from "utils";
import { useSearchParams } from "react-router-dom";
import { Filter } from "./component/filter";
import {
  selectedAssetState,
  useMintApis,
  assetListCall,
  initalFilterStaate,
  assetListPrivateState,
  assetCounterChangeState,
} from "./store";
import { LockinPeriod } from "./component/lockInPeriod";

export const Private = () => {
  // const [completedAuctions, setCompletedAUctions] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = useRecoilValue(initalFilterStaate);

  const [openDocNumber, setOpenDocNumber] = useState<any>(-1);
  const [approveVisible, setApprovalVisible] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
  const [auctionHistory, setAuctionHistory] = useState<boolean>(false);
  const buttonDisable = useUserPermission();
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });
  const [errorIndexes, setErrorIndexes] = useState<any[]>([]);

  const { get: getAsset } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { mintAssetAction, loading: mintAssetActionLoading } = useMintApis();

  const [{ data: assetList = [], loading }, setAssetList] =
    useRecoilState<Json>(assetListPrivateState);
  const [selectedAsset, setSelectedAsset] = useRecoilState(selectedAssetState);

  const assetApiCall = useRecoilValue(assetListCall);
  const assetCounterChange = useRecoilValue(assetCounterChangeState);
  // const updateAccountStatus = (
  //   e: any,
  //   sessionId: string,
  //   lockAction: boolean
  // ) => {
  //   e.stopPropagation();
  //   if (lockAccountLoading) return;
  //   setAccountLockedId(sessionId);
  //   lockUnlockAccounts(sessionId, {
  //     type: "user",
  //     isLocked: lockAction,
  //   }).then((res: any) => {
  //     if (res?.success) {
  //       handleUpdateAccountList(sessionId, lockAction);
  //     }
  //   });
  // };

  const handleFetchUserAuction = useCallback(async () => {
    setAssetList({ loading: true });
    const { immediate, ..._filters } = filters;
    setSearchParams({ ..._filters });
    const res = await getAsset(
      API_URL.ASSETS + getJsonToParams({ ..._filters })
    );

    if (res?.apiData?.message === "ok") {
      setAssetList({ loading: false, data: res?.apiData?.data });
    } else {
      setAssetList({ loading: false });
      errorNotification(
        res?.apiData?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters]);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(() => {
    handleFetchUserAuction();
  }, [debouncedFilters, assetApiCall, assetCounterChange?.private]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };
  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  const handelOpenDocList = useCallback(
    (index: number) => {
      openDocNumber === index ? setOpenDocNumber(-1) : setOpenDocNumber(index);
    },
    [openDocNumber]
  );

  const handelRow = useCallback(
    (data: any, e: any) => {
      e.preventDefault();

      const selectedData = assetList.filter(
        (item: any) => item?.id === data?.id
      );
      setSelectedAsset(selectedData?.length ? selectedData[0] : {});
      setApprovalVisible(true);
    },
    [assetList]
  );

  const handelApprove = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setConfirm(true);
    setSelectedAsset(item);
  }, []);

  const handelReject = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setRejectVisible(true);
    setSelectedAsset(item);
  }, []);
  const handelHistory = useCallback((e: any, items: any) => {
    // When we work history we will uncomment this
    e.stopPropagation();
    setAuctionHistory(true);
    setSelectedAsset(items);
  }, []);

  const handeCloseApproval = useCallback(() => {
    setApprovalVisible(false);
  }, []);

  const handeCloseReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const handeCloseHistory = useCallback(() => {
    setAuctionHistory(false);
  }, []);

  const handleImageError = (index: number) => {
    setErrorIndexes((prevErrorIndexes) => [...prevErrorIndexes, index]);
  };

  const [lockInStateId, setLockInStateId] = useState(null);

  const handleLockInAsset = useCallback((e: any, items: any) => {
    // When we work history we will uncomment this
    e.stopPropagation();
    setLockInStateId(items);
  }, []);

  const closeLoackInModal = () => {
    setLockInStateId(null);
    handleFetchUserAuction();
   // window.location.reload();
  }

  const tableRows = useMemo(() => {
    if (!assetList) return [];
    const rows: any = [];
    assetList?.forEach((items: any, index: number) => {
      if (items) {
        let row: any = {};
        MINT_TABLE_HEADER.forEach(({ format, key }) => {
          // row.tradeType = items.tradeType ?? "--";
          row.id = items.id;
          // const { status } = items;
          // row.price = 123;
          // const { status } = items;
          if (key === "actions") {
            const value = () => (
              <>
                <div className="auction-actions">
                  <div className="history-action">
                    {!items?.readStatus && <div className="history-view"></div>}
                    <i
                      onClick={(e) => handelHistory(e, items)}
                      className="ri-history-line"
                    ></i>
                  </div>
                  {items?.status === "APPROVED" && (
                    <div className="lock-in-action">
                      <i
                        title="lock in period"
                        onClick={(e) => handleLockInAsset(e, items)}
                        className="ri-settings-5-line"
                      ></i>
                    </div>
                  )}
                  {items?.status === "PENDING" && (
                    <>
                      <Button
                        label={"Approve"}
                        className="approve"
                        handleClick={(e) => handelApprove(e, items)}
                        disabled={buttonDisable}
                      />
                      <Button
                        label={"Reject"}
                        className="reject"
                        handleClick={(e) => handelReject(e, items)}
                        disabled={buttonDisable}
                      />
                    </>
                  )}

                  {items?.status === "APPROVED" && (
                    <div className={items?.status}>Approved</div>
                  )}
                  {items?.status === "REJECTED" && (
                    <div className={items?.status}>Rejected</div>
                  )}
                </div>
              </>
            );
            return (row[key] = value);
          }
          if (
            (format === "jsx" && key === "price") ||
            (format === "jsx" && key === "totalValue") 
          ) {
            const value = () => <>{"$" + formatNumberWithCommas(items[key])}</>;
            return (row[key] = value);
          }
          if ((format === "jsx" && key === "maxSupply")){
            const value = () => <>{formatNumberWithCommas(items[key])}</>;
            return (row[key] = value);
          }
          if (key === "supportingDoc") {
            const value = () => (
              <div className="circleDocs">
                {items["dataRoom"]?.length ? (
                  <>
                    {items["dataRoom"].map((file: any, index: number) => (
                      <>
                        {index < 2 && (
                          <div className="circleDoc">
                            <div className="circleDocS">
                              <img
                                src={
                                  FILE_EXTENSION_URL[
                                    file?.docName
                                      ?.split(".")
                                      .pop()
                                      .toLowerCase()
                                  ]
                                }
                                alt=""
                                data-tooltip-id={file?.id}
                              />
                               <ReactTooltip
                                  className="theme-tooltip"
                                  id={file?.id}
                                  place="top"
                                  content={file?.docName}
                                  style={{ zIndex: 101 }}
                                />
                            </div>
                          </div>
                        )}

                        {index === 2 && (
                          <div
                            className="circleDoc moreDoc"
                            onClick={() => handelOpenDocList(index)}
                          >
                            <div className="circleDocS" data-tooltip-id={file?.id}>
                              +{items["dataRoom"]?.length - 2}
                            </div>
                            <ReactTooltip
                                  className="theme-tooltip"
                                  id={file?.id}
                                  place="top"
                                  content={items["dataRoom"].slice(2).map((file: any) => file.docName).join("\n")}
                                  style={{ zIndex: 101 }}
                                />
                          </div>
                        )}
                        {index === openDocNumber && false && (
                          <div className="moreDoc-list">
                            <div className="arrow"></div>
                            <div className="moreDoc-list-wrapper">
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={pdfType} alt="" />
                                </div>
                                File Name <span></span> 3.2MB
                              </div>
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={xslType} alt="" />
                                </div>
                                File Name <span></span> 5.7MB
                              </div>
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={xslType} alt="" />
                                </div>
                                File Name <span></span> 4.4MB
                              </div>
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={pdfType} alt="" />
                                </div>
                                File Name <span></span> 2.8MB
                              </div>
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={xslType} alt="" />
                                </div>
                                File Name <span></span> 3.1MB
                              </div>
                              <div className="moreDoc--list">
                                <div className="moreDoc--list-img">
                                  <img src={pdfType} alt="" />
                                </div>
                                File Name <span></span> 3.2MB
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  "--"
                )}
              </div>
            );
            return (row[key] = value);
          }
          if (key === "createdBy") {
            const value = () => (
              <>
                {false ? (
                  <div className="circleNames">
                    <div className="circleName">
                      <div className="circleNameS">R</div>
                    </div>

                    <div className="circleName">
                      <div className="circleNameS">V</div>
                    </div>

                    <div className="circleName moreDoc">
                      <div className="circleNameS">+5</div>
                    </div>
                  </div>
                ) : (
                  "--"
                )}
              </>
            );
            return (row[key] = value);
          }
          if (key === "name") {
            const value = () => (
              <div className="asset-name">
                <div className="asset-image" key={index}>
                  {errorIndexes.includes(index) ? (
                    <div>{items[key]?.charAt(0)}</div>
                  ) : (
                    <img
                      src={items["logo"]}
                      onError={() => handleImageError(index)}
                      alt={`Images ${index + 1}`}
                    />
                  )}
                </div>
                <div>
                  <div className="text-ellipsis">{items[key]}</div>
                  <div className="text-ellipsis asset-symbol">
                    {items["symbol"]}
                  </div>
                </div>
              </div>
            );
            return (row[key] = value);
          }
          if (key === "memo") {
            const value = () => (
              <div className="memo-view">
                <button disabled={true}>View</button>
              </div>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [
    assetList,
    buttonDisable,
    errorIndexes,
    handelApprove,
    handelHistory,
    handelOpenDocList,
    handelReject,
    openDocNumber,
  ]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={MINT_TABLE_HEADER}
        height="calc(100vh - 285px)"
        showSearch={false}
        showFilter={false}
        handelRowClick={(data, e) => handelRow(data, e)}
        columnHandle={true}
        isLoading={loading}
      />
    );
  }, [tableRows]);

  const handleShowModal = useCallback(
    (isOpen: boolean, value: boolean) => {
      setConfirm(isOpen);
      if (value) {
        if (mintAssetActionLoading) return;
        mintAssetAction(selectedAsset?.id, {
          status: "APPROVED",
        }).then((res: any) => {
          if (res?.success) {
            successNotification("Asset approved successfully");
            handleFilter({ name: "", offset: 0 });
          } else {
            errorNotification(res?.message);
          }
        });
      }
    },

    [
      errorNotification,
      mintAssetAction,
      mintAssetActionLoading,
      selectedAsset?.id,
      successNotification,
    ]
  );
  return (
    <div className="super-admin-wrapper">
      {/* <Header title="Auction" /> */}
      <div className="minted-asset-admin">
        <Filter
          inputValue={filters.name || ""}
          handleInputChange={(value: string) =>
            handleFilter({ name: value, offset: 0 })
          }
        />
        {renderSuperAdminTable}
        <Pagination
          showCount={true}
          listCount={assetList?.length}
          page={parseInt(filters.offset)}
          perPage={parseInt(filters.limit)}
          onChangePage={(page) => handlePagination(page, "page")}
          onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
          loading={loading}
        />
      </div>
      <Modal
        isOpen={approveVisible}
        modalName={"d"}
        closeModal={handeCloseApproval}
        className="approval-mint-detail"
      >
        <AuctionDetail
          selectedAsset={selectedAsset}
          handeCloseApproval={handeCloseApproval}
          handelReject={handelReject}
          handelApprove={handelApprove}
        />
      </Modal>
      <Modal
        isOpen={rejectVisible}
        modalName={"d"}
        closeModal={handeCloseReject}
        className="reject-auction-detail"
      >
        <RejectModal
          selectedAsset={selectedAsset}
          handelFilter={handleFilter}
          handeCloseReject={handeCloseReject}
        />
      </Modal>
      <Modal
        isOpen={auctionHistory}
        modalName={""}
        className="auction-history"
        closeModal={handeCloseHistory}
      >
        <History />
      </Modal>
      <Modal
        isOpen={!!lockInStateId}
        modalName={""}
        className="auction-lockin"
        closeModal={() => setLockInStateId(null)}
      >
        <LockinPeriod
          selectedAsset={lockInStateId}
          closeLoackInModal={closeLoackInModal}
          closeLoackInModalNoSave={() => setLockInStateId(null)}
        />
      </Modal>
      <Confirmation
        handleModal={handleShowModal}
        visible={confirm}
        description="I have  reviewed the assets and gone through the documents. "
        label={mintAssetActionLoading ? <Loader dimension={20} /> : "Approve"}
        title="Are you sure?"
        backLabel="Cancel"
        type="success"
      />
    </div>
  );
};
