import { useEffect, useRef, useState } from "react";
import { useCookie } from "./cookie";
import { APP_ROUTES } from "views";

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useDebounce = (value, data = {}, delay = 1200) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    if (data && data.immediate) {
      clearTimeout(handler);
      setDebouncedValue(value);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
};

export const useHandleLogout = () => {
  const { deleteCookie } = useCookie();
  const { USER_LOGIN } = APP_ROUTES;
  const handleLogout = () => {
    localStorage.clear();
    deleteCookie("userDetails");
    window.location.href = USER_LOGIN;
  };

  return { handleLogout };
};

export const useRemainingSecPercentage = (futureDateTimeString, startInterval = true) => {
  const [percentageRemaining, setPercentageRemaining] = useState(null);
  const timeRef = useRef(null);

  useEffect(() => {
    calculatePercentageRemaining();
    if (startInterval) {
      timeRef.current = setInterval(calculatePercentageRemaining, 1000);
    }
    return () => {
      clearInterval(timeRef.current);
    };
  }, [futureDateTimeString]);

  const calculatePercentageRemaining = () => {
    const currentDateTime = new Date();
    const futureDateTime = new Date(futureDateTimeString);

    const timeDifferenceMillis = futureDateTime - currentDateTime;
    // Calculate the total time difference in milliseconds (from the future date to the current date)
    const totalTimeMillis = futureDateTime - new Date().setHours(0, 0, 0, 0);

    // Calculate the percentage of time left
    const percentage = ((totalTimeMillis - timeDifferenceMillis) / totalTimeMillis) * 100;


    if (percentage <= 0) {
      clearInterval(timeRef.current);
      setPercentageRemaining(0);
    } else {
      setPercentageRemaining((100 - percentage).toFixed(2));
    }
  };
  return percentageRemaining;
};

