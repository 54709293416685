import { atom } from "recoil";

export interface subModules {
  subModuleId: number;
  view: boolean;
  edit: boolean;
  create: boolean;
}
export interface permissonType {
  module: number;
  view: boolean;
  edit: boolean;
  create: boolean;
  subModules: subModules[];
}
export interface FormStateType {
  name?: string;
  description?: string;
  permissions?: permissonType[];
}
export const createFormState = atom<any>({
  key: "create-form-state",
  default: {},
});

export const newRoleState = atom<boolean>({
  key: "new-role-state",
  default: false,
});

export const addRoleState = atom<boolean>({
  key: "add-role-state",
  default: false,
});

export const inviteUserState = atom<boolean>({
  key: "invite-user-state",
  default: false,
});

export const allAccessState = atom<boolean>({
  key: "all-access-state",
  default: false,
});

export const inviteRoleId = atom<number>({
  key: "invite-role-id",
  default: 0,
});

export const pervissionViewState = atom<boolean>({
  key: "permission-view-state",
  default: false,
});

export const editRoleState = atom<boolean>({
  key: "edit-role-state",
  default: false,
});

export const moduleListState = atom<any>({
  key: "module-list-state",
  default: [],
});

export const userListState = atom<any[]>({
  key: "user-list-state",
  default: [],
});

export const rolesListtate = atom<any>({
  key: "role-list-state",
  default: {
    data: [
      {
        description:
          "Permissions grant users all access, such as creating, modifying, and viewing resources and data for all services",
        name: "Admin 1",
      },
    ],
  },
});

export const rolesTemplatetate = atom<any>({
  key: "role-template-state",
  default: {
    data: [],
  },
});

export const selectedRoleState = atom<any>({
  key: "selected-role-state",
  default: {
    description:
      "Permissions grant users all access, such as creating, modifying, and viewing resources and data for all services",
    name: "Admin 1",
  },
});

export const userDetailsState = atom<any>({
  key: "user-detail-state",
  default: {},
});

export const templateState = atom<any>({
  key: "template-state",
  default: {},
});
