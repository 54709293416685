import { FC } from "react";
import { Loader, Modal } from "@storybook";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import { useUserPermission } from "../../../../hooks/user-permission";
//import './session-details-model.scss';

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isBeta = /beta/gi.test(API_HOST);
const isProd = /api.satschel.com/gi.test(API_HOST);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "session-details": any;
      session_id: string;
      token: string;
      comments: string;
      env: string;
      type: string;
      compliance_data: string;
    }
  }
}

interface Props {
  visible: boolean;
  sessionId: string;
  setModalVisible: () => void;
  accessToken: string;
}

export const SessionDetailsModal: FC<Props> = ({
  visible,
  sessionId = "",
  setModalVisible,
  accessToken,
}) => {
  const userEditPermission = useUserPermission();
  const environmentHost = () => {
    if (isStage) return "beta";
    if (isPP) return "beta";
    if (isProd) return "prod";
    if (isBeta) return "beta";
    return "beta";
  };

  return (
    <>
      <Modal
        isOpen={visible}
        modalName="Session Modal"
        className="session-modal"
        showCloseBtn={true}
        isStopOutsideClick={true}
        closeOnEscBtn={true}
        closeModal={() => setModalVisible()}
      >
        <>
          {!!accessToken ?
          <div
            dangerouslySetInnerHTML={{
              __html: `<session-details
							session_id=${sessionId}
							token=${accessToken}
							comments="true"
							env=${environmentHost()}
							type="sessions"
							compliance_data="false"
							url="/complex-session-details"
							host_type="v2"
							action_disable=${userEditPermission}
							only_activity=${userEditPermission}
							/>`,
            }}
          ></div> :
            <div className="simplici-session-loader"><Loader /></div>
          }
        </>
      </Modal>
    </>
  );
};
