import { atom } from "recoil";
import { Json } from "types";


export const DVP_STATUS = {
  APPROVED: "ACCEPTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};
export const dvpListState = atom<Json>({
  key: "asset-list-dvp-state",
  default: {},
});

export const dvpListApproveState = atom<Json>({
  key: "approve-list-dvp-state",
  default: {},
});

export const selectedDvpState = atom<Json>({
  key: "selected-dvp-asset-state",
  default: {},
});

export const dvpListCall = atom<boolean>({
  key: "dvp-asset-list-call-state",
  default: false,
});

export const initalFilterStateDvp = atom<Json>({
  key: "dvp-initail-filter-state",
  default: {
    limit: 10,
    offset: 0,
    // type: "sba7",
    status: `${DVP_STATUS.PENDING},${DVP_STATUS.REJECTED}`,
  },
});
