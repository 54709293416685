export const UserDetails = () => {
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<onboarded-dashboard
          type="sessions"
          bgcolor="#f7f7f7"
          theadcolor="#d9e1f2"
          clientid="6ca6349359234b8abbf107e99689be07"
          clientsecret="aa0157533583bb3405858cd6013b13751665174a35f8fdc6964f43a0acda1a6f"
          btnprimary="#3c65d6"
          btnsecondary="#ebeef5"
          sidebar="false"
        ></onboarded-dashboard>`,
        }}
      />
    </div>
  );
};
