import { useMemo, useCallback, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "react-circular-progressbar/dist/styles.css";

import {
  Button,
  Modal,
  Pagination,
  ReactResponsiveTable,
} from "@storybook";
import {  DVP_TABLE_HEADER } from "./store/constant";
import {  RejectModal } from "./component";
import { useUserPermission } from "hooks/user-permission";
import { formatNumberWithCommas, useDebounce, useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { Json } from "types";
import { getJsonToParams, getObjectFromUseSearchParams } from "utils";
import { useSearchParams } from "react-router-dom";
// import { Filter } from "./component/filter";
import {
  selectedDvpState,
  dvpListCall,
  initalFilterStateDvp,
  dvpListState,
} from "./store";

import "./dvp.scss";
import { ApproveModal } from "./component/approve";

export const DvpList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = useRecoilValue(initalFilterStateDvp);

  const [openDocNumber, setOpenDocNumber] = useState<any>(-1);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
   const [iframe, setIframe] = useState("");
  const buttonDisable = useUserPermission();
  console.log(buttonDisable);
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });

  const { get: getAsset } = useNetwork();
  const { errorNotification } = useNotification();

  const [{ data: assetList = [], loading }, setAssetList] =
    useRecoilState(dvpListState);
  const [selectedAsset, setSelectedAsset] = useRecoilState(selectedDvpState);
  const assetApiCall = useRecoilValue(dvpListCall);

  const handleFetchUserAuction = useCallback(async () => {
    setAssetList({ loading: true });
    const { immediate, ..._filters } = filters;
    setSearchParams({ ..._filters });
    const res = await getAsset(
      API_URL.userDvp + getJsonToParams({ ..._filters })
    );

    if (res?.apiData?.message === "ok") {
      setAssetList({ loading: false, data: res?.apiData?.data });
    } else {
      setAssetList({ loading: false });
      errorNotification(
        res?.apiData?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters]);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(() => {
    handleFetchUserAuction();
  }, [debouncedFilters, assetApiCall]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };
  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  const handelOpenDocList = useCallback(
    (index: number) => {
      openDocNumber === index ? setOpenDocNumber(-1) : setOpenDocNumber(index);
    },
    [openDocNumber]
  );

  const handelRow = useCallback(
    (data: any, e: any) => {
      e.preventDefault();
      const selectedData = assetList.filter(
        (item: any) => item?.id === data?.id
      );
      setSelectedAsset(selectedData?.length ? selectedData[0] : {});
      // setApprovalVisible(true);
    },
    [assetList]
  );

  const handelApprove = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setConfirm(true);
    setSelectedAsset(item);
  }, []);

  const handelReject = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setRejectVisible(true);
    setSelectedAsset(item);
  }, []);
  const handelHistory = useCallback((e: any, items: any) => {
    // When we work history we will uncomment this
    e.stopPropagation();
    // setAuctionHistory(true);
    setSelectedAsset(items);
  }, []);



  const handeCloseReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const handeCloseIframe = useCallback(() => {
    setIframe("");
  }, []);

   const handeCloseApprove = useCallback(() => {
     setConfirm(false);
   }, []);



    const handleLinkClick = (url:string) => {
      setIframe(url);
    };

  const tableRows = useMemo(() => {
    if (!assetList) return [];
    const rows: any = [];
    assetList?.forEach((items: any,index:number) => {
      if (items) {
        let row: any = {};
        DVP_TABLE_HEADER.forEach(({ format, key }) => {
          row.id = items.id;
          if (key === "actions") {
            const value = () => (
              <>
                <div className="auction-actions">
                  {items?.status === "PENDING" && (
                    <>
                      <button
                        className="approved-btn"
                        disabled={buttonDisable}
                      >
                        {" "}
                        Approve
                      </button>
                      <button
                        className="reject-btn"
                        onClick={(e) => handelReject(e, items)}
                        disabled={buttonDisable}
                      >
                        Reject
                      </button>
                    </>
                  )}

                  {items?.status === "APPROVED" && (
                    <div className={items?.status}>Approved</div>
                  )}
                  {items?.status === "INPROGRESS" && (
                    <div className={items?.status}>In Progress</div>
                  )}
                  {items?.status === "REJECTED" && (
                    <div className={items?.status}>Rejected</div>
                  )}
                </div>
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx" && key === "requestedLimit") {
            const value = () => <>{"$" + formatNumberWithCommas(items[key])}</>;
            return (row[key] = value);
          }

          if (key === "userName") {
            const value = () => (
              <div className="asset-name">
                <div>
                  <div className="text-ellipsis">{items[key]}</div>
                  <div className="text-ellipsis asset-symbol">
                    {items["customerId"]}
                  </div>
                </div>
              </div>
            );
            return (row[key] = value);
          }
          if (key === "signUrl") {
            const value = () => (
              <div className="status">
                <Button
                  className="agreement-clm"
                  icon="ri-eye-line"
                  label={"View"}
                  handleClick={() => handleLinkClick(items[key])}
                />
              </div>
            );
            return (row[key] = value);
          }
          if (key === "userEmail") {
            const value = () => (
              <>
                <div className="userEmail" id={`${index}`}>
                  {items["userEmail"]}
                </div>
                <div className="text-ellipsis asset-symbol">
                  ({items["countryCode"]}){items["phone"]}
                </div>
                <ReactTooltip
                  className="tool-tip"
                  anchorId={`${index}`}
                  place="bottom"
                  content={items["userEmail"]}
                />
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [
    assetList,
    buttonDisable,
    handelApprove,
    handelHistory,
    handelOpenDocList,
    handelReject,
    openDocNumber,
  ]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={DVP_TABLE_HEADER}
        height="calc(100vh - 285px)"
        showSearch={false}
        showFilter={false}
        handelRowClick={(data, e) => handelRow(data, e)}
        columnHandle={true}
        isLoading={loading}
      />
    );
  }, [tableRows]);

  
  // const onReset = () => {
  //   setFilters({ ...initialFilter });
  // };

  return (
    <div className="super-admin-wrapper-dvp">
      <div className="minted-asset-admin">
        {/* Todo:once we get search api we will integrate that */}
        {/* <Filter
          inputValue={filters.name || ""}
          handleInputChange={(value: string) =>
            handleFilter({ name: value, offset: 0 })
          }
          data={dataFilterDvp}
          filters={filters}
          handleFilter={(obj: Json) => handleFilter({ ...obj, offset: 0 })}
          onReset={onReset}
          showFilter={false}
        /> */}
        {renderSuperAdminTable}
        <Pagination
          showCount={true}
          listCount={assetList?.length}
          page={parseInt(filters.offset)}
          perPage={parseInt(filters.limit)}
          onChangePage={(page) => handlePagination(page, "page")}
          onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
          loading={loading}
        />
      </div>
   
      <Modal
        isOpen={rejectVisible}
        modalName={"d"}
        closeModal={handeCloseReject}
        className="reject-dvp-detail"
        showCloseBtn={false}
      >
        <RejectModal
          selectedAsset={selectedAsset}
          handelFilter={handleFilter}
          handeCloseReject={handeCloseReject}
        />
      </Modal>

      <Modal
        isOpen={iframe ? true : false}
        modalName={""}
        className="view-document"
        closeModal={handeCloseIframe}
      >
        <iframe
          src={iframe}
          title="Compliance"
          className="view-document--iframe"
        />
      </Modal>
     

      <Modal
        isOpen={confirm}
        modalName={"d"}
        closeModal={handeCloseApprove}
        className="approve-dvp-detail"
      >
        <ApproveModal
          selectedAsset={selectedAsset}
          handelFilter={handleFilter}
          handeCloseReject={handeCloseApprove}
        />
      </Modal>
    </div>
  );
};
