import { ScrollableTable } from "./table";
import pdfType from "../../../assets/images/pdfType.png";
import xslType from "../../../assets/images/xslType.png";

export const AuctionDetail = () => {
  return (
    <div className="auctionDetails">
      <h2>2013 Subaru Forester Premium Plus</h2>
      <p>Some text</p>
      <div className="auction-img">
        <img
          src="https://storage.googleapis.com/satschel-assets-public/funds/privates/6c0b0c98-0e36-4e51-996c-bc8bceee7e16.png?GoogleAccessId=nich-service-account%40glossy-fastness-305315.iam.gserviceaccount.com&amp;Expires=1742169600&amp;Signature=SQlNB5ZJ3sc%2F%2F0mk9TMj8z4ZRW2F7yBFUjwdeO3NhQdIymdNpFoHhr53ufAqQWjJsqdXR%2B%2FteEF7z2Bn3ADGoV31Voskh6Dxce%2FetJGXI58mPJoek%2F6NZwQup7F4nTwyC%2BCymkzzbpevq3H6NOyKY6YIRkcCIKe1ULsztGgn6KfboFlgMvvXxVhLRZEURqTnLvyg2G%2FeTfIUycufUy7lUtzA6OGzGkfu%2FvP%2B4XgwGMKoBYOfYoGvohEeY%2FIRyij2wEhOYFK8qZ%2BNLqoilEqLf%2FBKd8sKkofpEpEPA0WjTB5%2BNs%2Bva9YkT%2BB3%2B1xvjfr2mMHi8H2AAca%2BddMlRLJe4w%3D%3D"
          alt="overview_image"
        ></img>
      </div>
      <h2>2013 Subaru Forester Premium Plus</h2>
      <p className="auction-description">
        blackrock.<b>AKJS</b>
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries
      </p>
      <div className="auction-row">
        <div className="auction--row">
          <h3>Classic</h3>
          <p>Auction type</p>
        </div>
        <div className="auction--row">
          <h3>$100</h3>
          <p>Price step</p>
        </div>
      </div>
      <div className="auction-row">
        <div className="auction--row">
          <h3>$80,292</h3>
          <p>Starting bid</p>
        </div>
        <div className="auction--row">
          <h3>$55,824.12</h3>
          <p>Buy now price</p>
        </div>
      </div>
      <div className="auction-row">
        <div className="auction--row">
          <h3>Mon, July 12, 9:38PM</h3>
          <p>Start date & time</p>
        </div>
        <div className="auction--row">
          <h3>Wed, July 14, 10:48PM</h3>
          <p>End date & time</p>
        </div>
      </div>
      <h2 className="loan-details">Loan details</h2>
      <p>Some text</p>
      <ScrollableTable />
      <h2 className="supporting-doc">Supporting documents</h2>
      <p>Some text</p>
      <div className="support-doc">
        <div className="support--doc">
          {" "}
          <div className="doc-img">
            <img src={pdfType} alt="" />
          </div>
          <div>
            ServiceBook <div className="support--doc_size">2.4MB</div>
          </div>
        </div>
        <div className="support--doc">
          {" "}
          <div className="doc-img">
            <img src={xslType} alt="" />
          </div>
          <div>
            VIN <div className="support--doc_size">2.4MB</div>
          </div>
        </div>
      </div>
      <div className="action-btns">
        <button className="reject">Reject</button>
        <button className="approve">Approve</button>
      </div>
    </div>
  );
};
