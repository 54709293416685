import "./onboardingFlowStatus.scss";
import { actionKeyLabel } from "views/accounts/store/constant";
import { useMemo } from "react";

type Props = {
  fullStatus?: any;
};

export const OnboardingFlowStatus = (props: Props) => {
  const { fullStatus } = props;

  const StatusJsx = useMemo(() => {
    return (
      <>
        {fullStatus &&
          Object.keys(fullStatus).map((key, index) => {
            return (
              <>
                <div
                  key={`${actionKeyLabel[key]}-${index}`}
                  className={`status__clip statusbg__${fullStatus[
                    key
                  ]?.toLowerCase()} statustct__${key}`}
                >
                  <svg
                    className="leftImg"
                    width="9"
                    viewBox="0 0 6 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
                  </svg>

                  <div className="inner__status">
                    {actionKeyLabel[key]}
                  </div>
                  <svg
                    width="9"
                    viewBox="0 0 6 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0L6 9.16667L0 20V0Z" />
                  </svg>
                </div>
              </>
            );
          })}
      </>
    );
  }, [fullStatus]);

  return <>{StatusJsx}</>;
};
