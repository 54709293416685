import './no-chats-available.scss';

export const NoChatsAvailable = ({ value = "" }: any) => {
  return (
    <div className="no-chat-container">
      <i
        className={`ri ${
          value !== "assets" ? "ri-chat-2-line" : "ri-bubble-chart-line"
        } `}
      ></i>
      <p>No {value} Available!</p>
    </div>
  );
};