import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useSearchParams } from "react-router-dom";

import { DvpList } from "./dvpList";
import { initalFilterStateDvp } from "./store";
import { getObjectFromUseSearchParams } from "utils";
import { DVP_STATUS } from "./store";
import { DvpListApproved } from "./dvpApprove";

export const DVP = () => {
  
  const [activeTab, setActiveTab] = useState(1);
  const initialfiter = useRecoilValue(initalFilterStateDvp);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
  const filterObj = getObjectFromUseSearchParams(searchParams); 
  filterObj.status === "ACCEPTED" && setActiveTab(2);
  },[searchParams])

  const handleTabClick = useCallback((tabNumber: React.SetStateAction<number>) => {
    if (activeTab !== tabNumber){
      const { immediate, ..._filters } = initialfiter;
      if (tabNumber === 2) {
        setSearchParams({ ..._filters, status: DVP_STATUS.APPROVED });
      } else {
        setSearchParams({
          ..._filters,
          status: `${DVP_STATUS.PENDING},${DVP_STATUS.REJECTED}`,
        });
      }
      setActiveTab(tabNumber);
    }
  },[activeTab, initialfiter]);

  return (
    <>
      <div className="minted-asset">
        <div className="tabs">
          <div
            className={`tab revenue-chart__label ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Approval Pending
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            Approved
          </div>
        </div>

        <div className="tab-content">
          {activeTab === 1 && <DvpList />}
          {activeTab === 2 && (
            <>
              <DvpListApproved />
            </>
          )}
        </div>
      </div>
    </>
  );
};
