import { atom } from "recoil";

export const chatInitialAssetId = atom<any>({
  key: "chat-inital-id",
  default: null,
});

export const chatDetails = atom<any>({
  key: "chat-details",
  default: {},
});