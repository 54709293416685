import { Button } from "@storybook";

interface IHeader {
  title: string;
  showBtn?: boolean;
  btnLabel?: string;
  btnClass?: string;
  icon?: string;
  handleClickBtn?: () => void;
  disabled?: boolean;
}

export const Header = ({
  title,
  showBtn,
  btnLabel,
  icon,
  btnClass = "",
  handleClickBtn,
  disabled,
}: IHeader) => {
  return (
    <div className="header">
      <div className="header__name">{title}</div>

      <div className={`header__right ${btnClass}`}>
        {showBtn && (
          <Button
            className="button__filled--primary"
            label={btnLabel || ""}
            handleClick={handleClickBtn}
            prefixIcon={icon}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
