import { useMemo, useCallback, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import "react-circular-progressbar/dist/styles.css";

import {
  Pagination,
  ReactResponsiveTable,
} from "@storybook";
import {  APPROVED_DVP_TABLE_HEADER } from "./store/constant";
import { useDebounce, useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { Json } from "types";
import { getJsonToParams, getObjectFromUseSearchParams } from "utils";
import { useSearchParams } from "react-router-dom";
// import { Filter } from "./component/filter";
import {
  dvpListCall,
  initalFilterStateDvp,

  dvpListApproveState,
} from "./store";

import "./dvp.scss";

export const DvpListApproved = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = useRecoilValue(initalFilterStateDvp);

 
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });
  // const [errorIndexes, setErrorIndexes] = useState<any[]>([]);

  const { get: getAsset } = useNetwork();
  const { errorNotification } = useNotification();
  // const { mintAssetAction, loading: mintAssetActionLoading } = useDvpApis();

  const [{ data: assetList = [], loading }, setAssetList] =
    useRecoilState(dvpListApproveState);
  // const [selectedAsset, setSelectedAsset] = useRecoilState(selectedDvpState);
  const assetApiCall = useRecoilValue(dvpListCall);

  const handleFetchUserAuction = useCallback(async () => {
    setAssetList({ loading: true });
    const { immediate, ..._filters } = filters;
    setSearchParams({ ..._filters });
    const res = await getAsset(
      API_URL.userDvp + getJsonToParams({ ..._filters })
    );

    if (res?.apiData?.message === "ok") {
      setAssetList({ loading: false, data: res?.apiData?.data });
    } else {
      setAssetList({ loading: false });
      errorNotification(
        res?.apiData?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters]);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(() => {
    handleFetchUserAuction();
  }, [debouncedFilters, assetApiCall]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };
  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);


  const tableRows = useMemo(() => {
    if (!assetList) return [];
    const rows: any = [];
    assetList?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        APPROVED_DVP_TABLE_HEADER.forEach(({ format, key }) => {
          row.id = items.id;
          
          if (format === "jsx" && key === "actualLimit") {
            const value = () => <>{"$" + items[key]}</>;
            return (row[key] = value);
          }

          if (key === "userName") {
            const value = () => (
              <div className="asset-name">
                <div>
                  <div className="text-ellipsis">{items[key]}</div>
                  <div className="text-ellipsis asset-symbol">
                    {items["customerId"]}
                  </div>
                </div>
              </div>
            );
            return (row[key] = value);
          }
          if (key === "price" || key==="actions") {
             const value = () => (
              <></>
              //  <div className="new-dvp">
              //    ${items[key]}
              //    <div className="new-dvp_action">
              //      <i className="ri-close-line"></i>
              //    </div>
              //    <div className="new-dvp_action accept">
              //      <i className="ri-check-line"></i>
              //    </div>
              //  </div>
             );
            return (row[key] = value);
          }
          if (key === "userEmail") {
            const value = () => (
              <>
                {items["userEmail"]}
                <div className="text-ellipsis asset-symbol">
                  ({items["countryCode"]}){items["phone"]}
                </div>
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [assetList]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={APPROVED_DVP_TABLE_HEADER}
        height="calc(100vh - 285px)"
        showSearch={false}
        showFilter={false}
        columnHandle={true}
        isLoading={loading}
      />
    );
  }, [tableRows]);

  // const onReset = () => {
  //   setFilters({ ...initialFilter });
  // };

  return (
    <div className="super-admin-wrapper-dvp">
      <div className="minted-asset-admin">
        {/* Todo:once we get search api we will integrate that */}
        {/* <Filter
          inputValue={filters.name || ""}
          handleInputChange={(value: string) =>
            handleFilter({ name: value, offset: 0 })
          }
          data={dataFilterDvp}
          filters={filters}
          handleFilter={(obj: Json) => handleFilter({ ...obj, offset: 0 })}
          onReset={onReset}
          showFilter={false}
        /> */}
        {renderSuperAdminTable}
        <Pagination
          showCount={true}
          listCount={assetList?.length}
          page={parseInt(filters.offset)}
          perPage={parseInt(filters.limit)}
          onChangePage={(page) => handlePagination(page, "page")}
          onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
          loading={loading}
        />
      </div>
    </div>
  );
};
