import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { LoginRoutesState } from "./store";
import { Login } from "./component/login/login";
import { ChooseMethod } from "./component";
import { OtpVerify } from "./component/otp-verify";
import { MobileLinkSent } from "./component/mobile-link-sent";
import { BiometricApprovalSent } from "./component/biometric-approval-sent/biometric-approval-sent";

export const LoginRoutes = () => {
    const route = useRecoilValue(LoginRoutesState);

    const renderRoutes = useMemo(() => {
        switch (route) {
            case "email":
                return <Login />;
            case "chooseMethod":
                return <ChooseMethod />;
            case "otpVerify":
                return <OtpVerify />;
            case "mobilelinkVerify":
                return <MobileLinkSent />;
            case "biometriclinkVerify":
                return <BiometricApprovalSent />;
            default:
                return <Login />;
        }
    }, [route]);

    return renderRoutes;
};

export default LoginRoutes;
