import { API_URL } from "constant";
import { useNetwork, useNotification } from "hooks";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

export const Invite = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { get: getInvite, data: inviteData } = useNetwork();
  const { successNotification, errorNotification } = useNotification();

  useEffect(() => {
    const code = searchParams.get("verificationCode");

    getInvite(`${API_URL.EMAIL_INVITE}/${code}`);
  }, [getInvite, searchParams]);

  useEffect(() => {
    if (inviteData) {
      if (inviteData?.data) {
        successNotification("Invite Accepted");
      } else {
        errorNotification(inviteData?.message);
      }
      navigate("/login");
    }
  }, [errorNotification, inviteData, navigate, successNotification]);

  return <></>;
};
