import React from "react";
import "./Checkbox.scss";

const Checkbox = ({
  className,
  content,
  note,
  value,
  onChange,
  name = "",
  disabled = false,
}) => {
  return (
    <label className="checkbox custom-checkbox">
      <input
        className="input"
        type="checkbox"
        onChange={onChange}
        checked={value}
        name={name}
        disabled={disabled}
      />
      <span className="inner">
        <span className="tick"></span>
        <span
          className="text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></span>
        {note && <span className="note">{note}</span>}
      </span>
    </label>
  );
};

export default Checkbox;
