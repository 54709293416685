/* eslint-disable no-console */
import React, { useEffect, useRef } from "react";
import { models as PowerBIModel } from 'powerbi-client';
import './revenue.scss';


const PowerBIReport: React.FC<{
  accessToken: string;
  embedUrl: string;
  reportId: string;
  tokenType: string;
}> = ({ accessToken, embedUrl, reportId, tokenType }) => {
  const embedContainer = useRef<HTMLDivElement>(null); // Use ref for embed container

  useEffect(() => {
    let loadedResolve: () => void;
    const reportLoaded = new Promise<void>((res) => {
      loadedResolve = res;
    });

    let renderedResolve: () => void;
    const reportRendered = new Promise<void>((res) => {
      renderedResolve = res;
    });

    const models = PowerBIModel;

    const embedPowerBIReport = () => {
      const permissions = models.Permissions.Read;

      const config = {
        type: "report",
        tokenType: tokenType === "0" ? models.TokenType.Aad : models.TokenType.Embed,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: reportId,
        permissions: permissions,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: true,
            },
          },
          bars: {
            statusBar: {
              visible: true,
            },
          },
        },
      };

      // Embed the report into the container
      const report = window?.powerbi?.embed(embedContainer.current!, config);

      report?.off("loaded");
      report?.on("loaded", function () {
        loadedResolve();
        report.off("loaded");
      });

      report?.off("error");
      report?.on("error", function (event) {
        console.error(event.detail);
      });

      report?.off("rendered");
      report?.on("rendered", function () {
        renderedResolve();
        report?.off("rendered");
      });
    };

    embedPowerBIReport();

    reportLoaded?.then(() => {
      //for debugging purpose
      console.log("Report loaded");
    });

    reportRendered?.then(() => {
      //for debugging purpose
      console.log("Report rendered");
    });

    // Cleanup on component unmount
    return () => {
      console.warn("embedContainer.current", embedContainer.current)
      !!embedContainer.current && window?.powerbi?.reset(embedContainer.current!);
    };
  }, [accessToken, embedUrl, reportId, tokenType, embedContainer.current]);

  return (
    <div className="power_BI__parent-container">
      <div ref={embedContainer} style={{ height: "100%", width: "100%" }}></div>
    </div>
  );
};

export default PowerBIReport;

