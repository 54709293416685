import {
  FC,
  MouseEvent,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useOutsideClick } from "hooks";

interface IButton {
  label?: string;
  iconClass?: string;
  onClick: (e: Event, id: string) => void;
}

interface IDropOptions {
  buttons: IButton[];
  sessionId: string;
}

export const EllipsisMenu: FC<IDropOptions> = ({ buttons, sessionId = "" }) => {
  const [showOption, setShowOption] = useState<boolean>(false);
  const [position, setPosition] = useState<"bottom" | "top">("bottom");
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const calculatePosition = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (rect.bottom + 150 > windowHeight) {
          setPosition("top");
        } else {
          setPosition("bottom");
        }
      }
    };

    if (showOption) {
      calculatePosition();
    }

    window.addEventListener("resize", calculatePosition);

    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [ref, showOption]);

  useOutsideClick(ref, () => {
    setShowOption(false);
  });

  const handleOptions = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowOption((prev) => !prev);
  }, []);

  const handleButtonClick = (e: Event, button: IButton) => {
    setShowOption(false);
    button.onClick(e, sessionId);
  };

  return (
    <div ref={ref} className={`ellipsismenu ${position}`}>
      <div className="ellipsismenu__icon-container ">
        <i className="ri-more-2-fill ri-lg" onClick={handleOptions} />
      </div>
      {showOption && (
        <div
          className={
            position === "bottom"
              ? "ellipsismenu__options-box-bottom"
              : "ellipsismenu__options-box-top"
          }
        >
          {buttons.map((button, index) => (
            <button
              key={index}
              className="ellipsismenu__menu-item"
              onClick={(e: any) => handleButtonClick(e, button)}
            >
              <i className={button.iconClass} />
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
