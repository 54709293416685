// src/firebase.js
import { getApp, getApps, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // For Realtime Database
import { getFirestore } from "firebase/firestore"; // For Firestore
import { REACT_APP_FIREBASE as FIREBASE } from "envs";
import { getAuth, setPersistence, signInWithCustomToken, browserLocalPersistence } from "firebase/auth"; // Import Firebase Auth functions

import { API_URL } from "constant";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import {  useSetRecoilState } from "recoil";
import { firsbaseAuthState } from "views";

const firebaseConfig = JSON.parse(FIREBASE);

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

export const database = getDatabase(app); // For Realtime Database
export const firestore = getFirestore(app); // For Firestore

// Function to sign in with custom token and set persistence
export const useFirebase = () =>{ 
    const setFirebaseToken = useSetRecoilState(firsbaseAuthState);

  const signInWithTokenAndSetPersistence = async (customToken: string) => {
    const auth = getAuth(app); // Initialize Firebase Auth
    try {
      // Set persistence to browserLocalPersistence (so user stays signed in across browser sessions)
      await setPersistence(auth, browserLocalPersistence);

      // Sign in with custom token
      const userCredential = await signInWithCustomToken(auth, customToken);
       setFirebaseToken(false);
      // You can optionally return the user object or token
      const token = await userCredential.user.getIdToken();
      return { user: userCredential.user, token };
    } catch (error) {
      console.error("Error during sign-in or setting persistence:", error);
      throw error; // You can handle this error in the UI or log it
    }
  };

  const fetchDataWithToken = async (token: string)=> {
    try {
      const response: any = await fetch(API_HOST + API_URL.FIREBASE, {
        headers: {
          Authorization: `Bearer ${token}`, // Set Bearer token in Authorization header
        },
      });
        const apiData = await response.json();
      // Log or handle the response data
      if (apiData.data) {
        signInWithTokenAndSetPersistence(apiData.data.token);
      }
      //  return response.data;
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      throw error;
    }
  }
 return {fetchDataWithToken}
}