import "./scrollable.scss"; // Import your stylesheet

export const ScrollableTable = () => {
  const tableData = [
    {
      loanNumber: "--",
      name: "--",
      email: "--",
      address: "--",
    },
  ];

  return (
    <div className="table-container mint-detail-table">
      <div className="table-header">
        <div>Creators name </div>
        <div className="head-email">Email</div>
        <div className="head-email">Creator address</div>
        <div>Share (in%)</div>
      </div>
      <div className="table-body">
        {tableData.map((row) => (
          <div key={row.loanNumber} className="table-row">
            <div>{row.name}</div>
            <div className="head-email">{row.email}</div>
            <div className="address head-email">{row.address}</div>
            <div>--</div>
          </div>
        ))}
      </div>
    </div>
  );
};
