import type { RangeKeyDict } from "react-date-range";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  DateRangePicker,
  defaultStaticRanges,
  SelectedDateRangeState,
} from "@storybook";
import { isSameDay } from "date-fns";
import classNames from "classnames";

import { useLocalStorage, useOutsideClick } from "hooks";

import { getDate } from "utils";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./date-picker.scss";

interface IDatePicker {
  onSubmit?: any;
  stateType?: any;
  brandColor?: any;
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

export const DatePicker: FC<IDatePicker> = ({
  onSubmit,
  stateType,
  brandColor,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const [selectedDateRange, setSelectedDateRange] = useRecoilState<any>(
    stateType ?? SelectedDateRangeState
  );

  const [range, setRange] = useState<IDateRange[] | any>();
  const { set: setLocalStorage } = useLocalStorage();

  useEffect(() => {
    setRange(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (range) {
      const isSame = isSameDay(range[0].startDate, range[0].endDate);
      if (!isSame || count > 1) {
        setLocalStorage("date-range", range[0]);
        setCount(0);
      }
    }
  }, [count, range, setLocalStorage]);

  const handleOpenDatePicker = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleChangeDateRange = useCallback(({ selection }: RangeKeyDict) => {
    if (selection) {
      setRange([selection as any]);
      setCount((prev) => prev + 1);
    }
  }, []);

  const handleApplyDateRange = useCallback(() => {
    setIsVisible(false);
    setSelectedDateRange(range);
    setLocalStorage("date-range", range[0]);
    if (onSubmit) onSubmit();
  }, [setSelectedDateRange, range, setLocalStorage, onSubmit]);

  const renderedDate = useMemo(() => {
    const { endDate, startDate }: any = selectedDateRange[0];

    const selectedRange = defaultStaticRanges.find(
      (date) =>
        date.range().startDate === startDate && date.range().endDate === endDate
    );

    if (selectedRange?.label) {
      return selectedRange.label;
    }
    const isSame = isSameDay(startDate, endDate);
    const fromDate = getDate(startDate, "MMM dd , yyyy");
    const toDate = getDate(endDate, "MMM dd , yyyy");
    if (isSame) {
      return fromDate;
    }

    return (
      <>
        <div className="selected-range--date">{fromDate}</div>
        <div className="selected-range--to">-</div>
        <div className="selected-range--date">{toDate}</div>
      </>
    );
  }, [selectedDateRange]);

  const selectedClass = classNames("calender-btn", {
    "calender-btn--active": isVisible,
  });

  const arrowIconClass = classNames("ri-arrow-down-s-line", {
    "arrow-icon--active": isVisible,
  });

  const ref: any = useRef();
  useOutsideClick(ref, () => {
    setIsVisible(false);
  });

  return (
    <div>
      <div onClick={handleOpenDatePicker} className={selectedClass}>
        <i className="ri-calendar-todo-line Date-picker--calendar-icon" />
        <span className="selected-range">{renderedDate}</span>
        <i className={arrowIconClass} />
      </div>
      <div className="picker-container" ref={ref}>
        {isVisible && (
          <DateRangePicker
            range={range}
            rangeColor={[brandColor]}
            handleChangeRange={handleChangeDateRange}
            handleSubmit={handleApplyDateRange}
            setIsVisible={setIsVisible}
          />
        )}
      </div>
    </div>
  );
};
