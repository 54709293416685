import { DateTime } from "luxon";

export const getDate = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(format ?? "MMM dd, yyyy");
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getDateWithTime = (
  date: string | number | Date,
  format?: string
) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(
        format ?? "MMM dd, yyyy hh:mm a"
      );
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy  hh:mm a");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getTimeWithDate = (
  date: string | number | Date,
  format?: string
) => {
  const defaultFormat = format ?? "hh:mma, LLL dd yyyy";

  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(defaultFormat);
    case "string":
      return DateTime.fromISO(date).toFormat(defaultFormat);
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(defaultFormat);
  }
};

export const getTime = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat("hh:mm a");
    case "string":
      return DateTime.fromISO(date).toFormat("hh:mm a");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getDifferenceInDate = (date1: any, date2: any) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDate = (dateI: Date | string) => {
  const date = new Date(dateI);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return `Today at ${date
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toUpperCase()}`;
  } else if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${date
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toUpperCase()}`;
  } else {
    return date.toLocaleString([], {
      dateStyle: "medium",
      timeStyle: "short",
      hour12: true,
    })
    .replace(/(am|pm)/i, (match) => match.toUpperCase());
  }
};
export const formatDateGroup = (dateI: Date | string) => {
  const date = new Date(dateI);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    return date.toLocaleDateString([], { month: "short", day: "2-digit" }); 
  }
};