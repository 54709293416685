export const APP_ROUTES = {
  USER_LOGIN: "login",
  NO_ROUTE: "/",
  OAUTH: "oauth",
  USER_DETAILS: "user-details",
  REVENUE: "revenue",
  AUCTION: "auction",
  ROLES: "roles",
  ACTIVITY_LOGS: "activity-logs",
  ACCOUNTS: "accounts",
  MINTED_ASSETS: "private-stock",
  SBA7: "sba7",
  API_DESIGNER: "api-designer",
  ACCEPT_INVITE: "accept-invite",
  ACCESS_DENIED: "access-denied",
  USER_LOGIN_APPROVE: "mobile-verification",
  CHATS: "chats",
  DP: "delevery-payments",
  APP: "app-management",
  CHAT_DETAILS: "chats/:chatId",
  MANU_HOME_LOANS: "manufactured-home-loans",
};
