import { useCookie } from "hooks";
import { DefaultLayout } from "layouts";
import { Navigate } from "react-router-dom";
import { RouteProps } from "views";

export const PrivateRoute = ({
  element,
  title = "",
  showBtn,
  btnLabel,
  btnClass = "",
  handleClickBtn,
  disabled,
  
}: RouteProps): JSX.Element => {
  const { get: getCookieData } = useCookie();
  const userDetails = getCookieData("userDetails");
  const { token: loginToken } = userDetails ?? {};

  const isAuthenticated = loginToken ? true : false;

  return isAuthenticated ? (
    <DefaultLayout
      children={element}
      title={title}
      showBtn={showBtn}
      btnLabel={btnLabel}
      btnClass={btnClass}
      handleClickBtn={handleClickBtn}
      disabled={disabled}
    />
  ) : (
    <Navigate to="/login" />
  );
};
