import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { API_URL } from "constant";
import { useNetwork, useNotification } from "hooks";

import {
  APP_ROUTES,
  LoginRoutesState,
  MobileVerificationState,
  OptionsResponseState,
  userLoggedInDetails,
} from "views";
import { Json } from "types";

export const useLogin = () => {
  const navigate = useNavigate();
  const { errorNotification } = useNotification();
  const { post } = useNetwork();
  const setLoginRoute = useSetRecoilState(LoginRoutesState);

  const { NO_ROUTE } = APP_ROUTES;
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [userAuthDetails, setUserAuthDetails] =
    useRecoilState(userLoggedInDetails);

  const handleVerifyEmail = async (payload: any) => {
    try {
      setIsLoaded(false);
      const resp = await post(API_URL.VERIFY_EMAIL_MOBILE, payload);
      const { response, apiData } = resp || {};
      if (response?.status === 200) {
        setUserAuthDetails(apiData?.data);
        // verificationId(apiData?.data?._id);
        if (!apiData?.data?.isVerifiedEmail) {
          setLoginRoute("otpVerify");
        } else setLoginRoute("chooseMethod");
        return { status: true, apiData };
      } else {
        setIsLoaded(true);
        return { status: false };
      }
    } catch (error) {
      setIsLoaded(true);
      errorNotification("An error occurred");
      return { status: false };
    }
  };

  const handleOnSuccess = useCallback(() => {
    setUserAuthDetails({ ...userAuthDetails, isLoggedIn: true });
    navigate(NO_ROUTE);
  }, [setUserAuthDetails, userAuthDetails, navigate]);

  const handleOnFailed = useCallback(() => {
    errorNotification("Something went wrong");
  }, [errorNotification]);

  return {
    handleOnSuccess,
    handleOnFailed,
    // handleSubmitCreds,
    handleVerifyEmail,
    isLoaded,
  };
};

//TODO: Modify this fn as per requirement
export const useBiometricAuthentication = () => {
  const { post } = useNetwork();
  const userAuthDetails = useRecoilValue(userLoggedInDetails);
  const [verificatioId, setVerificationId] = useRecoilState(
    MobileVerificationState
  );
  const setOptionsResponse = useSetRecoilState(OptionsResponseState);
  const { errorNotification } = useNotification();
  const { phone, countryCode } = userAuthDetails;

  const authenticateOptions = useCallback(async (payload: any) => {
    const response = await post(API_URL.CLIENT_WEBAUTHN, payload);
    return response?.apiData?.data;
  }, []);

  const registrationApproval = useCallback(
    async (payload: any) => {
      const response: any = await post(API_URL.CLIENT_WEBAUTHN, payload);
      const { verificationId } = response ?? "";
      setVerificationId(verificationId);
      return response;
    },
    [post, setVerificationId]
  );

  const registrationOptions = useCallback(
    async ({ authenticationType }: any) => {
      let optionPayload: any;
      if (authenticationType === "login") {
        optionPayload = {
          type: "registrationOpts",
          verificationId: verificatioId,
        };
      } else {
        optionPayload = {
          type: "registrationOptsUID",
          phone,
          countryCode,
        };
      }

      const response: any = await post(API_URL.CLIENT_WEBAUTHN, optionPayload);
      const { message, id, registrationOptions } =
        response?.apiData?.data ?? {};
      if (id && registrationOptions) {
        setOptionsResponse(response?.apiData?.data);
      } else {
        errorNotification(message ?? "Something went wrong");
      }
    },
    [
      countryCode,
      errorNotification,
      phone,
      post,
      setOptionsResponse,
      verificatioId,
    ]
  );

  const verifyRegistration = useCallback(
    async (payload: any) => {
      const response = await post(API_URL.CLIENT_WEBAUTHN, payload);
      return response;
    },
    [post]
  );

  const verifyAuthentication = useCallback(
    async (payload: any) => {
      const response = await post(API_URL.CLIENT_WEBAUTHN, payload);
      return response;
    },
    [post]
  );

  return {
    registrationOptions,
    registrationApproval,
    authenticateOptions,
    verifyRegistration,
    verifyAuthentication,
  };
};

export const useMobileAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const { post: postUserLogin } = useNetwork();
  const { post: checkLinkStatus } = useNetwork();
  const { errorNotification } = useNotification();

  type IgenerateVerificationLink = {
    type: string;
    countryCode?: any | string | number;
    phone: string;
  };

  const generateVerificationLink = async ({
    type,
    countryCode,
    phone,
  }: IgenerateVerificationLink) => {
    setLoading(true);
    try {
      const response: any = await postUserLogin(
        API_URL.userVerifyLink,
        {
          type,
          countryCode,
          phone,
        }
        // { apiResponse: true }
      );
      if (response?.apiData?.data?.success) {
        return response?.apiData?.data?.verificationId ?? "";
      } else {
        errorNotification(response?.message ?? "Something went wrong");
      }
    } catch (err: any) {
      console.log(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const checkMobileVerificationStatus = async (payload: Json) => {
    try {
      const response = await checkLinkStatus(
        API_URL.userVerifyLink,
        payload
        //   {
        //   apiResponse: true,
        // }
      );
      return response;
    } catch (err: any) {
      console.log(err?.message);
      return;
    }
  };

  const { post: mobileVerifyLinkAction } = useNetwork();
  const mobileLinkAction = async (payload: Json) => {
    setLoading(true);
    try {
      const response: any = await mobileVerifyLinkAction(
        API_URL.userVerifyLink,
        payload
        // { apiResponse: true }
      );
      if (
        response?.apiData?.message === "link expired." ||
        response?.apiData?.message === "Invalid Request"
      ) {
        return { response: response?.apiData, status: false };
      } else {
        return { response: response?.apiData, status: true };
      }
    } catch (err: any) {
      console.log(err?.message);
      return { status: false };
    } finally {
      setLoading(false);
      // return;
    }
  };

  return {
    generateVerificationLink,
    checkMobileVerificationStatus,
    mobileLinkAction,
    loading,
  };
};
