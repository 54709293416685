import { useCallback, useState } from "react"

/* 
 This hooks help us to get user Location info 
 */
export const useLocation = () => {
    const [locationInfo, setLocationInfo] = useState<Partial<GeolocationCoordinates>>()
    const fetchLocation = useCallback(() =>
        navigator.geolocation.getCurrentPosition(info => {
            /*
              setting only few values as Other is not required  if coords is available
            */
            if (info?.coords)
                setLocationInfo({
                    accuracy: info.coords.accuracy,
                    altitude: info.coords.altitude,
                    altitudeAccuracy: info.coords.altitudeAccuracy,
                    latitude: info.coords.latitude,
                    longitude: info.coords.longitude,
                })
        }, error => {
            console.log("error", error)
        }, { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 })
        , [])
    /*
      Calling this method only if locationinfo does not contain anything ,    
    */
    if (!locationInfo)
        fetchLocation();

    /* 
    Do not need to return method for that reason we are returing state that we have saved on line 7
    */
    return { locationInfo }

}