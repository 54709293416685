import "./chats.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CHAT_HEADER } from "./constant";
import { Confirmation, Input, Loader, Modal, Pagination, ReactResponsiveTable } from "@storybook";
import {
  getDate,
  getInitials,
  getJsonToParams,
  getObjectFromUseSearchParams,
  getTime,
} from "utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce, useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { Json } from "types";
import { useResetRecoilState } from "recoil";
import { chatInitialAssetId, chatDetails as chatUserDetails } from "./state";

export const Chats = () => {
  const initialFilter = { limit: 20, offset: 0 };
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getObjectFromUseSearchParams(searchParams),
  });
  const { get: fetchChatDetails } = useNetwork();
  const { put: lockUnlockAccounts } = useNetwork();
  const [accountLockId, setAccountLockId] = useState<any>(null);
  const { successNotification } = useNotification();

  const navigate = useNavigate();
  const resetInitialAssetId = useResetRecoilState(chatInitialAssetId);
  const resetChatSelectedDetails = useResetRecoilState(chatUserDetails);

  // clearing all the chat details state, so that whenever we redirect to chat page everything loads like start
  useEffect(() => {
    resetInitialAssetId();
    resetChatSelectedDetails();
  }, []);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );
  const [chatDetails, setChatDetails] = useState<Json>({});
  const { errorNotification } = useNotification();

  useEffect(() => {
    handleFetchChats();
  }, [debouncedFilters]);

  const handleFetchChats = useCallback(async () => {
    setChatDetails({ loading: true });
    const { immediate, ..._filters } = filters;
    setSearchParams({ ..._filters }, { replace: true });
    const res = await fetchChatDetails(
      API_URL.chatDetails + getJsonToParams({ ..._filters })
    );
    const { apiData } = res ?? {};
    if (apiData?.message === "ok") {
      setChatDetails({ loading: false, data: apiData?.data });
    } else {
      setChatDetails({ loading: false });
      errorNotification(
        res?.message || "Something went wrong! Please try again"
      );
    }
  }, [filters]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const userAccountLocked = (id: any) => {
    setAccountLockId(id);
  };

  const renderChatList = useMemo(() => {
    if (!chatDetails?.data?.length) return [];
    const rows: any = [];
    chatDetails?.data?.forEach((items: any) => {
      if (items) {
        let row: any = {};

        CHAT_HEADER.forEach(({ format, key }) => {
          row.id = items?.id;
          row.customerId = items?.customerId;
          row.userDetails = items?.firstName + " " + items?.lastName;
          if (format === "jsx" && key === "name") {
            const value = () => (
              <div className="chat-name-container">
                <p className="chat-name">
                  {items?.firstName ?  (items?.firstName + " " + items?.lastName) : "LIQUIDITYUSER"}{" "}
                </p>
                <p className="client-id">{items?.customerId ?? "--"} </p>
              </div>
            );

            return (row[key] = value);
          }

          if (format === "jsx" && key === "date") {
            const value = () => (
              <div className="chat-time-container">
                <p className="chat-date">{getDate(items?.createdAt) ?? "--"}</p>
                <p className="chat-time">{getTime(items?.createdAt) ?? "--"}</p>
              </div>
            );

            return (row[key] = value);
          }

          if (format === "jsx" && key === "email") {
            const value = () => (
              <div className="chat-details-personal">
                <p className="email">{items?.email ?? "--"}</p>
                <p className="phone">
                  {items?.countryCode} {items?.phone}
                </p>
              </div>
            );

            return (row[key] = value);
          }
          const { firstName, lastName, message, sentTo } = items ?? {};

          if (format === "jsx" && key === "message") {
            const value = () => (
              <div className="chat-details-message">
                <p className="message">
                  {message
                    ? (getInitials(firstName + " " + lastName) || "LU") +
                      ": " +
                      message
                    : " "}
                </p>
                <p className="message op-2">
                  {sentTo?.lastRecievedMessage
                    ? (getInitials(
                        sentTo?.firstName + " " + sentTo?.lastName
                      ) || "LU") +
                      ": " +
                      sentTo?.lastRecievedMessage
                    : " "}
                </p>
              </div>
            );

            return (row[key] = value);
          }

          if (key === "actions") {
            const value = () => (
              <div className={`chat-action`}>
                {!true ? (
                  <Loader dimension={16} className="loader-blue" />
                ) : (
                  <i
                    className={`ri ${
                      items?.isBlock ? "ri-lock-2-line" : "ri-lock-unlock-line"
                    } ${""}`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      userAccountLocked(items);
                    }}
                  ></i>
                )}
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });

        rows.push(row);
      }
    });

    return rows;
  }, [chatDetails]);

  const redirectToChat = (item: any) => {
    navigate(`/chats/${item?.customerId}`, {
      state: { user: item?.userDetails },
    });
  };

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="chats_list-container"
        rows={renderChatList}
        isLoading={chatDetails?.loading}
        column={CHAT_HEADER}
        showSearch={false}
        showFilter={false}
        handelRowClick={(item: any) => redirectToChat(item)}
      />
    );
  }, [renderChatList, chatDetails?.loading]);

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  const handleUpdateAccountList = (id: string, lockAction: boolean) => {
    const myNextList = [...chatDetails?.data];
    const artwork: any = myNextList?.find((a: Json) => a?.id === id);

    artwork.isBlock = lockAction;
    setChatDetails({ loading: false, data: myNextList });
    setAccountLockId(null);
    successNotification(
      `Chat feature ${lockAction ? "locked" : "unlocked"} successfully`
    );
  };

  const handalConfimation = useCallback(
    async (isOpen: boolean, value: boolean) => {
      if (value) {
        //
        const { isBlock, id } = accountLockId ?? {};
        lockUnlockAccounts(`${API_URL.chatDetails}/${id}`, {
          isBlock: !isBlock,
        }).then((res: any) => {
          if (res?.apiData?.message === "ok") {
            handleUpdateAccountList(id, !isBlock);
          } else {
            errorNotification("Something went wrong");
          }
        });
      } else if (!isOpen) {
        setAccountLockId(null);
      }
    },
    [accountLockId]
  );

  return (
    <>
      <div className="chat-container">
        <div className="chat-container-main">
          <div className="chat-header">
            <Input
              label=""
              inputType="text"
              suffixIcon="ri-search-line"
              placeholder="Search"
              handleChange={(e) =>
                handleFilter({ search: e.target.value, offset: 0 })
              }
            />
          </div>
          <div className="chat-body">{renderSuperAdminTable}</div>
          <Pagination
            showCount={false}
            loading={chatDetails?.loading}
            listCount={chatDetails?.data?.length}
            page={parseInt(filters.offset)}
            perPage={parseInt(filters.limit)}
            onChangePage={(page) => handlePagination(page, "page")}
            onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
          />
        </div>
      </div>
      {accountLockId?.id && (
        <Modal
          isOpen={accountLockId?.id}
          modalName=""
          showCloseBtn={false}
          className="delete-modal"
          isStopOutsideClick={false}
        >
          <Confirmation
            label="Yes"
            type="delete"
            description={`Are you sure you want to ${
              !accountLockId?.isBlock ? "disable" : "enable"
            } chat for ${accountLockId?.firstName}${accountLockId?.lastName}? The user will ${
              !accountLockId?.isBlock ? "not be able to send or receive messages until it is unlocked" : " be able to send or receive messages until it is  unlocked"
            }.`}
            handleModal={handalConfimation}
            title={`${!accountLockId?.isBlock ? "Disable" : "Enable"} chat?`}
            boldDescription=""
            backLabel="Cancel"
            visible={accountLockId}
          />
        </Modal>
      )}
    </>
  );
};
