import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "views/roles/store";

export const useAccess = () => {
  const userDataAccess = useRecoilValue(userDetailsState);

  const isSubModuleAccess = useCallback((accessTab: string, rolesData: any) => {
    const isSubmodules = rolesData?.permissions?.filter(
      (perms: any) => perms?.module?.subModules.length
    );
    const filterArr: any = [];

    isSubmodules?.forEach((module: any) => {
      const filterNArr = module?.module?.subModules?.filter(
        (submodule: any) =>
          submodule?.name?.replace(" ", "")?.toLowerCase() ===
            accessTab?.replace(" ", "")?.toLowerCase() && submodule?.view
      );
      const filterNArrM = module?.module?.subModules?.filter(
        (submodule: any) => {
          return (
            module?.module?.name?.replace(" ", "")?.toLowerCase() ===
              accessTab?.replace(" ", "")?.toLowerCase() && submodule?.view
          );
        }
      );
      if (filterNArr?.length) {
        filterArr.push(filterNArr);
      }
      if (filterNArrM?.length) {
        filterArr.push(filterNArrM);
      }
    });

    if (filterArr.length > 0) {
      return true;
    }
    return false;
  }, []);

  const isAccess = useCallback(
    (accessTab: string) => {
      const rolesData: any = userDataAccess?.data?.roles[0].role;

      const access = rolesData?.permissions?.filter(
        (perms: any) =>
          perms?.module?.name?.replace(" ", "")?.toLowerCase() ===
            accessTab?.replace(" ", "")?.toLowerCase() && perms?.view
      );
      if (access?.length > 0) {
        return true;
      } else {
        return isSubModuleAccess(accessTab, rolesData);
      }
    },
    [isSubModuleAccess, userDataAccess?.data?.roles]
  );
  return { isAccess };
};
