import { Input, Loader } from "@storybook";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import googleLogo from "../../../../assets/images/googleLogo.svg";
import liquidityLogo from "../../../../assets/images/liquidityLogo.svg";
import { STEP_LABELS, loginFormState, useLogin, userAuthState } from "views";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { validateEmail } from "utils";

type CredentialPayload = {
  email: string;
};

export const Login = () => {
  const { handleVerifyEmail, isLoaded } = useLogin();
  const isLocal = /localhost/gi.test(window.location.href);

  const userAuth = useRecoilValue(userAuthState);
  const [loginForm, setLoginForm] = useRecoilState(loginFormState);
  const { get: googleLogin } = useNetwork();
  const { errorNotification } = useNotification();
  const [emailError, setEmailError] = useState({ status: false, message: "Sorry, you do not have access to this platform." });
  const [isDisabled, setIsDisabled] = useState(true);

  const { email } = loginForm;
  const { step } = userAuth;

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setEmailError((prev: any) => { return { ...prev, status: false } })
      setLoginForm({ ...loginForm, [name]: value });
      const isValidEmail = validateEmail(value);
      setIsDisabled(!isValidEmail)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginForm]
  );

  const renderButtonLabel = useMemo(() => {
    if (!isLoaded) {
      return <Loader className="loader-white" dimension={20} />;
    }
    return STEP_LABELS[step] || "";
  }, [step, isLoaded]);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      if (isDisabled) return;
      const payload: CredentialPayload = {
        email: email.trim().toLowerCase(),
      };
      if (email) {
        const res = await handleVerifyEmail(payload);
        if (!res?.status) {
          setEmailError((prev: any) => { return { ...prev, status: true } })
        }
      }
    },
    [email, handleVerifyEmail]
  );

  const handleGoogleLogin = useCallback(() => {
    const originParam = isLocal ? "?origin=localhost" : "";

    googleLogin(`${API_URL.GOOGLELOGIN}${originParam}`).then((res: any) => {
      if (res) {
        const googleUrl = res?.apiData?.data[0];
        window.location.href = googleUrl;
      } else {
        //TODO: update error notification
        errorNotification("Something went wrong");
      }
    });
  }, [errorNotification, googleLogin, isLocal]);

  return (
    <>
      <div className="signin__header">
        <img src={liquidityLogo} alt="liquidity logo" />
      </div>
      <div className="signin-wrapper">
        <div className="signin-wrapper__inner">
          <div className="signin-wrapper__inner__form">
            <div>
              <div className="signin-wrapper__inner__form__title">Log in</div>
              <div className="signin-wrapper__inner__form__subtitle">
                Please log in to your account.
              </div>
            </div>
            <div>
              <button
                className="signin-wrapper__inner__form__googleSignIn"
                onClick={handleGoogleLogin}
                type="button"
              >
                <img src={googleLogo} alt="google logo" /> Sign in with Google
              </button>
            </div>
            <div className="signin-wrapper__inner__form__divider">
              <div className="signin-wrapper__inner__form__divider__line"></div>
              <div className="signin-wrapper__inner__form__divider__text">
                OR
              </div>
              <div className="signin-wrapper__inner__form__divider__line"></div>
            </div>

            <Input
              label="Email"
              handleChange={handleOnChange}
              placeholder=""
              inputName="email"
              inputType="text"
              autoFocus={true}
              isError={emailError?.status}
              errorMessage={emailError?.message}
            />

            <div onClick={handleSubmit} className={`button button__filled--primary button__large button__block ${isDisabled ? "disabled" : "active"}`}>{renderButtonLabel}</div>
          </div>

        </div>
      </div>
    </>
  );
};


