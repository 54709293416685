import type { IInput } from "./types";

import { FC } from "react";
import { Button } from "@storybook";

import "./input.scss";

export const Input: FC<IInput> = ({
  label,
  inputType,
  id,
  placeholder,
  handleChange,
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  handleBlur = () => { },
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  autoComplete,
  inputName,
  maxLength,
  autoFocus = false,
}) => {
  return (
    <div className="input">
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      )}
      <div className="input__value-container">
        <div className="input__group">
          <div
            className={`input__text-field input__text-field${isError ? "--error" : ""
              }`}
          >
            {prefixIcon && <i className={prefixIcon} />}
            <input
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlur}
              id={id}
              className="input__field"
              onChange={handleChange}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              autoFocus={autoFocus}
            />
            {allowClear && (
              <i
                className="ri-close-line input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixIcon && (
              <i
                className={`${suffixIcon} input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && (
            <Button
              label="Add"
              handleClick={handleClickBtn}
              className="button__outline button__large"
            />
          )}
        </div>

        {isError && errorMessage && (
          <span className="input__error"><i className="ri-error-warning-fill"></i>{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
