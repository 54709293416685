import { FC } from "react";
import { Button } from "@storybook";
import type { IConfirmationModal } from "./type";
import banner from "./assets/banner.svg";

import "./confirmation.scss";

export const Confirmation: FC<IConfirmationModal> = ({
  visible,
  title,
  description,
  handleModal,
  boldDescription = "",
  label,
  backLabel = "Close",
  type="",
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div
      className="modal-main"
      tabIndex={0}
      role="button"
      onKeyDown={() => ({})}
      onClick={() => handleModal(false, false)}
    >
      <div
        tabIndex={0}
        role="button"
        onKeyDown={() => ({})}
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="confirmation-header">
          <img src={banner} alt="" className="confirmation-image" />
          <div className="modal-title">{title}</div>
        </div>
        <div>
          <div className="modal-des">
            <span>
              {description}
              {boldDescription && <strong>{boldDescription}</strong>}
            </span>
          </div>
          <div className="modal-footers">
            <Button
              label={backLabel}
              handleClick={() => handleModal(false, false)}
              className="cancle_button"
            />
            <Button
              label={label}
              handleClick={() => handleModal(false, true)}
              className={`delete_button ${type}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
