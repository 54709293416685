import { Json } from "../types";

export const getJsonToParams = (obj: Json) => {
  return "?" + new URLSearchParams(obj || {}).toString();
};

export const getObjectFromUseSearchParams = (searchParams: any = []) => {
  const params: Json = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });

  return params;
};

export const getJsonFromParams = (paramsString: string) => {
  return Object.fromEntries(new URLSearchParams(paramsString));
};


export const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes == 0) return "0 Bytes";
  const k = 1000,
    sizes = ["Bytes", "KB", "MB", "GB", "TB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + " " + sizes[i]
  );
};

export const base64URLStringToBuffer = (base64URLString: any) => {
  // Convert from Base64URL to Base64
  const base64 = base64URLString.replace(/-/g, "+").replace(/_/g, "/");
  /**
   * Pad with '=' until it's a multiple of four
   * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
   * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
   * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
   * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
   */
  const padLength = (4 - (base64.length % 4)) % 4;
  const padded = base64.padEnd(base64.length + padLength, "=");

  // Convert to a binary string
  const binary = atob(padded);

  // Convert binary string to buffer
  const buffer = new ArrayBuffer(binary.length);
  const bytes = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  return buffer;
};

export const bufferToBase64URLString = (buffer: any) => {
  const bytes = new Uint8Array(buffer);
  let str = "";

  for (const charCode of bytes as any) {
    str += String.fromCharCode(charCode);
  }

  const base64String = btoa(str);

  return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};

const attachments = ["cross-platform", "platform"];

export const toAuthenticatorAttachment = (attachment: any) => {
  if (!attachment) {
    return;
  }

  if (attachments.indexOf(attachment) < 0) {
    return;
  }

  return attachment;
};

export const asterisksString = (str: string) => {
  const temp = str?.split(" ");
  temp?.forEach((s, i) => {
    temp[i] = s[0] + "*".repeat((s?.length || 1) - 1)
  });
  return temp.join(" ")
};

export const getInitials = (name: any) => {
  if (!name) return "LU";
  const words = name.split(" ");
  let initials =
    words[0]?.charAt(0).toUpperCase() +
    (words.length > 1
      ? words[1]?.charAt(0).toUpperCase()
      : "");
  return initials;
}

export const convertToDays = (durationObj: any, byDdefaultDaysInMonth: number  = 31) => {
  const { duration, unit } = durationObj;
  let days;

  switch (unit) {
    case "days":
      days = duration;
      break;
    case "day":
      days = duration;
      break;
    case "week":
      days = duration * 7;
      break;
    case "month":
      days = duration * byDdefaultDaysInMonth;
      break;
    case "year":
      days = duration * 365;
      break;
    default:
      return 0;
  }

  return days;
}
