import { useCallback, useState } from "react";

import { Loader, TextArea } from "@storybook";
import {  useDvpApis } from "../store";
import { useNotification } from "hooks";
import banner from "../../../assets/images/banner.svg";


export const RejectModal = ({
  selectedAsset,
  handelFilter,
  handeCloseReject,
}: any) => {
  const [reason, setReason] = useState<any>("");
  const { dvpAction, loading:dvpActionLoading } = useDvpApis();
  const { successNotification, errorNotification } = useNotification();

  const handelInput = useCallback((e: any) => {
    const { value } = e.target;
    setReason(value);
  }, []);


  const handelReject = useCallback(() => {
    if (reason === "") {
      errorNotification("Please enter reason of rejection");
      return;
    }
    if (dvpActionLoading) return;
   dvpAction(selectedAsset?.id, {
     status: "REJECTED",
     reason: reason,
   }).then((res: any) => {
     if (res?.success) {
       handelFilter({ name: "", offset: 0 });
       handeCloseReject();
       successNotification("DVP request rejected");
     } else {
       errorNotification(res?.message);
     }
   });
  }, [
    errorNotification,
    // fileList,
    handeCloseReject,
    handelFilter,
   dvpAction,
   dvpActionLoading,
    reason,
    selectedAsset?.id,
    successNotification,
  ]);

  return (
    <div className="reject-auction">
      <div className="confirmation-header">
        <img src={banner} alt="" className="confirmation-image" />
        <div className="modal-title">{"Reject DVP request?"}</div>
      </div>
      <div className="reject--auction reject--dvp">
        Reason for rejection (Required)
        <TextArea
          handleChange={handelInput}
          label={""}
          value={reason}
          placeholder={""}
          row={2.5}
        />
        {/* {selectedAsset?.dataRoom?.length > 0 && (
          <>
            <h3>Files</h3>
            {selectedAsset?.dataRoom.map((item: any, index: number) => (
              <div className="support-doc">
                <div className="support--doc">
                  {" "}
                  <div className="doc-img">
                    <img
                      src={
                        FILE_EXTENSION_URL[
                          item?.docName?.split(".").pop().toLowerCase()
                        ]
                      }
                      alt=""
                      data-tooltip-id={item?.id}
                    />
                    <div className="support--doc_name">{item?.docName}</div>
                    <div className="dot"></div>{" "}
                    {formatFileSize(item?.fileSize, 2)}
                  </div>
                  <div className="support-action">
                    <button onClick={() => handelOpenDocList(index)}>
                      Add comment
                    </button>
                    <button onClick={() => handelView(item?.fileUrl)}>
                      View
                    </button>
                  </div>
                </div>
                {openDocNumber === index && (
                  <div>
                    <div className="cooment-add">
                      Comment{" "}
                      <i
                        className="ri-close-line"
                        onClick={() => handelViewClose(item)}
                      ></i>
                    </div>
                    <TextArea
                      handleChange={(e) => handelInputReason(item, e)}
                      label={""}
                      value={reasonValue(item) as any}
                      placeholder={""}
                      row={3}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )} */}
      </div>
      <div className="action-btns">
        <button className="cancel" onClick={handeCloseReject}>
          Cancel
        </button>
        <button className="reject" onClick={handelReject}>
          {" "}
          {dvpActionLoading ? <Loader dimension={20} /> : "Reject"}
        </button>
      </div>
    </div>
  );
};
