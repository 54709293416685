import {  useCallback, useState } from "react";

import { Input, Loader } from "@storybook";
import {  useDvpApis } from "../store";
import { formatNumberWithCommas, useNotification } from "hooks";
export const ApproveModal = ({
  selectedAsset,
  handelFilter,
  handeCloseReject,
}: any) => {

  const [amount, setAmount] = useState(selectedAsset?.requestedLimit);
  const { dvpAction, loading: dvpActionLoading } = useDvpApis();
  const { successNotification, errorNotification } = useNotification();

  const handelInput = useCallback((e: any) => {
    const { value } = e.target;
    const validValue = /^\d*(\.\d{0,2})?$/.test(value);
     if (!validValue) return;
    setAmount(value);
  }, []);

  
  const handelApprove = useCallback(() => {
    const amountVal = Number(amount)
    if (amountVal <= 0 || !amountVal) {
      errorNotification("Please enter DVP limit");
      return;
    }
    if (dvpActionLoading) return;
    dvpAction(selectedAsset?.id, {
      status: "ACCEPTED",
      // fileData: fileList,
      actualLimit: amountVal,
    }).then((res: any) => {
      if (res?.success) {
        handelFilter({ name: "", offset: 0 });
        handeCloseReject();
        successNotification("DVP request accepted");
      } else {
        errorNotification(res?.message);
      }
    });
  }, [
    errorNotification,
    handeCloseReject,
    handelFilter,
    dvpAction,
    dvpActionLoading,
    amount,
    selectedAsset?.id,
    successNotification,
  ]);

  return (
    <div className="reject-auction">
      <h3>DVP Request</h3>
     
      <div className="reject--auction">
        <div className="request-limit">
          Requested DVP Limit : <span>$ {formatNumberWithCommas(selectedAsset?.requestedLimit)}</span>
        </div>
        Set Custom DVP Limit
       
        <Input
          label={""}
          inputType={"number"}
          placeholder={"$0.00"}
          handleChange={handelInput}
          value={amount}
        />
        {/* Todo: once doc will upload we wil show this */}
        {/* Attachments
        {selectedAsset?.dataRoom?.length > 0 && (
          <>
            {selectedAsset?.dataRoom.map((item: any) => (
              <div className="support-doc">
                <div className="support--doc">
                  {" "}
                  <div className="doc-img">
                    <img
                      src={
                        FILE_EXTENSION_URL[
                          item?.docName?.split(".").pop().toLowerCase()
                        ]
                      }
                      alt=""
                      data-tooltip-id={item?.id}
                    />
                    <div className="support--doc_name">{item?.docName}</div>
                  
                  </div>
                  <div className="support-action">
                  
                    <button onClick={() => handelView(item?.fileUrl)}>
                      View
                    </button>
                  </div>
                </div>
               
              </div>
            ))}
          </>
        )} */}
      </div>
      <div className="action-btns">
        <button className="cancel" onClick={handeCloseReject}>
          Cancel
        </button>
        <button className="approve" onClick={handelApprove}>
          {" "}
          {dvpActionLoading ? <Loader dimension={20} /> : "Approve"}
        </button>
      </div>
    </div>
  );
};
