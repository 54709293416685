import type { IDateRange } from "@storybook";

import { atom } from "recoil";

import { defineds } from "@storybook/date-range-picker/defineds";

const { startOfToday, endOfThirtyDays } = defineds;

export const DatePickerVisibleState = atom<boolean>({
  key: "date-picker-visible-state",
  default: false,
});
export const updateNewDataState = atom<boolean>({
  key: "update-new-data-state",
  default: false,
});

export const SelectedDateRangeState = atom<IDateRange[]>({
  key: "selected-date-range-state",
  default: [
    {
      startDate: endOfThirtyDays,
      endDate: startOfToday,
      key: "selection",
    },
  ],
});

export const SelectedMultipleDateState = atom<string[]>({
  key: "selected-multiple-date-state",
  default: [],
});
