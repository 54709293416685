export const isValidMobileNumber = (mobileNumber: string) => {
  const mob_regex = /^(?:(?:\+?91\s*[-]?\s*)?|[0]?)?[789]\d{9}$/;
  const isValid = mob_regex.test(mobileNumber);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isNumber = (number: string) => {
  const number_regex = /^[0-9]*$/;
  const isValid = number_regex.test(number);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isValidEmail = (email: string) => {
  return !!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};