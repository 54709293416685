import type { IReactSwitch } from "./types";

import { FC } from "react";
import Switch from "react-switch";

export const ReactSwitch: FC<IReactSwitch> = ({
  id,
  checked,
  handleChange,
  width,
  height,
  onColor,
  offColor,
  diameter,
  isDisabled = false,
}) => {
  return (
    <Switch
      id={id}
      checked={checked}
      disabled={isDisabled}
      checkedIcon={false}
      onColor={onColor ?? "#4574f5"}
      offColor={offColor ?? "#C9CDD6"}
      uncheckedIcon={false}
      height={height ?? 24}
      width={width ?? 48}
      handleDiameter={diameter ?? 16}
      onChange={handleChange}
    />
  );
};
