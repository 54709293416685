import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { MobileVerificationState, userLoggedInDetails } from "../../store/state";
import { useNotification } from "hooks";
import { ApprovalStatusState, LoginRoutesState } from "../../store/state";
import liquidity from "../../../../assets/icon/liquidity.gif";
import { useNetwork } from "hooks";
import { useBiometricAuthentication } from "../../store/hooks";
import { API_URL } from "constant";

export const BiometricApprovalSent = () => {
    const [verificationId, setVerificationId] = useRecoilState(MobileVerificationState);
    const loginDetails = useRecoilValue(userLoggedInDetails);
    const { phone, countryCode } = loginDetails;
    const { successNotification, errorNotification } = useNotification();
    const seLoginRoutes = useSetRecoilState(LoginRoutesState);
    const [isSmsSending, setIsSmsSending] = useState(false);
    let mobileLinkVerifyInterval: any = useRef(null);

    const setVerificationStatus = useSetRecoilState(ApprovalStatusState);

    const { registrationApproval } = useBiometricAuthentication();

    const { get: checkLinkStatus } = useNetwork();
    const checkBiometricMobileVerificationStatus = async (id: string) => {
        try {
            const response = await checkLinkStatus(`${API_URL.VerifyPhone}/${id}`);
            return response;
        } catch (err: any) {
            console.log(err?.message);
            clearInterval(mobileLinkVerifyInterval.current);
        }
    };

    const checkStatus = async () => {
        const res = await checkBiometricMobileVerificationStatus(verificationId);
        if (res) {
            const { status } = res?.apiData?.data;
            if (status === "approved") {
                clearInterval(mobileLinkVerifyInterval.current);
                setVerificationStatus(res?.apiData?.data?.status);
                seLoginRoutes("chooseMethod");
                successNotification("Mobile verified successfully");
            }
            if (status === "rejected") {
                // stop the interval and login the user
                clearInterval(mobileLinkVerifyInterval.current);
                errorNotification("Mobile verification failed");
                seLoginRoutes("chooseMethod");
            }
        }
    };

    useEffect(() => {
        if (verificationId) {
            mobileLinkVerifyInterval.current = setInterval(() => {
                checkStatus();
            }, 3000);
        }

        return () => clearInterval(mobileLinkVerifyInterval.current);
    }, [verificationId]);

    const cancelApproval = () => {
        clearInterval(mobileLinkVerifyInterval.current);
        seLoginRoutes("chooseMethod");
    };

    const resendSms = useCallback(async () => {
        if (isSmsSending) return;
        if (phone && countryCode) {
            setIsSmsSending(true);
            const approvalPayload = {
                type: "registrationApproval",
                phone,
                countryCode,
                deviceInfo: navigator.userAgent ?? {},
            };
            const response = await registrationApproval(approvalPayload);
            if (response) {
                const { message, success, verificationId } = response?.apiData?.data;
                if (success && verificationId) {
                    setVerificationId(verificationId)
                    successNotification(message);
                    return;
                } else {
                    errorNotification(message);
                }
                setIsSmsSending(false);
            }
        }
    }, [countryCode, errorNotification, isSmsSending, phone, registrationApproval, setVerificationId, successNotification]);

    return (
        <div className="biometric-approval">
            <div className="biometric-approval__wrapper">
                <div className="biometric-approval__row">
                    <div className="biometric-approval__title">Waiting for approval</div>
                    <div className="biometric-approval__description">
                        We have sent you a text message to &nbsp;
                        <strong className="biometric-approval__number">
                            {countryCode} {phone}. &nbsp;
                        </strong>
                        Open the link provided in the text message and approve from there
                    </div>
                </div>
                <div className="biometric-approval__row align-center">
                    <img src={liquidity} alt="Liquidity" />
                </div>
                <div className="biometric-approval__row">
                    <button onClick={cancelApproval}>Back</button>
                </div>
                <div className="biometric-approval__row">
                    <span className="resent-link">
                        Didn’t received text message? <i onClick={resendSms}> Resend </i>
                    </span>
                </div>
            </div>
        </div>
    );
};