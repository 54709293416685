import { Button, Loader, Modal, ReactResponsiveTable } from "@storybook";
import "./userListModal.scss";
import { useEffect, useMemo, useState } from "react";
import { USER_TABLE_HEADER } from "views/accounts/store/constant";
import { OnboardingFlowStatus } from "../onboardingFlowStatus";
import { useAccountApis } from "../../store/hooks";
import { Json } from "types";
import { useNotification } from "hooks";
import { getDate, getTime } from "utils";
import { useUserPermission } from "hooks/user-permission";

type Props = {
  visible: boolean;
  setModalVisible: () => void;
  sessionId: string;
  handleSessionClick?: any;
};

export const UserListModal = (props: Props) => {
  const { visible, setModalVisible, sessionId } = props;
  const [coOwnerData, setCoOwnerData] = useState<any>([]);
  const [accountLockId, setAccountLockedId] = useState<string | null>(null);
  const { successNotification } = useNotification();
  const { getCoOwnerList, loading } = useAccountApis();
  const { lockUnlockAccounts, loading: lockAccountLoading } = useAccountApis();
  const buttonDisable = useUserPermission();

  useEffect(() => {
    sessionId &&
      getCoOwnerList(
        `?sessionId=${sessionId}&offset=0&limit=12&orderby=asc&orderField=createdAt`
      ).then((resp) => {
        setCoOwnerData(resp);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertJson = (onboardingStatus: Json): Json => {
    if (!Object.keys(onboardingStatus).length) return [];
    const result: Json = {};
    onboardingStatus?.forEach((item: Json) => {
      result[item?.stepId] = item?.status ?? "processing";
    });
    return result;
  };

  // TODO: make these functions generic from acountlist and this file (refactor)
  const handleUpdateAccountList = (Id: string, lockAction: boolean) => {
    const myNextList = [...coOwnerData];
    const artwork: any = myNextList?.find((a: Json) => a?.id === Id);
    artwork.isAccountLocked = lockAction;
    setCoOwnerData(myNextList);
    setAccountLockedId("");
    successNotification(
      `Account ${lockAction ? "locked" : "unlocked"} successfully`
    );
  };

  const updateAccountStatus = (e: any, Id: string, lockAction: boolean) => {
    e.stopPropagation();
    if (lockAccountLoading) return;
    setAccountLockedId(Id);
    lockUnlockAccounts(Id, {
      type: "co-owner",
      isLocked: lockAction,
    }).then((res: any) => {
      if (res?.success) {
        handleUpdateAccountList(Id, lockAction);
      }
    });
  };

  // const openSessionModal = (id: string) => {
  //   handleSessionClick(id);
  // };

  const renderAccountList = useMemo(() => {
    if (!coOwnerData) return []; //TODO: need to change this
    const rows: any = [];
    coOwnerData?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        USER_TABLE_HEADER.forEach(({ format, key }) => {
          row.name = items?.name ?? "--";
          row.role = items?.userType ?? "--";

          if (format === "jsx" && key === "email") {
            const value = () => (
              <div className="account-details-personal">
                <p className="email">{items?.email ?? "--"}</p>
                <p className="phone">
                  ({items?.countryCode}) {items?.phone ?? "--"}
                </p>
              </div>
            );

            return (row[key] = value);
          }
          if (format === "jsx" && key === "OnboardingStatus") {
            const value = () => (
              <>
                <div className="session-status">
                  <OnboardingFlowStatus
                    fullStatus={convertJson(items?.onboardingStatus)}
                  />
                </div>
              </>
            );
            return (row[key] = value);
          }

          if (key === "dateTime") {
            const value = () => (
              <div className="account-creation-details">
                <p className="creation-day">{getDate(items?.createdAt)}</p>
                <p className="creation-time">{getTime(items?.createdAt)}</p>
              </div>
            );
            return (row[key] = value);
          }

          if (key === "actions") {
            const value = () => (
              <div className={`account-actions accountdropdown-list ${buttonDisable? "disable": ""}`}>
                {/* <button
                  onClick={() => openSessionModal(items?.sessionId ?? "")}
                >
                  View session
                </button> */}
                {accountLockId?.includes(items?.id) && lockAccountLoading ? (
                  <Loader dimension={16} className="loader-blue" />
                ) : (
                  <i
                    className={`ri ${
                      items?.isAccountLocked
                        ? "ri-lock-2-line"
                        : "ri-lock-unlock-line"
                    } ${buttonDisable ? 'disablebtn' : ''}`}
                    onClick={(e) =>
                      updateAccountStatus(e, items?.id, !items?.isAccountLocked)
                    }
                  ></i>
                )}
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });

        rows.push(row);
      }
    });

    return rows;
  }, [coOwnerData, lockAccountLoading]);

  const renderUserList = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="account__container-body"
        rows={renderAccountList}
        column={USER_TABLE_HEADER}
        // height="calc(100vh - 285px)"
        showSearch={false}
        showFilter={false}
        isLoading={loading}
        //handelRowClick={() => handleRowClick(true)}
      />
    );
  }, [renderAccountList, loading]);

  return (
    <>
      <Modal
        isOpen={visible}
        modalName="user Modal"
        className="user_modal"
        showCloseBtn={true}
        isStopOutsideClick={true}
        closeOnEscBtn={true}
        closeModal={setModalVisible}
      >
        <div className="user_modal_title">
          <p>Co-owner/Authorised User</p>
        </div>
        <div className="user-modal_body">{renderUserList}</div>
        <div className="user-modal_footer">
          <Button
            label="Close"
            handleClick={() => setModalVisible()}
            className="btn-default"
          />
        </div>
      </Modal>
    </>
  );
};
