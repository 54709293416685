import { useCookie } from "hooks";
import { Navigate } from "react-router-dom";
import { APP_ROUTES, RouteProps } from "views";

export const PublicRoute = ({ element }: RouteProps): JSX.Element => {
  const { get: getCookieData } = useCookie();
  const { NO_ROUTE } = APP_ROUTES;

  const userDetails = getCookieData("userDetails");
  const { token: loginToken } = userDetails ?? {};

  const isAuthenticated = loginToken ? true : false;

  return isAuthenticated ? <Navigate to={NO_ROUTE} /> : <>{element}</>;
};
