import { FC, useMemo } from 'react';
import cn from 'classnames';

import './skeleton-loader.scss';

interface ISkeletonTable {
  listsToRender: number;
  numberColumn: number;
  column?: any;
  className?:string
}

export const SkeletonTable: FC<ISkeletonTable> = ({
  listsToRender,
  numberColumn,
  column,
  className,
}) => {
  const columns = column ?? Array(numberColumn).fill(1);
  const skeletonLoader = useMemo(() => {
    return Array(listsToRender)
      .fill(1)
      .map((card: any, index: number) => (
        <div
          key={`table__${index.toString() + card}`}
          className={`skeleton_table ${className}`}
        >
          {columns.map((colCard: any, idx: number) => (
            <div
              className="sub_table_div"
              key={`sub-table__${idx.toString()}`}
              style={{ width: colCard.width }}
            >
              <div
                className={cn("skeleton_table_symbol skeleton-loader-light")}
              />
            </div>
          ))}
        </div>
      ));
  }, [listsToRender, columns]);

  return <div className="skeleton_body">{skeletonLoader}</div>;
};
