import { atom } from "recoil";

export interface IUserSelectedCurrencyState {
  code: string;
  symbol: string;
  thousandsSeparator: string;
  decimalSeparator: string;
  symbolOnLeft: boolean;
  spaceBetweenAmountAndSymbol: boolean;
  decimalDigits: number;
  rate: number;
}

export const userSelectedCurrencyState = atom<IUserSelectedCurrencyState>({
  key: "user-selected-currency-state",
  default: {
    code: "USD",
    decimalDigits: 2,
    decimalSeparator: ".",
    rate: 1,
    spaceBetweenAmountAndSymbol: false,
    symbol: "$",
    symbolOnLeft: true,
    thousandsSeparator: ",",
  },
});
