import { atom } from "recoil";
import { Json } from "types";

export const AccountDataState = atom<any[]>({
  key: "account-data-state",
  default: [],
});

export const initalFilterState = atom<Json>({
  key: "initail-filter-account-state",
  default: { limit: 10, offset: 0 },
});



export const FilterState = atom<Json>({
  key: "filter-account-state",
  default: { limit: 10, offset: 0 },
});