import { useState } from "react";
import { getInitials } from "utils";

export const ChatPanel = ({name, logo, message = '', data = '', handleClick, className = '', isUserOnline = false, initials}: any) => {
  const [onImageerror, setonImageError] = useState(false)
   

  return (
    <div
      className={`chat-panel ${className} `}
      onClick={() => handleClick(data)}
    >
      <div className="image-container">
        { onImageerror || !logo ? (
          <span className="name-initial">{initials || getInitials(name)} </span>
        ) : (
          <img src={logo} alt="LU" onError={() => setonImageError(true)} />
        )}
        {isUserOnline && <div className="circle-dot"></div>}
      </div>
      <div className="chat-personal-details">
        <h3>{name}</h3>
        <p>{message}</p>
      </div>
    </div>
  );
}