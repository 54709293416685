import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { Permission } from "./permission";
import { Button, Input } from "@storybook";
import {
  addRoleState,
  createFormState,
  editRoleState,
  pervissionViewState,
  selectedRoleState,
} from "../store";
import { useNetwork, useNotification, useRolesApi } from "hooks";
import { API_URL } from "constant";

export const AddRole = () => {
  const setVisibleAddRole = useSetRecoilState(addRoleState);
  const [createForm, setCreateForm] = useRecoilState(createFormState);
  const [editRole, setEditRole] = useRecoilState(editRoleState);
  const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleState);

  const setPermissionView = useSetRecoilState(pervissionViewState);

  const { fetchRoleList } = useRolesApi();
  const { successNotification, errorNotification } = useNotification();
  const { post: createRole, data: roleData, error: roleError } = useNetwork();
  const {
    patch: updateRole,
    data: roleUpdateData,
    error: roleUpdateError,
    apiResponse,
  } = useNetwork();

  const handelCreateRole = useCallback(() => {
    if (!createForm?.name) {
      errorNotification("Please Enter Role Name");
      return;
    }
    if (!createForm?.description) {
      errorNotification("Please Enter Role Description");
      return;
    }
    const rolePayload = { ...createForm, name: createForm?.name.trim() };
    if (editRole) {
      updateRole(`${API_URL.ROLES}/${selectedRole._id}`, rolePayload);
    } else {
      createRole(API_URL.ROLES, rolePayload);
    }
  }, [
    createForm,
    createRole,
    editRole,
    errorNotification,
    selectedRole._id,
    updateRole,
  ]);

  useEffect(() => {
    if (roleError) {
      errorNotification(roleError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleError]);

  useEffect(() => {
    if (roleUpdateError) {
      errorNotification(roleError?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUpdateError]);
  useEffect(() => {
    if (roleUpdateData?.data && apiResponse?.status === 200) {
      fetchRoleList();
      successNotification("Role Updated");
      setPermissionView(false);
      setVisibleAddRole(false);
      setEditRole(false);
      setSelectedRole({});
      setCreateForm({});
    } else {
      errorNotification(roleUpdateData?.message);
    }
  }, [
    roleUpdateData,
    fetchRoleList,
    successNotification,
    setPermissionView,
    setVisibleAddRole,
    setEditRole,
    setSelectedRole,
    setCreateForm,
    errorNotification,
  ]);

  useEffect(() => {
    if (roleData) {
      if (roleData?.data?._id) {
        fetchRoleList();
        setCreateForm({});
        successNotification("Role Created");
        setVisibleAddRole(false);
      } else {
        errorNotification(roleData?.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  const handelInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>, name: string) => {
      const { value } = e.target;
      setCreateForm((pre: any) => ({ ...pre, [name]: value }));
    },
    []
  );

  const [roleButtonlabel, roleEditheading] = useMemo(() => {
    if (editRole) {
      return ["Save", "Edit role"];
    }
    return ["Add role", "Add new role"];
  }, []);

  const closeAddRole = useCallback(() => {
    setVisibleAddRole(false);
    setCreateForm({});
    setEditRole(false);
  }, []);

  return (
    <>
      <div className="add-role-header">
        <h2>{roleEditheading}</h2>

        <p>
          Define roles, set permissions and assign to users for streamlined
          access{" "}
        </p>
      </div>
      <Input
        label={"Role name"}
        inputType={"text"}
        placeholder={"Enter role name"}
        handleChange={(e: ChangeEvent<HTMLInputElement>) =>
          handelInput(e, "name")
        }
        value={createForm?.name}
        disabled={editRole}
      />
      <div className="description-addRole">
        <Input
          label={"Description"}
          inputType={"text"}
          handleChange={(e: ChangeEvent<HTMLInputElement>) =>
            handelInput(e, "description")
          }
          placeholder={"Enter description here..."}
          value={createForm?.description}
        />
      </div>
      <Permission />

      <div className="footer-btn">
        <Button
          label={"Cancel"}
          className="button__outline"
          handleClick={closeAddRole}
        />
        <Button
          label={roleButtonlabel}
          className="button__filled--primary"
          handleClick={handelCreateRole}
        />
      </div>
    </>
  );
};
