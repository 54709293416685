import { useRecoilValue } from "recoil";
import { chatDetails } from "views/chats/state";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce, useNetwork } from "hooks";
import { API_URL } from "constant";
import { getInitials, getJsonToParams, getTime } from "utils";
import { Loader } from "@storybook";
import InfiniteScroll from "react-infinite-scroll-component";
import { format, isToday, isYesterday } from 'date-fns';

import "./chat-screen.scss";
import { Json } from "types";

const ChatProfileImage = ({ name = "" }) => {
  return (
    <>
      {
        <span className="chat-initial">{getInitials(name) || "LU"}</span>
      }
    </>
  );
};

export const ChatScreen = () => {
  const selectedChatDetails = useRecoilValue(chatDetails);
  const { get: getChatHistory } = useNetwork();
  const [assetChats, setAssetChats] = useState<any>([]);
  const [onImageerror, setonImageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialFilter = { search: "", limit: 20, offset: 0 };
  const [filters, setFilters] = useState<any>(initialFilter);
  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  const {receiverName, profileImage, isOnline} = selectedChatDetails?.receiverInfo ?? {};
  const { senderName } = selectedChatDetails?.senderInfo ?? {};
  const { chatId } = selectedChatDetails;
 
  useEffect(() => {
    if (chatId) {
      setAssetChats([]);
      setFilters((prev: any) => ({ ...prev, chatId: chatId, offset: 0 }));
    }
  }, [chatId]);

  useEffect(() => {
    if (chatId) fetchChatHistory();
  }, [debouncedFilters]);

  const fetchChatHistory = useCallback(async () => {
    setLoading(true);
    const { immediate, chatId, ..._filters } = filters;
    const response = await getChatHistory(
      `${API_URL.chatHistory}/${chatId}` + getJsonToParams({ ..._filters })
    );
    const { apiData } = response ?? {};
    if (apiData?.message === "ok") {
      setLoading(false);
      setAssetChats((prev: any) => [...prev, ...apiData?.data]);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, filters]);

  const handleFetchMore = useCallback(() => {
    setFilters((prev: any) => ({ ...prev, offset: prev.offset + 1 }));
  }, []);

  const groupMessagesByDate = (messages: any) => {
    return messages.reduce((acc: any, message: Json) => {
      const date = new Date(message.createdAt).setHours(0, 0, 0, 0).toString();

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(message);
      // acc[date].sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      return acc;
    }, {});
  };

  const getFormattedDate = (date: any) => {
    if (isToday(date)) return "Today";
    if (isYesterday(date)) return "Yesterday";
    return format(date, 'dd MMM yyyy');
  };

  const groupedMessages = groupMessagesByDate(assetChats);

  return (
    <div className="chat-screen__container">
      <div className="chat-screen__header">
      <div className="image-container">
        {onImageerror || !profileImage? (
          <span className="name-initial">{getInitials(receiverName)}</span>
        ) : (
          <img
            src={profileImage}
            className="name-initial"
            alt="LU"
            onError={() => setonImageError(true)}
          />
        )}
        {isOnline && <div className="circle-dot"></div>}
        </div>
        <p>{receiverName || "LIQUIDITYUSER"}</p>
      </div>
      <div id="chat-history-div" className="chat-screen__body">
        <InfiniteScroll
          style={{ display: "flex", flexDirection: "column-reverse" }}
          dataLength={assetChats?.length || 0}
          next={handleFetchMore}
          inverse={true}
          hasMore={
            assetChats?.length ===
            filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="chat-history-div"
        >
          {Object.keys(groupedMessages).map((date) => (
            <React.Fragment key={date}>
              {assetChats?.length > 0 &&
                groupedMessages[date]?.map((item: any) => {
                  return (
                    <div
                      key={item?.id}
                      className={`chat-user ${
                        item?.senderId === selectedChatDetails?.receiverId
                          ? "row-reverse"
                          : ""
                      }`}
                    >
                      <span className="text">
                        <p className="text-message">{item?.message}</p>
                        <small className="chat-time">
                          {getTime(item?.createdAt) ?? "--"}
                        </small>
                      </span>
                      <ChatProfileImage
                        name={
                          item?.senderId === selectedChatDetails?.receiverId
                            ? receiverName
                            : senderName
                        }
                      />
                    </div>
                  );
                })}
                <div className="chat-date">{getFormattedDate(new Date(parseInt(date)))}</div>
            </React.Fragment>
          ))}
        </InfiniteScroll>
        {loading && <Loader className="loader-blue" dimension={18} />}
      </div>
    </div>
  );
};
