import { useCallback, useEffect, useRef, useState } from "react";
import { Json } from "types";

type ICountDownTimer = {
  dateTime: Date | string | number;
  renderer: (value: Json) => any;
  counterType?: "down" | "forward";
};
export const CountdownTimer = ({
  dateTime,
  renderer,
  counterType = "down",
}: ICountDownTimer): any => {
  const [renderState, setRenderState] = useState("");
  const [countdownTime, setCountdownTime] = useState<any>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    completed: false,
  });
  const timerRef = useRef<any>(null);
  const countdown = useCallback(() => {
    timerRef.current = setInterval(() => {
      const countdownDateTime = new Date(dateTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime: any = {
        days: totalDays,
        hours: totalHours,
        minutes: totalMinutes,
        seconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timerRef.current);
        setCountdownTime({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          completed: true,
        });
      }
    }, 1000);
  }, [dateTime]);

  const countForward = useCallback(() => {
    timerRef.current = setInterval(() => {
      const countDateTime = new Date(dateTime).getTime();
      const currentTime = new Date().getTime();
      const forwardDayTime = currentTime - countDateTime;
      const totalDays = Math.floor(forwardDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (forwardDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (forwardDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((forwardDayTime % (1000 * 60)) / 1000);

      const runningCountTime: any = {
        days: totalDays,
        hours: totalHours,
        minutes: totalMinutes,
        seconds: totalSeconds,
      };

      setCountdownTime(runningCountTime);
    }, 1000);
  }, [dateTime]);

  useEffect(() => {
    if (counterType === "forward") {
      countForward();
    } else {
      countdown();
    }
    return () => clearInterval(timerRef.current);
  }, [countdown, countForward, dateTime, counterType]);

  useEffect(() => {
    setRenderState(renderer(countdownTime));
  }, [countdownTime]);

  return renderState;
};
