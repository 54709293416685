// OauthRedirectHandler.js
import { useCookie, useNetwork, useNotification } from "hooks";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../routes/store/constant";
import { Loader } from "@storybook";

import "./component/login/login.scss";
import { API_URL } from "constant";
import { LoginRoutesState, userLoggedInDetails } from "./store";
import { useSetRecoilState } from "recoil";

const { NO_ROUTE, USER_LOGIN } = APP_ROUTES;

export const OauthRedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { set: setCookieToken } = useCookie();
  const { post: exchangeCodeForToken } = useNetwork();
  const isLocal = /localhost/gi.test(window.location.href);
  const { errorNotification } = useNotification();
  const setUserAuthDetails = useSetRecoilState(userLoggedInDetails);
  const setLoginRoute = useSetRecoilState(LoginRoutesState);

  const errorToaster: any = (
    <>
      <div className="error-header">Authentication Failed</div>
      <div className="error-body">
        You don’t have access to this platform. Please use a different account
        or contact support for assistance.
      </div>
    </>
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      const loginOptions = {
        code,
        origin: isLocal ? "localhost" : undefined,
      };

      exchangeCodeForToken(API_URL.GOOGLELOGIN, loginOptions).then(
        (res: any) => {
          const { response, apiData } = res;
          if (response?.status === 200) {
            setUserAuthDetails(apiData?.data);
            setLoginRoute("chooseMethod");
          } else {
            errorNotification(errorToaster);
          }
          setTimeout(() => {
            navigate(NO_ROUTE);
          }, 1000);
        }
      );
    } else {
      navigate(USER_LOGIN);
    }
  }, [
    exchangeCodeForToken,
    isLocal,
    location.search,
    navigate,
    setCookieToken,
  ]);

  return (
    <div className="login_Container">
      <Loader />
    </div>
  );
};
