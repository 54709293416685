import { Input } from "@storybook";
import { useCallback } from "react";
import pdfType from "../../../assets/images/pdfType.png";

export const RejectModal = () => {
  const handelInput = useCallback(() => {}, []);
  return (
    <div className="reject-auction">
      <h3>Some text</h3>
      <div className="reject--auction">
        Reason for rejection
        {/* <textarea rows={4} cols={50}></textarea> */}
        <Input
          label={""}
          inputType={"number"}
          placeholder={""}
          handleChange={handelInput}
        />
        <h3>Data room files</h3>
        <div className="support-doc">
          <div className="support--doc">
            {" "}
            <div className="doc-img">
              <img src={pdfType} alt="" />
              <div className="support--doc_name">File Name </div>
              <div className="dot"></div>2.4MB
            </div>
            <div className="support-action">
              <button>Add comment</button>
              <button>View</button>
              <i className="ri-more-line"></i>
            </div>
          </div>
        </div>
        <div className="support-doc">
          <div className="support--doc">
            {" "}
            <div className="doc-img">
              <img src={pdfType} alt="" />
              <div className="support--doc_name">File Name </div>
              <div className="dot"></div>2.4MB
            </div>
            <div className="support-action">
              <button>Add comment</button>
              <button>View</button>
              <i className="ri-more-line"></i>
            </div>
          </div>
          <div>
            <div className="cooment-add">
              Comment <i className="ri-close-line"></i>
            </div>
            <Input
              label={""}
              inputType={"number"}
              placeholder={""}
              handleChange={handelInput}
            />
          </div>
        </div>
        <div className="support-doc">
          <div className="support--doc">
            {" "}
            <div className="doc-img">
              <img src={pdfType} alt="" />
              <div className="support--doc_name">File Name </div>
              <div className="dot"></div>2.4MB
            </div>
            <div className="support-action">
              <button>Add comment</button>
              <button>View</button>
              <i className="ri-more-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="action-btns">
        <button className="approve">Cancel</button>
        <button className="reject"> Reject</button>
      </div>
    </div>
  );
};
