export const CHAT_HEADER = [
  {
    label: "Name",
    key: "name",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Email & Contact",
    key: "email",
    format: "jsx",
    className: "",
    width: "25%",
  },
  {
    label: "Messages",
    key: "message",
    format: "jsx",
    className: "",
    width: "35%",
  },
  {
    label: "Date & Time",
    key: "date",
    format: "jsx",
    className: "",
    width: "15%",
  },
  {
    label: "Action",
    key: "actions",
    format: "jsx",
    className: "",
    width: "8%",
  },
];

export const ChatData = {
  message: "ok",
  data: [
    {
      id: "537535f7",
      name: "Prateek Singh",
      email: "prateek.singh@Satschel.Com",
      date: "2024-08-06T13:43:19.938Z",
      phone: "(+1) 525 623 5235",
      message1: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      message2: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      isAccountLocked: true
    },
    {
      id: "537535f7",
      name: "Austin Trombley",
      email: "prateek.singh@Satschel.Com",
      date: "2024-08-06T13:43:19.938Z",
      phone: "(+1) 525 623 5235",
      message1: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      message2: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      isAccountLocked: false
   
    },
    {
      id: "537535f7",
      name: "Shubham shukla",
      email: "prateek.singh@Satschel.Com",
      date: "2024-08-06T13:43:19.938Z",
      phone: "(+1) 525 623 5235",
      message1: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      message2: "JS: I wanted to ask you something, Can I ask? I am sending you some document.",
      isAccountLocked: true
    },
  ],
};