export const ACCOUNT_TABLE_HEADER = [
  {
    label: "Name",
    key: "company",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Email & Contact",
    key: "email",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Type",
    key: "type",
    format: "jsx",
    width: "13%",
  },
  {
    label: "Onboarding Status",
    key: "OnboardingStatus",
    format: "jsx",
    width: "32%",
  },
  {
    label: "Status",
    key: "status",
    format: "jsx",
    width: "10%",
  },
  {
    label: "Actions",
    key: "actions",
    format: "jsx",
    width: "10%",
  },
];


export const DVP_TABLE_HEADER = [
  {
    label: "Name",
    key: "name",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Email & Contact",
    key: "email",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Agreement Document",
    key: "agreement",
    format: "jsx",
    className: "",
    width: "18%",
  },
  {
    label: "Actions",
    key: "action",
    format: "jsx",
    className: "",
    width: "18%",
  },
];


export const AccountData: any = {
  message: "ok",
  data: [
    {
      id: "537535f7",
      name: "Deloitte",
      location: "USA, New york",
      userName: "It Satschel",
      email: "It@Satschel.Com",
      type: "Entity",
      assetIcon:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxEOPKRpwyIA1cS-sLHZdWhSA__mszbE7D6A&usqp=CAU",
      onboardingStatus: {
        kyc: "completed",
      },
      status: "approved",
    },
    {
      id: "537535f7",
      name: "Austin",
      location: "USA, New york",
      userName: "Austin",
      email: "It@Satschel.Com",
      type: "Individual",
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/SBA7-1697826018000.image/png",
      onboardingStatus: {
        kyc: "completed",
        aml: "rejected",
        accreditation: "processing",
      },
      status: "rejected",
    },
    {
      id: "537535f7",
      name: "Austin",
      location: "USA, New york",
      userName: "Austin",
      email: "It@Satschel.Com",
      type: "Individual",
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/SBA7-1697826018000.image/png",
      onboardingStatus: {
        kyc: "rejected",
        aml: "processing",
        accreditation: "success",
      },
      status: "pending",
    },
  ],
};

export const USER_TABLE_HEADER = [
  {
    label: "Name",
    key: "name",
    format: "string",
    className: "",
    width: "15%",
  },
  {
    label: "Email & Contact",
    key: "email",
    format: "jsx",
    className: "",
    width: "20%",
  },
  {
    label: "Role",
    key: "role",
    format: "string",
    width: "10%",
  },
  {
    label: "Onboarding Status",
    key: "OnboardingStatus",
    format: "jsx",
    width: "31%",
  },
  {
    label: "Date & Time",
    key: "dateTime",
    format: "jsx",
    width: "12%",
  },
  {
    label: "Actions",
    key: "actions",
    format: "jsx",
    width: "30%",
  },
];

export const userData = {
  message: "ok",
  data: [
    {
      id: "537535f7",
      name: "Austin Trombley",
      location: "USA, New york",
      email: "It@Satschel.Com",
      mobile: "(+91) 700 753 7258",
      role: "Co-owner",
      assetIcon:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxEOPKRpwyIA1cS-sLHZdWhSA__mszbE7D6A&usqp=CAU",
      onboardingStatus: {
        kyc: "completed",
      },
      date: "Mar 16, 2023",
      time: "02:58 PM",
    },
    {
      id: "537535f7",
      name: "Shubham shukla",
      location: "USA, New york",
      email: "It@Satschel.Com",
      mobile: "(+91) 700 753 7258",
      role: "Authorized user",
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/SBA7-1697826018000.image/png",
      onboardingStatus: {
        kyc: "completed",
        aml: "rejected",
        accreditation: "processing",
      },
      date: "Mar 16, 2023",
      time: "02:58 PM",
    },
  ],
};


export const TableUserData = {
  message: "ok",
  data: [
    {
      id: "537535f7",
      name: "Prateek Singh",
      location: "USA, New york",
      email: "prateek.singh@Satschel.Com",
      mobile: "+917007537258",
    },
    {
      id: "537535f7",
      name: "Austin Trombley",
      location: "USA, New york",
      email: "austin@Satschel.Com",
      mobile: "+17007537258",
    },
    {
      id: "537535f7",
      name: "Shubham shukla",
      location: "USA, New york",
      email: "subham@Satschel.Com",
      mobile: "+917007537258",
    },
  ],
};
export const actionKeyLabel: any = {
  kyc: "KYC",
  payIn: "Fund (Pay In)",
  payOut: "Fund (Payout)",
  accreditation: "Accreditation",
  "506c": "Accreditation(506c)",
  "506b": "Accreditation(506b)",
  aml: "AML",
  signAgreement: "Sign Doc.",
  authentication: "Auth",
  form: "Form",
  kyb: "KYB",
  kybForm: "form",
  dynamicForm: "form",
  "kyc(with liveness)": "KYC",
  simpliciSignAgreement: "Sign Doc.",
  docusignSignAgreement: "Sign Doc.",
  signAgreement_esign: "Sign Doc.",
  signAgreement_docusign: "Sign Doc.",
};
