import type { IReactResponsiveTable } from "./type";

import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  Button,
  Input,
  SelectedDateRangeState,
  DatePicker,
  SkeletonTable,
} from "@storybook";

import { getDate, getDateWithTime, useFormatNumber } from "utils";
import { TableEmptyMessage } from "./components";

import "./react-responsive-table.scss";

interface ISorting {
  isSort: boolean;
  sortKey: string;
}

export const ReactResponsiveTable: FC<IReactResponsiveTable> = ({
  column,
  rows = [],
  height,
  error,
  isLoading = false,
  showDateFilter = false,
  showFilter = false,
  showSearch = false,
  className = "",
  columnHandle = false,
  handelRowClick,
  numberOfList = 6,
}) => {
  const [searchedText, setSearchedText] = useState<string>("");
  const [tableRowsData, setTableRows] = useState<any[]>(rows);

  const [selectedDate] = useState<any>({});
  const [sortBy, setSortBy] = useState<ISorting>({
    isSort: false,
    sortKey: "",
  });

  const selectedDateRange = useRecoilValue(SelectedDateRangeState);
  const resetSelectedDateRange = useResetRecoilState(SelectedDateRangeState);

  useEffect(() => {
    resetSelectedDateRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  const formatDate = (date: any) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const checkSearch = useCallback(
    (name: string | number | undefined) => {
      return name
        ?.toString()
        ?.toLowerCase()
        ?.includes?.(searchedText.toLowerCase());
    },
    [searchedText]
  );

  const tableData = useMemo(
    () =>
      tableRowsData?.filter((item) => {
        if (searchedText.length > 0) {
          if (
            checkSearch(item?.company_name) ||
            checkSearch(item?.name) ||
            checkSearch(item?.company_address?.address1) ||
            checkSearch(item?.company_address?.address2) ||
            checkSearch(item?.company_address?.city) ||
            checkSearch(item?.company_address?.state) ||
            checkSearch(item?.user_email) ||
            checkSearch(item?.user_name) ||
            checkSearch(item?.currentStatus) ||
            checkSearch(
              new Date(item?.createdAt).toLocaleString("default", {
                month: "long",
              })
            ) ||
            checkSearch(new Date(item?.createdAt).getFullYear())
          ) {
            return true;
          }
          return false;
        }
        const date = new Date(formatDate(item.createdAt)).getTime();
        if (date >= selectedDate.fromDates && date <= selectedDate.endDates) {
          return item;
        }
        return <></>;
      }),
    [
      checkSearch,
      tableRowsData,
      searchedText.length,
      selectedDate.endDates,
      selectedDate.fromDates,
    ]
  );

  const { numberDecimal } = useFormatNumber();

  const { isSort, sortKey } = useMemo(() => sortBy, [sortBy]);

  const tableRows = useMemo(
    () =>
      tableData
        .filter((row) => {
          const isMatched = column.find(({ key, format }) => {
            let value = row[key];
            switch (format) {
              case "date":
                value = getDate(value);
                break;
              case "dateTime":
                value = getDateWithTime(value);
                break;
              default:
                break;
            }
            if (format !== "jsx") {
              return (
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes?.(searchedText.toLowerCase()) === true
              );
            }
            return <></>;
          });
          return !!isMatched;
        })
        .sort((a, b) => {
          if (isSort) {
            return a[sortKey].toString().toLowerCase() >
              b[sortKey].toString().toLowerCase()
              ? 1
              : -1;
          }
          return a[sortKey]?.toString().toLowerCase() >
            b[sortKey]?.toString().toLowerCase()
            ? -1
            : 1;
        }),
    [tableData, column, searchedText, isSort, sortKey]
  );

  const handleSort = useCallback(
    (key: string, format: string) => {
      if (format !== "jsx") {
        setSortBy({
          isSort: !isSort,
          sortKey: key,
        });
      }
    },
    [isSort]
  );

  const renderColumn = useMemo(
    () =>
      column.map(({ label, key, width, format }, index) => (
        <th
          className="table__head-data"
          key={`${index}-${key}`} // eslint-disable-line
          style={{ width: width }}
        >
          <div
            className="table__head-data--label"
            onClick={() => handleSort(key, format)}
          >
            <span>{label} </span>
            {/* {format !== "jsx" && (
              <div className={`table__head-data--label__${isSort}`}>
                <i className="ri-arrow-down-s-fill" />
              </div>
            )} */}
          </div>
        </th>
      )),
    [column, handleSort]
  );
  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchedText(value);
    },
    [setSearchedText]
  );
  const renderRows = useMemo(
    () =>
      tableRows.map((item, index) => {
        return (
          <tr
            key={`${index}-item`} // eslint-disable-line
            onClick={(e: any) =>
              handelRowClick ? handelRowClick(item, e) : {}
            }
            className={`${columnHandle ? "row__hover" : ""}`}
          >
            {column.map(({ key, format, width, className }, index) => {
              let value = item[key];
              if (value) {
                switch (format) {
                  case "number":
                    break;
                  case "date":
                    value = getDate(value);
                    break;
                  case "dateTime":
                    value = getDateWithTime(value);
                    break;
                  case "jsx":
                    value = value();
                    break;
                  case "price":
                    value = `$${numberDecimal(Number(value))}`;
                    break;
                  case "percentage":
                    value = `${value}%`;
                    break;
                  default:
                    break;
                }
              }
              return (
                <td
                  key={`${index}-${key}`} // eslint-disable-line
                  className={`${className ? className : ""}`}
                  style={{ width: width }}
                >
                  <div>{value ?? "-"}</div>
                </td>
              );
            })}
          </tr>
        );
      }),

    [column, columnHandle, handelRowClick, numberDecimal, tableRows]
  );

  const handleClearField = useCallback(() => {
    setSearchedText("");
  }, [setSearchedText]);

  const handleOnClickDateFilter = useCallback(() => {
    const { endDate, startDate } = selectedDateRange[0] || {};
    const fromDates = new Date(formatDate(startDate)).getTime();
    const endDates = new Date(formatDate(endDate)).getTime();
    const filteredArr = rows?.filter((item) => {
      const date = new Date(formatDate(item.createdAt)).getTime();
      if (date >= fromDates && date <= endDates) {
        return item;
      }
      return [];
    });
    setTableRows(filteredArr);
  }, [selectedDateRange, rows]);

  useEffect(() => {
    if (showDateFilter) handleOnClickDateFilter();
  }, [handleOnClickDateFilter, rows, showDateFilter]);

  return (
    <div className="react-table-responsive-container">
      {(showSearch || showFilter) && (
        <div className="table-responsive-actions__btns">
          {showSearch && (
            <Input
              inputType="text"
              handleChange={handleSearch}
              placeholder="Search"
              allowClear={false}
              handleClearField={() => handleClearField}
              prefixIcon="ri-search-line"
              value={searchedText}
              label={""}
            />
          )}

          {showDateFilter && <DatePicker onSubmit={handleOnClickDateFilter} />}
          {showFilter && (
            <Button
              // handleClick={() => {}}
              className="btn btn__outline btn__outline--secondary btn__small btn__iconFront btn--disabled"
              icon="ri-filter-3-line"
              label="Filters"
              // disabled
            />
          )}
        </div>
      )}
      <div
        className="react-table-responsive-wrapper"
        style={{ maxHeight: height }}
      >
        <table className={`react-table-responsive ${className}`}>
          <thead className="react-table-responsive__head">
            <tr className="react-table-responsive__head-row">{renderColumn}</tr>
          </thead>

          {!isLoading && !!tableData.length && !!renderRows.length && (
            <tbody className="react-table-responsive__body">{renderRows}</tbody>
          )}
        </table>
        {isLoading ? (
          <div className="skeleton-loader-container">
            <SkeletonTable
              listsToRender={numberOfList}
              numberColumn={column.length}
              column={column}
            />
            {/* <Loader className="loader-blue" dimension={52} /> */}
          </div>
        ) : (
          (!rows.length || error || !tableRows.length) && (
            <TableEmptyMessage height={"calc(100vh - 400px)"} />
          )
        )}
      </div>
    </div>
  );
};
