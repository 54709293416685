// /* eslint-disable react/react-in-jsx-scope */
import { IDropdown } from "./type";

import { useState, FC } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import styles from "./Dropdown.module.sass";

export const SelectDropdown: FC<IDropdown> = ({
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  positionDropDown = "bottom",
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value: string | number) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active as any]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{value}</div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            {/* <Icon name="arrow-down" size="24" /> */}
            <i className="ri-arrow-down-s-line" />
          </div>
        </div>
        <div
          className={cn(classDropdownBody, styles.body, {
            [styles.bodyTop as any]: positionDropDown === "top",
          })}
        >
          {(options as any).map((option: string | number, index: number) => (
            <div
              className={cn(classDropdownOption, styles.option, {
                [styles.selectioned as any]: option === value,
              })}
              onClick={() => handleClick(option)}
              key={index}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
