export const AUCTION_TABLE_HEADER = [
  {
    label: "Auction Name ",
    key: "auctionName",
    format: "string",
    className: "",
    width: "13%",
  },
  {
    label: "Created By",
    key: "createdBy",
    format: "string",
    className: "",
    width: "12%",
  },
  {
    label: "Start Price",
    key: "startPrice",
    format: "string",
    width: "12%",
  },
  {
    label: "Start Date & Time",
    key: "startDateAndTime",
    format: "jsx",
    width: "14%",
  },
  {
    label: "End Date & Time",
    key: "endTime",
    format: "jsx",
    width: "14%",
  },
  {
    label: "Supporting Documents",
    key: "supportingDoc",
    format: "jsx",
    width: "16%",
  },
  {
    label: "Actions",
    key: "actions",
    format: "jsx",
    width: "20%",
  },
];

// AUCITON NAME -STRING
// CREATED BY - STRING
// START PRICE - STRING
// START DATE AND TIME - STRING
// AUCTION ENDS IN  -- JSX
// SUPPORTING DOCUMENTS -- JSX
// ACTIONS ---JSX
