import { Button, Input, ReactDropdown } from "@storybook";
import "./lockin-period.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { convertToDays } from "utils";

const option = [
  { label: "No Lockup", value: "0 day" },
  { label: "1 week", value: "1 week" },
  { label: "2 weeks", value: "2 week" },
  { label: "1 month", value: "1 month" },
  { label: "3 months", value: "3 month" },
  { label: "6 months", value: "6 month" },
  { label: "12 months", value: "12 month" },
  { label: "Custom", value: "custom" },
];

const option2 = [
  { label: "Day", value: "days" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

const daysMap = ["0 day", "1 week", "2 week", "1 month", "3 month", "6 month", "12 month"];

export const LockinPeriod: FC<any> = ({
  selectedAsset,
  closeLoackInModal,
  closeLoackInModalNoSave,
}) => {
  const [lockInValue, setLockInValue] = useState<any>({
    label: "No Lockup",
    value: "0 day",
  });
  const [customValue, setCustomValue] = useState<any>("1");
  const [customDropDownValue, setCustomDropDownValue] = useState<any>({
    label: "Day",
    value: "day",
  });
  const [isUserInpurDataNotValid, setIsUserInpurDataNotValid] = useState(false);
  const [trandeSelected, setTradeSelected] = useState("primary");
  const [primaryFee, setPrimaryFee] = useState<any>({ sell: "", buy: "" });
  const [secondaryFee, setSecondaryFee] = useState<any>({
    sell: "",
    buy: "",
  });
  const [primaryError, setPrimaryError] = useState(false);
  const [secondaryError, setSecondaryError] = useState(false);
 
  const { errorNotification, successNotification } = useNotification();
  const { put: setLockIn, isLoaded } = useNetwork();

  useEffect(() => {
    const { lockupPeriod, primaryTrade, secondaryTrade } = selectedAsset;
    setPrimaryFee(primaryTrade);
    setSecondaryFee(secondaryTrade)
    
    if (Object?.keys(lockupPeriod)?.length === 0) {
      setLockInValue({ label: "No Lockup", value: "0 day" });
      return;
    }

   const { duration, unit } = lockupPeriod;
    const mergedDay = duration + " " + unit
    const value = daysMap.indexOf(mergedDay) > -1;

    if (value) {
      const filteredObject = option?.filter(
        (item: any) => item.value === mergedDay
      );
      setLockInValue({
        label: filteredObject[0]?.label,
        value: filteredObject[0]?.value,
      });
    } else {
      setLockInValue({ label: "Custom", value: "custom" });
      setCustomValue(duration);
      setCustomDropDownValue({
        label: unit.charAt(0).toUpperCase() + unit.slice(1),
        value: unit,
      });
    }

    return () => {
      setLockInValue({ label: "No Lock In", value: 0 });
      setCustomValue(null);
      setCustomDropDownValue({ label: "Day", value: "day" });
    };
  }, []);

  const getPayload = useCallback(() => {
    if (lockInValue.value !== "custom") {
        const value = lockInValue?.value?.split(' ');
        return { duration: value[0], unit: value[1] };
     } else {
      return { duration: Number(customValue), unit: customDropDownValue.value };
    }

  }, [lockInValue, customValue, customDropDownValue]);

  const checkValidDate = () => {
    return (
      1865 >=
      convertToDays({
        duration: Number(customValue),
        unit: customDropDownValue?.value,
      })
    );
  };

  const validateForm = () => {
    let isDateValid = true;
    let isvalidPrimaryValue = true;
    let isValidSecondaryValue = true;
    if (lockInValue.value === "custom") {
      isDateValid = checkValidDate();
    }

    if(!isDateValid){
      setIsUserInpurDataNotValid(true);
    }

    if (Number(primaryFee?.sell) > 100) {
      setPrimaryError(true);
      isvalidPrimaryValue = false;
    }

    if (Number(secondaryFee?.sell) > 100) {
      setSecondaryError(true);
      isValidSecondaryValue = false;
    }

    // return to tab 1 to show error if tab 2 has no error
    if(trandeSelected !== 'primary' && isValidSecondaryValue && (!isDateValid || !isvalidPrimaryValue)) {
      setTradeSelected('primary')
    }
    else if(trandeSelected === 'primary' && (isDateValid && isvalidPrimaryValue) && !isValidSecondaryValue) {
      setTradeSelected('secondary')
    }

    return isDateValid && isvalidPrimaryValue && isValidSecondaryValue;
  };

  const saveLockInPeriod = () => {
    const isFormValid: boolean = validateForm();
    
    if (isFormValid) {
      setIsUserInpurDataNotValid(false);
      setPrimaryError(false);
      setSecondaryError(false);
      setLockIn(`${API_URL.ASSETS}/${selectedAsset?.id}`, {
        primaryTrade: {
          sell: Number(primaryFee?.sell),
        },
        secondaryTrade: {
          sell: Number(secondaryFee?.sell),
        },
        lockupPeriod: getPayload(),
      }).then((res: any) => {
        if (res?.apiData?.message === "ok") {
          // do something
          closeLoackInModal();
          successNotification("Lockup Period set successfully");
        } else {
          // show error
          errorNotification("There was an unexpected error");
        }
      });
    } else {
    }
  };

  const setCustomValueInput = (e: any) => {
    const { value } = e.target;
    const regex = /^\d{0,4}$/;
    let result = regex.test(value);
    if (!result) return;
    setCustomValue(value);
  };

  const setTradingFee = (e: any) => {
    const { value, name } = e.target;
    const regex = /^[0-9]{0,3}(?:[.][0-9]{0,2})?$/;
    let result = regex.test(value);
    if (!result) return;

    if (trandeSelected === "primary") {
      setPrimaryFee((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setSecondaryFee((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
 
  return (
    <div className="lockin-modal">
      <div className="lockin-modal-header">
        <h2>Trading Fee & Lockup Period</h2>
        <p>Configure trading fees and Lockup requirements for this asset.</p>
      </div>
      <div className="lockin-modal-tabs">
        <span
          onClick={() => setTradeSelected("primary")}
          className={trandeSelected === "primary" ? "active" : ""}
        >
          Primary Trade
        </span>
        <span
          onClick={() => setTradeSelected("secondary")}
          className={trandeSelected !== "primary" ? "active" : ""}
        >
          Secondary Trade
        </span>
      </div>
      <div className="lockin-modal-body">
        <div className="details">
          <h3>{selectedAsset?.name}</h3>
          <p className="asset-type">
            {selectedAsset?.issuerDetails?.issuerName}.{selectedAsset?.symbol}
          </p>
        </div>
        <div className="details">
          <h3>Trading Fee</h3>
          <p>
            Set a percentage fee that will be applied to each trade for buyers
            and sellers. This fee is charged to cover transaction costs and
            platform maintenance.
          </p>
          <div className="input-group m-t-10">
            <div className="input-custom">
              {/* <Input
                inputType="tel"
                label="Buyer Fee"
                handleChange={setTradingFee}
                inputName="buyer"
                value={trandeSelected === 'primary' ? primaryFee?.buyer : secondaryFee?.buyer}
                placeholder="0"
                suffixIcon="ri-percent-line"
                isError={isUserInpurDataNotValid}
                errorMessage="The lock-in period cannot be more than 5 years"
              /> */}
              <Input
                inputType="tel"
                label="Seller Fee"
                inputName="sell"
                disabled={!isLoaded}
                handleChange={setTradingFee}
                value={
                  trandeSelected === "primary"
                    ? primaryFee?.sell
                    : secondaryFee?.sell
                }
                placeholder="0"
                suffixIcon="ri-percent-line"
                isError={
                  trandeSelected === "primary" ? primaryError : secondaryError
                }
                errorMessage="Value cannot exceed 100"
              />
            </div>
          </div>
        </div>
        {trandeSelected === "primary" && (
          <div className="details">
            <h3>Lockup Period</h3>
            <p>
              Define the minimum holding period required before an asset becomes
              eligible for trading again. This ensure asset stability and
              long-term investment.
            </p>
            <h4>Lockup Period</h4>
            <div className="input-group">
              <ReactDropdown
                defaultValue={lockInValue}
                options={option}
                isSearchable={false}
                value={lockInValue}
                handleChangeSelect={(value) => setLockInValue(value)}
              />
              {lockInValue.value === "custom" && (
                <div className="input-custom">
                  <Input
                    inputType="tel"
                    label=""
                    disabled={!isLoaded}
                    handleChange={setCustomValueInput}
                    value={customValue}
                    placeholder=""
                    isError={isUserInpurDataNotValid}
                    errorMessage="The lockup period cannot be more than 5 years"
                  />
                  <ReactDropdown
                    options={option2}
                    value={customDropDownValue}
                    isSearchable={false}
                    handleChangeSelect={(value) =>
                      setCustomDropDownValue(value)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="lockin-modal-footer">
        <Button
          label={"Cancel"}
          className="button__filled--secondary"
          handleClick={closeLoackInModalNoSave}
        />
        <Button
          label={"Save"}
          loader={isLoaded}
          disabled={
            !isLoaded || (lockInValue.value === "custom" && !customValue)
          }
          className="button__filled--primary"
          handleClick={saveLockInPeriod}
        />
      </div>
    </div>
  );
};
