export const DUMMY_LOGIN_CODE = [
  "satschel",
  "Satschel",
  "28c6b723-d43f-4904-b403-ca8eb8c5fbb8",
];

export const STEP_LABELS = {
  CREDS: "Next",
  VERIFY: "Submit",
};

export const REGEX_ACCEPT_ONLY_NUMBER = /^[0-9]+$/;

export const loginToasterMessages = {
  SENT_OTP: "OTP send successfully to mobile number ",
  LOGIN_SUCCESS: "Login successfully",
  PHONE_NUMBER_VALIDATION: "Mobile Number length must be greater than 8",
  INVALID_OTP: "Invalid OTP.",
  ACCEPTED_NUMBER_ONLY: "Only number allowed in OTP input",
};
