import { useState, useCallback, useEffect, useMemo } from "react";
import cn from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useNetwork, useNotification } from "hooks";
import { loginFormState } from "views";
import { Loader } from "@storybook";
import { REGEX_ACCEPT_ONLY_NUMBER, loginToasterMessages } from "../../store/constants";
import {
    LoginRoutesState, userLoggedInDetails,
} from "./../../store/state"
import styles from "./otp-verify.module.sass";
import { API_URL } from "constant";
export interface IOtpObject {
    otp1: string;
    otp2: string;
    otp3: string;
    otp4: string;
}

export const OtpVerify = () => {
    const loginEmail = useRecoilValue(loginFormState);
    const userAuthDetails = useRecoilValue(userLoggedInDetails);
    const { post: sendOtp, data: resData, apiResponse: otpSentRes } = useNetwork();

    const [otpValue, setOtpValue] = useState<IOtpObject>({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
    });
    const [focusEvent, setFocusEvent] = useState<any>({});
    const [email] = useState<string>(loginEmail.email);

    const {
        patch: verifyPhone,
        data: verifyOTP,
        apiResponse
        // loading: verifyOtpLoading,
    } = useNetwork();
    const { successNotification, errorNotification } = useNotification();

    const seLoginRoutes = useSetRecoilState(LoginRoutesState);
    const { INVALID_OTP, ACCEPTED_NUMBER_ONLY } = loginToasterMessages;
    const setLoginRoute = useSetRecoilState(LoginRoutesState);

    // const goBackLoginPage = useCallback(() => {
    //     setShowOtpPage(false);
    //     seLoginRoutes("chooseMethod");
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleSubmitOtp = useCallback(() => {
        const { otp1, otp2, otp3, otp4 } = otpValue;
        const sentOtp = otp1 + otp2 + otp3 + otp4;
        const payload: any = {
            otp: sentOtp,
        };
        verifyPhone(`${API_URL.EmailCodes}/${userAuthDetails?._id}`, payload);

    }, [otpValue, verifyPhone, userAuthDetails?._id]);

    let AuthType = ""

    useEffect(() => {
        if (verifyOTP) {
            const { status } = apiResponse;
            if (status === 200) {
                const { message } = verifyOTP?.data;
                setLoginRoute("chooseMethod");
                successNotification(message);
            } else {
                errorNotification(INVALID_OTP);
                setOtpValue({ otp1: "", otp2: "", otp3: "", otp4: "" });
                focusEvent?.target?.form?.elements[0]?.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, verifyOTP, AuthType]);

    const onHandleOtp = useCallback((name: string, e: any) => {
        e.preventDefault();
        const { value } = e.target;
        if (value.length > 1) return;
        if (REGEX_ACCEPT_ONLY_NUMBER.test(e.target.value)) {
            setOtpValue((prev: IOtpObject) => ({
                ...prev,
                [name]: value,
            }));
        } else {
            setOtpValue((prev: IOtpObject) => ({
                ...prev,
                [name]: "",
            }));
        }
    }, []);

    const handleBack = useCallback(() => {
        seLoginRoutes("email");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toasterMessage = useCallback(() => {
        if (otpSentRes?.status === 200) {
            successNotification(resData?.data?.message)
        } else {
            errorNotification(resData?.data?.message);
        }
    }, [errorNotification, otpSentRes, resData?.data?.message, successNotification])

    const handleResendOTP = useCallback(() => {
        sendOtp(API_URL.EmailCodes, loginEmail);
        toasterMessage();
    }, [loginEmail, sendOtp, toasterMessage]);

    useEffect(() => {
        sendOtp(API_URL.EmailCodes, loginEmail);
        successNotification("OTP sent successfully")
    }, [])

    const inputFocus = useCallback(
        (event: any) => {
            if (REGEX_ACCEPT_ONLY_NUMBER.test(event.target.value)) {
                setFocusEvent(event);
                const next = event.target.tabIndex;
                if (next < 4) {
                    event.target.form.elements[next].focus();
                }
            } else if (event.key === "Delete" || event.key === "Backspace") {
                const next = event.target.tabIndex - 2;
                if (next > -1) {
                    event.target.form.elements[next].focus();
                }
            }
        },
        [ACCEPTED_NUMBER_ONLY]
    );

    const handlePaste = useCallback((e: any) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('Text');
        const pastedValues = pasteData.split('').filter((char: any) => REGEX_ACCEPT_ONLY_NUMBER.test(char)).slice(0, 4);
        const updatedOtpValue: any = {};
        Object.keys(otpValue).forEach((key, index) => {
            updatedOtpValue[key] = pastedValues[index] || '';
        });
        setOtpValue(updatedOtpValue);
    }, [otpValue]);

    const otpInput = useMemo(() => {
        return Object.entries(otpValue).map(([key, value], index) => (
            <input
                type="text"
                key={index}
                className={styles.otpInput}
                name={key}
                value={value}
                onChange={(e) => onHandleOtp(key, e)}
                onKeyUp={(e) => inputFocus(e)}
                maxLength={1}
                minLength={1}
                tabIndex={index + 1}
                onFocus={(e) => e.target.select}
                autoFocus={index === 0}
                onPaste={handlePaste}
            />
        ));
    }, [inputFocus, onHandleOtp, otpValue]);

    const { otp1, otp2, otp3, otp4 } = otpValue;
    const submitBtn = !(otp1 && otp2 && otp3 && otp4);

    return (
        // otp verify screen
        <form className={styles.form}>
            <div className={styles.otp}>
                <div className={styles.otp_detail}>OTP verification</div>
                <div className={styles.edit}>
                    We will send you a verification code :

                    <div className={styles.email}>
                        {`${email} `}

                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.line}>{otpInput}</div>
                </div>
                <div className={styles.verify_button}>
                    <button
                        className={cn("buttons", styles.buttons)}
                        type="button"
                        onClick={handleSubmitOtp}
                        disabled={submitBtn}
                    >
                        {/* {"verifyOtpLoading" ? <Loader dimension={22} /> : "Verify"} */}
                        {false ? <Loader dimension={22} /> : "Next"}

                    </button>
                </div>
                {AuthType === "login" && (
                    <div className={styles.verify_button}>
                        <button
                            className={cn("buttons", styles.back_button)}
                            type="button"
                            style={{ backgroundColor: "#999" }}
                            onClick={handleBack}
                        >
                            Back
                        </button>
                    </div>
                )}
                <div className={styles.resend}>
                    {`Not received the code yet? `}
                    <span className={styles.editBtn} onClick={handleResendOTP}>
                        Resend
                    </span>
                </div>
            </div>
        </form>
    );
};
