import { FC } from 'react';
import { IButton } from '.';

import './button.scss';

export const Button: FC<IButton> = ({ 
  label,
  handleClick,
  loader,
  className,
  icon,
  height,
  disabled,
  buttonType,
  prefixIcon,
  minHeight,
  width,
}) => {
  return (
    <button
      className={`button ${className} ${disabled ? 'button--disabled' : ''}`}
      onClick={handleClick}
      style={{ minHeight, height, width }}
      disabled={disabled}
      type={buttonType}
    >
      {prefixIcon && <i className={prefixIcon} />}
      <div className="button-label-txt">{label}</div>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
