import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { UserListModal } from "./components/userListModal";
import { AccountList } from "./components/accountList";
import { useAccountApis } from "./store/hooks";
import { SessionDetailsModal } from "./components/sessionDetailsModal";
import { AccountDataState, FilterState } from "./store/state";
import { Pagination } from "@storybook";
import { Json } from "types";

import "./accounts.scss";

export const Accounts = () => {
  const [activeSessionId, setActiveSessionId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [activeAccountSession, setActiveAccountSession] = useState("");
  const [loadingChild, setLoadingChild] = useState(false);

  const [filters, setFilters] = useRecoilState(FilterState);
  const [AccountListData, setAccountListData] = useRecoilState(AccountDataState);

  const { getSimpliciAccessToken } = useAccountApis();

  const handleClick = (id: string): any => {
    setActiveSessionId(id);
  };

  const handleClickSession = (id: string): any => {
    setActiveAccountSession(id);
    setAccessToken("");
    getSimpliciAccessToken({sessionId: id}).then((response: any) => {
      if (response) {
        setAccessToken(response);
      }
    });
  }

   const handleFilter = (filterObj: Json, debounce: boolean = true) => {
     setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
   };
   
    const handlePagination = useCallback((val: number, action: string) => {
      let pageChange: Json = {};
      if (action === "perPage") {
        pageChange.limit = val;
        pageChange.offset = 0;
      }
      if (action === "page") pageChange.offset = val;
      handleFilter(pageChange);
    }, []);
    
    const handleDataFromChild = (data:boolean) => {
      setLoadingChild(data);
    }

    const {
      getAuctionList,
      data: auctionData,
      loading: accountListLoading,
    } = useAccountApis();

    useEffect(() => {
      setAccountListData(auctionData);
    }, [auctionData]);

  return (
    <>
      <AccountList
        handleClick={(id: string) => handleClick(id)}
        accessToken={accessToken}
        handleSessionClick={handleClickSession}
        sendDataToParent={handleDataFromChild}
        isLoading={accountListLoading}
      />
      {!!activeSessionId && (
        <UserListModal
          visible={!!activeSessionId}
          setModalVisible={() => setActiveSessionId("")}
          sessionId={activeSessionId}
          handleSessionClick={(id: string) => {
            setActiveAccountSession(id);
          }}
        />
      )}

      {!!activeAccountSession && (
        <SessionDetailsModal
          visible={!!activeAccountSession}
          sessionId={activeAccountSession}
          accessToken={accessToken}
          setModalVisible={() => {
            const { immediate, ..._filters } = filters;
            getAuctionList({
              ..._filters,
            });
            setActiveAccountSession("");
          }}
        />
      )}
      <Pagination
        showCount={true}
        listCount={AccountListData?.length}
        page={parseInt(filters.offset)}
        perPage={parseInt(filters.limit)}
        onChangePage={(page) => handlePagination(page, "page")}
        onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
        loading={loadingChild}
        perPageOptions={[10, 20]}
      />
    </>
  );
};
