import { ChatList } from "../chatList";
import { ChatScreen } from "../chatScreen";
import { ChatAssetList } from "../ChatAssetList";

import "./chat-details.scss";
import { useLocation, useNavigate } from "react-router-dom";

export const ChatDetails = () => {
  const {state} = useLocation();
  const navigation: any = useNavigate();

  return (
    <div className="chat_details-container">
      <div className="chat_details-container-header">
        <div className="chat-header">
          <span onClick={() => navigation(-1)} className="ri ri-arrow-left-line" ></span>
          <h3>{state?.user ?? ""}</h3>
        </div>
      </div>
      <div className="chat_details-container-body">
        <ChatAssetList />
        <ChatList />
        <ChatScreen />
      </div>
    </div>
  );
}