import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  addRoleState,
  createFormState,
  editRoleState,
  selectedRoleState,
} from "../store";
import { MODULE_ICON } from "./constant";
import { Button } from "@storybook";
import { useUserPermission } from "hooks/user-permission";

export const PermissionView = () => {
  const setVisibleAddRole = useSetRecoilState(addRoleState);
  const selectedRole = useRecoilValue(selectedRoleState);
  const setEditRole = useSetRecoilState(editRoleState);
  const setCreateForm = useSetRecoilState(createFormState);
  const buttonDisable = useUserPermission();
  const [collapseRoute, setCollapseRoute] = useState<any>(new Set([]));

  const handleToggle = (key: string) => {
    if (collapseRoute.has(key)) {
      collapseRoute.delete(key);
    } else {
      collapseRoute.add(key);
    }

    setCollapseRoute(new Set([...collapseRoute]));
  };

  const handelEdit = useCallback(() => {
    const modifiedForm = {
      name: selectedRole.name,
      description: selectedRole.description,
      permissions: [],
    };
    const selectedPermission = selectedRole?.permissions;
    for (let i = 0; i < selectedPermission?.length; i++) {
      const subModuleModify = [];
      const selectedPermissionMdule = selectedPermission[i]?.module;
      for (let j = 0; j < selectedPermissionMdule?.subModules?.length; j++) {
        const subModuleObj = {
          create:
            selectedPermissionMdule?.subModules[j]?.create ??
            selectedPermission[i]?.create,
          view:
            selectedPermissionMdule?.subModules[j]?.view ??
            selectedPermission[i]?.view,
          edit:
            selectedPermissionMdule?.subModules[j]?.edit ??
            selectedPermission[i]?.edit,
          subModuleId: selectedPermissionMdule?.subModules[j]?._id,
        };
        subModuleModify.push(subModuleObj);
      }
      const permissionModify = {
        create: selectedPermission[i]?.create,
        view: selectedPermission[i]?.view,
        edit: selectedPermission[i]?.edit,
        module: selectedPermission[i]?.module?._id,
        subModules: subModuleModify,
      };
      (modifiedForm?.permissions as any)?.push(permissionModify);
    }
    setCreateForm(modifiedForm);
    setEditRole(true);
    setVisibleAddRole(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  return (
    <>
      <div className="permissions">
        <h3>
          {selectedRole.name}
          <div className="right-header">
            <Button
              label={"Edit"}
              className={"button__outline"}
              handleClick={handelEdit}
              disabled={buttonDisable}
            />
            <i className="ri-more-2-line"></i>
          </div>
        </h3>
        <p>{selectedRole.description}</p>
        <h4>Permission:</h4>
        <div className="permission-header permission-row">
          <div>Actions</div>
          <div>View only</div>
          <div>Create, edit & view</div>
        </div>
        {selectedRole.permissions?.map((role: any) => {
          const { module } = role;
          const { subModules } = module ?? [];
          return (
            <div>
              <div
                className={`permission-row permission--header ${
                  module?.subModules?.length > 0 && "permission-active"
                }`}
                onClick={() => handleToggle(module)}
              >
                <div>
                  {module?.subModules?.length > 0 ? (
                    <>
                      {collapseRoute.has(module) ? (
                        <i className="ri-arrow-up-s-fill dropdown-arrow"></i>
                      ) : (
                        <i className="ri-arrow-down-s-fill dropdown-arrow"></i>
                      )}
                    </>
                  ) : (
                    <i className="ri-empty"></i>
                  )}
                  <i className={`${MODULE_ICON[module?.name]} module_icon`}></i>
                  {module?.name}
                </div>

                <div>
                  {!role.create && !role.edit && role.view && (
                    <div className="checkmark"></div>
                  )}
                </div>
                <div>
                  {role.create && role.edit && role.view && (
                    <div className="checkmark"></div>
                  )}
                </div>
              </div>
              {subModules?.map((subModule: any) => {
                return (
                  <>
                    {collapseRoute.has(module) && (
                      <div className="permission-row">
                        <div>{subModule?.name}</div>
                        <div>
                          {!subModule?.create &&
                            !subModule?.edit &&
                            subModule?.view && (
                              <div className="checkmark"></div>
                            )}
                        </div>
                        <div>
                          {subModule?.create &&
                            subModule?.edit &&
                            subModule?.view && (
                              <div className="checkmark"></div>
                            )}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
