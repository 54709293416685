import { Json } from "types";

import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import { useNetwork } from "./network";
import { rolesListtate, rolesTemplatetate } from "views/roles/store";
import { API_URL } from "constant";
import { useNotification } from "./use-notification";

export const useRolesApi = () => {
  //   const user = { token: "test" };
  const setRoleList = useSetRecoilState(rolesListtate);
  const setTemplateList = useSetRecoilState(rolesTemplatetate);

  const { get: getRoles } = useNetwork();
  const { errorNotification } = useNotification();

  const fetchRoleList = useCallback(async (): Promise<void> => {
    // We will uncomment this when we are implementing token api
    // if (!user?.token) return;
    // setRoleList((prev: any) => ({
    //   ...prev,
    //   loading: true,
    //   data: {},
    // }));
    const resData: Json = { loading: false };
    try {
      const resposne = await getRoles(API_URL.ROLES);
      if (resposne?.apiData?.data) {
        resData.data = resposne?.apiData?.data ?? [];
      } else {
        resData.data = [];
        errorNotification(resposne?.apiData?.message);
      }
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setRoleList((prev: any) => ({ ...prev, ...resData }));
    }
  }, []);

  const fetchRoleTemplate = useCallback(async (): Promise<void> => {
    // We will uncomment this when we are implementing token api
    // if (!user?.token) return;
    // setRoleList((prev: any) => ({
    //   ...prev,
    //   loading: true,
    //   data: {},
    // }));
    const resData: Json = { loading: false };
    try {
      const resposne = await getRoles(`${API_URL.ROLES}?type=template`);
      resData.data = resposne?.apiData?.data ?? [];
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setTemplateList((prev: any) => ({ ...prev, ...resData }));
    }
  }, []);

  return { fetchRoleList, fetchRoleTemplate };
};
