export const MODULE_ICON: any = {
  Accounts: "ri-account-circle-line",
  Products: "ri-box-3-line",
  "API Dashboard": "ri-node-tree",
  "Activity Logs": "ri-list-check-2",
  "Revenue & Analytics": "ri-money-dollar-circle-line",
  Settings: "ri-settings-5-line",
  "Chats" : "ri-chat-4-fill",
  "DVP":"ri-file-copy-2-fill"
};
