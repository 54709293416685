export const ErrorIcons = () => {
    return (
        <div className="wrapper">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M40 80C17.908 80 0 62.092 0 40C0 17.908 17.908 0 40 0C62.092 0 80 17.908 80 40C80 62.092 62.092 80 40 80ZM40 34.344L28.688 23.028L23.028 28.688L34.344 40L23.028 51.312L28.688 56.972L40 45.656L51.312 56.972L56.972 51.312L45.656 40L56.972 28.688L51.312 23.028L40 34.344Z" fill="#EF4343" />
            </svg>

        </div>
    );
};
