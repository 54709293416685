import { API_URL } from "constant";
import { useNetwork, useNotification } from "hooks";
import { useState } from "react";
import { Json } from "types";

export const useMintApis = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState(false);

  const { patch: assetAction } = useNetwork();
  const { errorNotification } = useNotification();

  const mintAssetAction = async (id: string, payload: Json): Promise<void> => {
    setLoading(true);
    let response: any;
    try {
      response = await assetAction(`${API_URL.ASSETS}/${id}`, payload);
    } catch (e: any) {
      setError(true);
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setLoading(false);
      if (response?.response?.status === 200) {
        setData(response?.apiData?.data);
        return response?.apiData?.data;
      } else {
        errorNotification(response?.apiData?.message ?? "Something went wrong");
        setError(true);
      }
    }
  };

  return {
    mintAssetAction,
    data,
    loading,
    error,
  };
};
