export const SuccessIcon = () => {
	return (
		<div className="wrapper">
			<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M40 80C17.908 80 0 62.092 0 40C0 17.908 17.908 0 40 0C62.092 0 80 17.908 80 40C80 62.092 62.092 80 40 80ZM36.012 56L64.292 27.716L58.636 22.06L36.012 44.688L24.696 33.372L19.04 39.028L36.012 56Z" fill="#389F75" />
			</svg>

		</div>
	);
};
