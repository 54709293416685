import { useState } from "react";
import "./scrollable.scss"; // Import your stylesheet

export const ScrollableTable = () => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleCheckboxChange = (loanNumber: number) => {
    // Toggle the selected state for the corresponding loan number
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(loanNumber)) {
        return prevSelectedRows.filter((row) => row !== loanNumber);
      } else {
        return [...prevSelectedRows, loanNumber];
      }
    });
  };

  const tableData = [
    {
      loanNumber: 1,
      name: "John Doe",
      type: "Personal Loan",
      maturityDate: "2024-12-31",
    },
    {
      loanNumber: 1,
      name: "John Doe",
      type: "Personal Loan",
      maturityDate: "2024-12-31",
    },
    {
      loanNumber: 1,
      name: "John Doe",
      type: "Personal Loan",
      maturityDate: "2024-12-31",
    },
    {
      loanNumber: 1,
      name: "John Doe",
      type: "Personal Loan",
      maturityDate: "2024-12-31",
    },
    // Add more rows as needed
  ];

  return (
    <div className="table-container detail-table">
      <div className="table-header">
        <div>
          <input type="checkbox" />
        </div>
        <div>Loan number</div>
        <div>Name</div>
        <div>Type</div>
        <div>Maturity date</div>
      </div>
      <div className="table-body">
        {tableData.map((row) => (
          <div key={row.loanNumber} className="table-row">
            <div>
              <input
                type="checkbox"
                checked={selectedRows.includes(row.loanNumber)}
                onChange={() => handleCheckboxChange(row.loanNumber)}
              />
            </div>
            <div>{row.loanNumber}</div>
            <div>{row.name}</div>
            <div>{row.type}</div>
            <div>{row.maturityDate}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
