import { moduleNameAsPerRoute } from "layouts";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "views/roles/store";

export const useUserPermission = () => {
  const userDataAccess = useRecoilValue(userDetailsState);
  const [activeModule, setactiveModule] = useState("");

  const rolesData: any = userDataAccess?.data?.roles?.[0].role;
  const pathName = window.location.pathname;

  const permissions = rolesData?.permissions;

  useEffect(() => {
    const moduleName = moduleNameAsPerRoute[pathName];
    setactiveModule(moduleName);
  }, [pathName]);

  const flattenModules = useMemo(() => {
    const flattenedModules: any = [];

    const flatten = (module: any) => {
      const { name, subModules, view, edit, create } = module;
      const moduleName = name;
      flattenedModules.push({ moduleName, view, edit, create });

      if (subModules && subModules.length > 0) {
        subModules?.forEach((subModule: any) => flatten(subModule));
      }
    };

    permissions?.forEach((items: any) => {
      const module = {
        ...items.module,
        view: items.view,
        edit: items.edit,
        create: items.create,
      };
      flatten(module);
    });

    const modulePermissions = flattenedModules?.find(
      ({ moduleName }: any) => moduleName === activeModule
    );

    if (modulePermissions) {
      const { view, create } = modulePermissions;
      return (create === false && view === true && create === false);
    }

    return true;
  }, [activeModule, permissions]);

  return flattenModules;
};
