import { Input, Loader } from "@storybook";
import { ChatPanel } from "../chatPanel";
import { useParams } from "react-router-dom";
import { useDebounce, useNetwork } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { API_URL } from "constant";
import { getJsonToParams } from "utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { Json } from "types";
import { chatDetails, chatInitialAssetId } from "views/chats/state";
import { NoChatsAvailable } from "../NoChatsAvailable";

import "./chat-list.scss";

export const ChatList = () => {
  const { chatId } = useParams();
  const { get: getSssetChatList } = useNetwork();
  const [loading, setLoading] = useState(false);
  const [assetChats, setAssetChats] = useState<any>([]);
  const initialFilter = { search: "", limit: 20, offset: 0 };
  const [filters, setFilters] = useState<any>(initialFilter);
  const [selectedChatDetails, setSelectedChatDetails] =
    useRecoilState(chatDetails);
  const InitialAssetID = useRecoilValue(chatInitialAssetId);
  const abortControllerRef = useRef<any>(null);

  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );

  useEffect(() => {
    if (assetChats?.length && !selectedChatDetails?.chatId) {
      setSelectedChatDetails(assetChats[0] ?? {});
    }
  }, [assetChats, selectedChatDetails]);

  useEffect(() => {
    setAssetChats([]);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    if (InitialAssetID) getAssetList();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters, InitialAssetID]);

  const getAssetList = useCallback(async () => {
    const { signal } = abortControllerRef.current;
    setLoading(true);
    const { immediate, assetType, ..._filters } = filters;
    const response = await getSssetChatList(
      `${API_URL.chatHistory}` +
        getJsonToParams({
          userId: chatId,
          assetId: InitialAssetID,
          ..._filters,
        }),
      { signal }
    );
    const { apiData } = response ?? {};
    if (apiData?.message === "ok") {
      setLoading(false);
      setAssetChats((prev: any) => [...prev, ...apiData?.data]);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, InitialAssetID]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev: any) => ({
      ...prev,
      ...filterObj,
      immediate: !debounce,
    }));
  };

  const handleFetchMore = useCallback(() => {
    setFilters((prev: any) => ({ ...prev, offset: assetChats?.length }));
  }, [assetChats]);

  const getChatDetails = useCallback((data: any) => {
    setSelectedChatDetails(data);
  }, []);

  return (
    <div className="chat-list__container">
      <div className="chat-list__header">
        <p>Users</p>
      </div>
      <div className="chat-list__filter">
        <Input
          label=""
          inputType="text"
          suffixIcon="ri-search-line"
          placeholder="Search"
          handleChange={(e: any) =>
            handleFilter({ search: e.target.value, offset: 0 })
          }
        />
      </div>
      <div className="chat-list__body" id="scrollablechatDiv">
        {loading && <Loader className="loader-blue" dimension={18} />}
        <InfiniteScroll
          dataLength={assetChats?.length || 0}
          next={handleFetchMore}
          hasMore={
            assetChats?.length ===
            filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="scrollablechatDiv"
        >
          {assetChats?.length > 0 && InitialAssetID
            ? assetChats?.map((items: any) => {
                const { receiverName, profileImage, isOnline, initials} =
                  items?.receiverInfo;
                  
                return (
                  <ChatPanel
                    key={items?.id}
                    name={receiverName || "LIQUIDITYUSER"}
                    logo={ profileImage}
                    initials={initials || "LU"}
                    isUserOnline={isOnline}
                    data={items}
                    className={`${
                      selectedChatDetails?.chatId === items?.chatId
                        ? "active-panel"
                        : ""
                    }`}
                    message={items?.message}
                    handleClick={(item: any) => getChatDetails(item)}
                  />
                );
              })
            : !loading && InitialAssetID && <NoChatsAvailable value="chats" />}
        </InfiniteScroll>
      </div>
    </div>
  );
};
