import { useCallback } from "react";

export const useCookie = () => {
  const getCookie = useCallback((key: string): string => {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const decodeCookie = decodedCookie.split(";");

    for (const cookie of decodeCookie) {
      let decodeKey = cookie.trim();

      if (decodeKey.indexOf(name) === 0) {
        return decodeKey.slice(name.length);
      }
    }
    return "";
  }, []);

  const get = useCallback(
    (key: string) => {
      try {
        const localData = JSON.parse(getCookie(key) || "null");
        return localData;
      } catch (err) {
        return null;
      }
    },
    [getCookie]
  );

  const set = useCallback(
    (key: string, value: any) => {
      try {
        const localData: any = get(key);
        const localValue = { ...localData, ...value };
        const localValueStr =
          typeof localValue === "object"
            ? JSON.stringify(localValue)
            : localValue;

        const exdays = 30;
        const newDate = new Date();
        newDate.setTime(newDate.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + newDate.toUTCString();

        document.cookie = key + "=" + localValueStr + ";" + expires + ";path=/";
      } catch (err) {
        // do nothing
      }
    },
    [get]
  );

  const deleteCookie = useCallback(
    (key: string) => {
      try {
        if (getCookie(key)) {
          document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        }
      } catch (err) {
        // do nothing
      }
    },
    [getCookie]
  );

  return { get, set, deleteCookie };
};
