import { saveNewCredentials } from "hooks/web-authentication/utils";
import {
  base64URLStringToBuffer,
  bufferToBase64URLString,
  toAuthenticatorAttachment,
} from "utils/common";
import { useCallback } from "react";

export const useWehAuthn = () => {
  const registerNewCredential = useCallback(
    async (callback: any, registrationOptions: any) => {
      const {
        rp,
        user,
        attestation,
        pubKeyCredParams,
        challenge,
        authenticatorSelection,
        timeout,
        extensions,
      } = registrationOptions ?? {};

      const { name, id, displayName } = user;
      const publicKey: PublicKeyCredentialCreationOptions = {
        rp: rp ?? {},
        user: {
          name: name ?? "",
          id: base64URLStringToBuffer(id ?? ""),
          displayName: displayName ?? "",
        },
        attestation: attestation ?? "direct",
        pubKeyCredParams: pubKeyCredParams ?? [],
        challenge: base64URLStringToBuffer(challenge ?? ""),
        authenticatorSelection: authenticatorSelection ?? {},
        timeout,
        extensions,
        excludeCredentials: [],
      };
      const res = (await navigator?.credentials?.create({
        publicKey: publicKey,
      })) as any;

      const payload = {
        rawId: bufferToBase64URLString(res?.rawId),
        id: res.id,
        name: name ?? "",
        userId: id,
        displayName: displayName ?? "",
      };

      const successPayload = {
        authenticatorAttachment: toAuthenticatorAttachment(
          res.authenticatorAttachment
        ),
        id: res.id,
        rawId: bufferToBase64URLString(res?.rawId),
        response: {
          attestationObject: bufferToBase64URLString(
            res.response.attestationObject
          ),
          clientDataJSON: bufferToBase64URLString(res.response.clientDataJSON),
          transports: ["internal"],
        },
        type: "public-key",
        clientExtensionResults: res.getClientExtensionResults() ?? {},
      };

      saveNewCredentials(payload);
      await callback(successPayload);
    },
    []
  );

  const authenticateDevice = useCallback(
    async (onSuccess: any, resAuthenticateCredentials: any, onfailure: any) => {
      const { id, authenticateOptions } = resAuthenticateCredentials;
      const { challenge, allowCredentials, rpId, timeout, userVerification } =
        authenticateOptions ?? {};
      const registeredCredentials = () => {
        return allowCredentials?.map((registeredDevice: any) => ({
          id: base64URLStringToBuffer(registeredDevice.id),
          type: registeredDevice.type,
          transports: registeredDevice.transports,
        }));
      };
      const publicKey = {
        challenge: base64URLStringToBuffer(challenge ?? ""),
        allowCredentials: registeredCredentials(),
        rpId: rpId,
        timeout: timeout ?? 60000,
        userVerification: userVerification ?? "required",
      };

      try {
        // browser receives the publicKey object and passes it to WebAuthn "get" API
        const res = (await navigator?.credentials?.get({
          publicKey: publicKey,
        })) as any; //as PublicKeyCredential;
        const payload = {
          id,
          authenticateOptions: {
            id: res.id,
            rawId: bufferToBase64URLString(res.rawId),
            response: {
              authenticatorData: bufferToBase64URLString(
                res.response?.authenticatorData
              ),
              clientDataJSON: bufferToBase64URLString(
                res.response?.clientDataJSON
              ),
              signature: bufferToBase64URLString(res.response?.signature),
              userHandle: bufferToBase64URLString(res.response?.userHandle),
            },
            type: "public-key",
            clientExtensionResults: res.getClientExtensionResults(),
            authenticatorAttachment: toAuthenticatorAttachment(
              res.authenticatorAttachment
            ),
          },
        };
        onSuccess(payload);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        if (error.name === "NotAllowedError") {
          onfailure(true);
        }
      }
    },
    []
  );

  const getWebAuthnSupported = async () => {
    // return await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    //@avinash
    return (
      window?.PublicKeyCredential !== undefined &&
      typeof window.PublicKeyCredential === "function"
    );
  };

  return {
    registerNewCredential,
    authenticateDevice,
    getWebAuthnSupported,
  };
};
