import { Input, Loader } from "@storybook";
import { ChatPanel } from "../chatPanel";
import { useParams } from "react-router-dom";
import { useDebounce, useNetwork } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { API_URL } from "constant";
import { getJsonToParams } from "utils";
import { Json } from "types";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState } from "recoil";
import { chatInitialAssetId } from "views/chats/state";
import { NoChatsAvailable } from "../NoChatsAvailable";

import '../chatList/chat-list.scss';

export const ChatAssetList = () => {
  const { chatId } = useParams();
  const { get: getSssetChatList } = useNetwork();
  const [loading, setLoading] = useState(false);
  const [assetChats, setAssetChats] = useState<any>([]);
  const initialFilter = { search: "", limit: 20, offset: 0 };
  const [filters, setFilters] = useState<any>(initialFilter);
  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    400
  );
  const [initialAssetId, setInitialAssetID] =
    useRecoilState(chatInitialAssetId);
  const abortControllerRef = useRef<any>(null);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    getAssetList();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  useEffect(() => {
    if (assetChats.length && !initialAssetId) {
      setInitialAssetID(assetChats[0]?.assetId ?? "");
    }
  }, [assetChats, initialAssetId]);

  const getAssetList = useCallback(async () => {
    const { signal } = abortControllerRef.current;
    setLoading(true);
    const { immediate, assetType, ..._filters } = filters;
    const response = await getSssetChatList(
      `${API_URL.chatDetails}/${chatId}` + getJsonToParams({ ..._filters }),
      { signal }
    );
    const { apiData } = response ?? {};
    if (apiData?.message === "ok") {
      setLoading(false);
      setAssetChats((prev: any) => [...prev, ...apiData?.data]);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setAssetChats([]);
    setFilters((prev: any) => ({
      ...prev,
      ...filterObj,
      immediate: !debounce,
    }));
  };

  const handleFetchMore = useCallback(() => {
    setFilters((prev: any) => ({ ...prev, offset: assetChats?.length }));
  }, [assetChats]);

  const loadChatHiostory = useCallback((assetId: string) => {
    setInitialAssetID(assetId);
  }, []);

  return (
    <div className="chat-list__container">
      <div className="chat-list__header">
        <p>Assets</p>
      </div>
      <div className="chat-list__filter">
        <Input
          label=""
          inputType="text"
          suffixIcon="ri-search-line"
          placeholder="Search"
          handleChange={(e: any) =>
            handleFilter({ search: e.target.value, offset: 0 })
          }
        />
      </div>
      <div id="scrollableAssetChatDiv" className="chat-list__body">
        {loading && <Loader className="loader-blue" dimension={18} />}
        <InfiniteScroll
          dataLength={assetChats?.length || 0}
          next={handleFetchMore}
          hasMore={
            assetChats?.length ===
            filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="scrollableAssetChatDiv"
        >
          {assetChats?.length > 0
            ? assetChats?.map((items: any) => {
                return (
                  <ChatPanel
                    key={items?.id}
                    name={items?.name}
                    className={`${
                      initialAssetId === items?.assetId ? "active-panel" : ""
                    }`}
                    logo={items?.logo}
                    data={items}
                    handleClick={(items: any) =>
                      loadChatHiostory(items?.assetId)
                    }
                  />
                );
              })
            : !loading && <NoChatsAvailable value="assets" />}
        </InfiniteScroll>
      </div>
    </div>
  );
}