import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Button, Confirmation } from "@storybook";
import {
  inviteRoleId,
  inviteUserState,
  selectedRoleState,
  userListState,
} from "../store";
import noUser from "../../../assets/images/noUser.svg";
import { useNetwork, useNotification, useOutsideClick } from "hooks";
import { API_URL } from "constant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useUserPermission } from "hooks/user-permission";

export const AssignedUser = () => {
  const setVisibleInvite = useSetRecoilState(inviteUserState);
  const [confirmVisble, setConfirmVisble] = useState(false);
  const [openUserNumber, setOpenUserNumber] = useState<any>(-1);
  const [selectedUser, setSelectedUser] = useState<any>(0);
  const [action, setAction] = useState("");
  const [userList, setUserList] = useRecoilState(userListState);
  const selectedRole = useRecoilValue(selectedRoleState);
  const setRoleId = useSetRecoilState(inviteRoleId);

  const { patch: userAction, data: userActionData } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const { get: getUsers, data: userDatList } = useNetwork();
  const Ref = useRef(null);

  const buttonDisable = useUserPermission();

  const handleShowModal = useCallback(
    (isOpen: boolean, value: boolean) => {
      setConfirmVisble(isOpen);
      if (value) {
        setAction("revoke");
        const actionPayload = {
          action: "revoke", // ['blocked', 'revoke','unblock']
          roleId: selectedRole?._id,
        };
        userAction(`${API_URL.USERS}/${selectedUser}`, actionPayload);
      }
    },

    [selectedRole?._id, selectedUser, userAction]
  );

  useOutsideClick(Ref, () => {
    setOpenUserNumber(-1);
  });

  const handleModalOpen = useCallback(() => {
    setConfirmVisble(true);
    setOpenUserNumber(-1);
  }, []);

  const handleUserAction = useCallback(
    (action: string) => {
      setAction(action);
      const actionPayload = {
        action: action, // ['blocked', 'revoke','unblock']
        roleId: selectedRole?._id,
      };
      userAction(`${API_URL.USERS}/${selectedUser}`, actionPayload);
    },

    [selectedRole?._id, selectedUser, userAction]
  );

  useEffect(() => {
    if (userActionData) {
      if (userActionData?.message === "ok") {
        setOpenUserNumber(-1);
        const successMsg =
          action === "revoke" ? "User removed" : `User access ${action}`;
        successNotification(successMsg);
        getUsers(API_URL.USERS);
      } else {
        errorNotification(userActionData?.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActionData]);

  useEffect(() => {
    if (userDatList) {
      setUserList(userDatList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDatList]);

  const handleInvite = useCallback(() => {
    setVisibleInvite(true);
    setRoleId(selectedRole?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole?._id]);

  const handelOpenuserList = useCallback(
    (index: number, userId: number) => {
      setSelectedUser(userId);
      openUserNumber === index
        ? setOpenUserNumber(-1)
        : setOpenUserNumber(index);
    },
    [openUserNumber]
  );
  const userDataList = userList?.filter(
    (users: any) => users?._id === selectedRole?._id
  );

  const userData = useCallback(
    () => (
      <>
        {userDataList[0]?.users?.map((user: any, index: number) => {
          return (
            <div className="asigned-user-row">
              <div className="asigned-user--row">
                <div className="user-icon"> {user?.firstName?.charAt(0)} </div>
                <div className={user?.inviteStatus === "pending" || user?.isRoleBlocked ? "pending" : "accepted"} id={`${index}`}>
                  {user?.firstName} {user?.lastName}
                </div>
                <ReactTooltip className="tool-tip" anchorId={`${index}`} place="bottom" content={`${user?.firstName} ${user?.lastName}`} />
              </div>
              <div className="asigned-user--right">
                {!user?.isRoleBlocked && user?.inviteStatus === "pending" && (
                  <div className="invite-status">pending</div>
                )}
                {user?.isRoleBlocked && (
                  <div className="invite-status blocked">Blocked</div>
                )}
                <div className={`${buttonDisable ? "disable": ""}`}>
                <i
                  className={ `ri-more-2-line ${buttonDisable ? "disablebtn": ""}` }
                  onClick={() => handelOpenuserList(index, user?._id)}
                ></i> </div>
              </div>

              {index === openUserNumber && (
                <div className="userAction" ref={Ref}>
                  <div
                    className="userAction-row"
                    onClick={handleModalOpen}
                  >
                    {" "}
                    <i className="ri-user-unfollow-line"></i>
                    Remove user
                  </div>
                  <div
                    className="userAction-row"
                    onClick={() =>
                      handleUserAction(
                        user?.isRoleBlocked ? "unblocked" : "blocked"
                      )
                    }
                  >
                    {" "}
                    <i className="ri-forbid-line"></i>
                    {user?.isRoleBlocked ? "Unblock access" : "Block access"}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openUserNumber, userDataList]
  );
  return (
    <>
      {" "}
      <div className="assigned-user">
        <h3>
          <span>
            Assigned Users:{" "}
            <span className="count">({userDataList?.[0]?.count ?? 0})</span>
          </span>{" "}
          {!!userDataList?.[0]?.users?.length && (
            <Button
              label={"Invite user"}
              className="button"
              handleClick={handleInvite}
              disabled= {buttonDisable}
            />
          )}
        </h3>
        {userDataList?.[0]?.users?.length > 0 ? (
          <div className="assigned--user">{userData()}</div>
        ) : (
          <div className="empty-user">
            <div className="empty--user">
              <img src={noUser} alt="" />
              <h3>No roles added</h3>
              <p>Assign roles to user to manage their permission</p>
              <Button
                label={"Invite user"}
                className="button__filled--primary"
                handleClick={handleInvite}
              />
            </div>
          </div>
        )}
      </div>
      <Confirmation
        handleModal={handleShowModal}
        visible={confirmVisble}
        description="Removing this user will result in user losing all access to this portal. "
        boldDescription="Do you still want to remove this user?"
        label="Remove"
        title="Are you sure?"
        backLabel="Cancel"
      />
    </>
  );
};
