import { useMemo, useCallback, useState } from "react";
// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Button, Modal, ReactResponsiveTable } from "@storybook";
import { AUCTION_TABLE_HEADER } from "views";
import pdfType from "../../assets/images/pdfType.png";
import xslType from "../../assets/images/xslType.png";
import { AuctionDetail, RejectModal, History } from "./component";
import "./auction.scss";
import { useUserPermission } from "hooks/user-permission";
// import { useRemainingSecPercentage } from "hooks";
// interface Json {
//   [key: string]: any;
// }

// const RenderProgresBar = ({ endTime, status }: Json) => {
//   const secPercentage = useRemainingSecPercentage(endTime);

//   return (
//     <CircularProgressbar
//       className="counter-circle"
//       value={
//         status === "yet-to-start"
//           ? 100
//           : status === "completed"
//           ? 0
//           : secPercentage || 0
//       }
//       maxValue={100}
//       strokeWidth={15}
//     />
//   );
// };

export const AuctionTable = () => {
  // const [completedAuctions, setCompletedAUctions] = useState<any>({});
  const [openDocNumber, setOpenDocNumber] = useState<any>(-1);
  const [approveVisible, setApprovalVisible] = useState<boolean>(false);
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
  const [auctionHistory, setAuctionHistory] = useState<boolean>(false);
  const buttonDisable = useUserPermission();  

  const AuctionData = {
    message: "ok",
    data: [
      {
        id: "537535f7-70cb-4fad-8c78-1d13b2c015a2",
        assetId: "36bbc980-f5c8-42d8-bfa0-5fbf70e73e79",
        name: "SBA7",
        auctionName: "Item name",
        createdBy: "Shubham Shukla",
        description: "THis is a descritpion for demo",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/SBA7-1697826017000.image/png",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/SBA7-1697826018000.image/png",
        specification: {},
        tradeType: "classic",
        status: "yet-to-start",
        startPrice: 100,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 10,
        buynowPrice: 1000,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-31T18:22:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-20T18:20:18.862Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Puja.Bhosale",
        symbolValue: "SBA7",
        folderId: "",
        currentBidPrice: 0,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: 0,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-21T14:47:59.4759Z",
      },
      {
        id: "306f7e2b-ed07-4a10-b567-37678752c4bd",
        assetId: "7ab35b59-448f-4de7-8dbf-b613b098796b",
        name: "Test",
        auctionName: "Item name",
        createdBy: "Austin Trombley",
        description: "",
        assetImage: [],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Test-1697775071000.image/jpeg",
        specification: {},
        tradeType: "classic",
        status: "completed",
        startPrice: 100,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 10,
        buynowPrice: 150,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-19T20:33:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-20T04:11:12.261Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Manmohan.Ujala",
        symbolValue: "TestSymbol",
        folderId: "",
        currentBidPrice: 100,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: true,
        antiSnipTimestamp: "2023-10-21T14:47:59.4759Z",
      },
      {
        id: "d470126d-280a-45e3-b5a7-f49e79c542e4",
        assetId: "1128ae41-11fc-4155-ac11-a6d9dec21c64",
        name: "testing 008",
        auctionName: "Item name",
        createdBy: "Alina Trombley",
        description: "desc",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing 008-1697726980000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing 008-1697726980000.image/png",
        specification: {},
        tradeType: "classic",
        status: "live",
        startPrice: 0,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 0,
        buynowPrice: 0,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-25T15:50:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-19T14:49:40.974Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Pankaj.Jasoria",
        symbolValue: "",
        folderId: "",
        currentBidPrice: 0,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-21T14:47:59.4759Z",
      },
      {
        id: "80b40179-1d3e-4e5b-a7ef-f83444fa6564",
        assetId: "d5afaeb8-83cf-4b61-8720-b8cbf0238131",
        name: "pp testing",
        auctionName: "Item name",
        createdBy: "Vaibhav Arora",
        description: "",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/pp testing-1697724674000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/pp testing-1697724675000.image/png",
        specification: {},
        tradeType: "classic",
        status: "completed",
        startPrice: 100,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 50,
        buynowPrice: 1000,
        reservePrice: 0,
        antiSnipTimeStep: 60,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-20T14:09:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-19T14:11:15.369Z",
        isPublished: true,
        antiSnipTimeToAdd: 120,
        symbol: "Pankaj.Jasoria",
        symbolValue: "",
        folderId: "a62e5536-8d9d-4665-b40b-984484f183e0",
        currentBidPrice: 1000,
        finalPrice: 1000,
        totalBid: 4,
        userBidPrice: 0,
        highestBidPrice: 1000,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-19T14:17:28.1728Z",
      },
      {
        id: "28490ef2-d921-4bbd-bdf3-1c3a750fd0e8",
        assetId: "49235e35-2cb2-427e-8d5f-0d8907d7e0ac",
        name: "testing007",
        auctionName: "Item name",
        createdBy: "Prateek Singh",
        description: "description",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing007-1697718474000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing007-1697712622000.image/jpeg",
        specification: {},
        tradeType: "classic",
        status: "live",
        startPrice: 200,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 100,
        buynowPrice: 20000,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-27T07:20:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-19T10:50:22.591Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Pankaj.Jasoria",
        symbolValue: "",
        folderId: "",
        currentBidPrice: 200,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
      },
      {
        id: "4100fd17-34e7-414b-85a6-141996e803f0",
        assetId: "386039da-c783-453f-843b-1b04078025b4",
        name: "dutch test 01",
        auctionName: "Item name",
        createdBy: "Chandan Tiwari",
        description: "this is testing",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/dutch test 01-1697712337000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/dutch test 01-1697712291000.image/jpeg",
        specification: {},
        tradeType: "dutch",
        status: "live",
        startPrice: 10000,
        endPrice: 500,
        timeStepHours: 2,
        timeStepMinutes: 30,
        dutchStepQuantity: 20,
        dutchDuration: 28,
        stepPrice: 100,
        buynowPrice: 0,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-24T05:18:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-19T10:44:51.827Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Pankaj.Jasoria",
        symbolValue: "JKRF",
        folderId: "68b487f5-dc02-481d-bcfe-d5b5be858bd1",
        currentBidPrice: 10000,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
      },
      {
        id: "b5f1cdfa-3c48-4e1f-bb8c-840edf88b105",
        assetId: "c33de39b-9e66-4676-a017-fe0a9f19e93a",
        name: "file testing",
        auctionName: "Item name",
        createdBy: "bcd",
        description: "",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/file testing-1697706119000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/file testing-1697706120000.image/jpeg",
        specification: {},
        tradeType: "classic",
        status: "completed",
        startPrice: 600,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 100,
        buynowPrice: 10000,
        reservePrice: 0,
        antiSnipTimeStep: 180,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-27T03:30:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-19T09:02:00.386Z",
        isPublished: true,
        antiSnipTimeToAdd: 237,
        symbol: "Pankaj.Jasoria",
        symbolValue: "JKRF",
        folderId: "30aaa5b2-087c-43fe-b44b-24024e04010e",
        currentBidPrice: 10000,
        finalPrice: 10000,
        totalBid: 4,
        userBidPrice: 10000,
        highestBidPrice: 10000,
        isWatchlist: true,
        antiSnipTimestamp: "2023-10-20T06:35:58.3558Z",
      },
      {
        id: "501c7d42-8a00-40be-ba17-b1fdfe920e9b",
        assetId: "aee1d337-7636-4f7e-861a-8aee4673323b",
        name: "auction 02",
        auctionName: "Item name",
        createdBy: "pqr",
        description: "this is description",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/auction 02-1697609585000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/auction 02-1697612153000.image/png",
        specification: {},
        tradeType: "classic",
        status: "completed",
        startPrice: 100,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 20,
        buynowPrice: 2000,
        reservePrice: 0,
        antiSnipTimeStep: 200,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-24T20:15:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-18T06:13:05.556Z",
        isPublished: true,
        antiSnipTimeToAdd: 175,
        symbol: "Pankaj.Jasoria",
        symbolValue: "JKRF",
        folderId: "",
        currentBidPrice: 2000,
        finalPrice: 2000,
        totalBid: 7,
        userBidPrice: 2000,
        highestBidPrice: 2000,
        isWatchlist: false,
        antiSnipTimestamp: "2023-10-20T06:38:37.3837Z",
      },
      {
        id: "5e6338b2-ef72-495c-88a6-87da3f65562b",
        assetId: "a6e02a46-8408-4e0a-8d72-96301a974e60",
        name: "Auction test 01",
        auctionName: "Item name",
        createdBy: "xyz",
        description: "Testing description",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Auction test 01-1697605934000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Auction test 01-1697605935000.image/jpeg",
        specification: {},
        tradeType: "classic",
        status: "completed",
        startPrice: 200,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 50,
        buynowPrice: 10000,
        reservePrice: 0,
        antiSnipTimeStep: 120,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-19T23:45:00.000Z",
        autoRestart: false,
        restartSize: 0,
        auctionVisibility: false,
        createdAt: "2023-10-18T05:12:15.504Z",
        isPublished: true,
        antiSnipTimeToAdd: 120,
        symbol: "Pankaj.Jasoria",
        symbolValue: "JKRF",
        folderId: "83b56259-510a-44e2-acee-d5bee646d0e7",
        currentBidPrice: 200,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: true,
        antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
      },
      {
        id: "72a91da0-c3fe-410a-adc2-ecdd31b09fda",
        assetId: "38d5c496-bc23-40dd-ae28-ddc0d69d0237",
        name: "Product Name Dutch",
        auctionName: "Item name",
        createdBy: "Abc",
        description: "Product Name Dutch Description",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Product Name Dutch-1697542238000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Product Name Dutch-1697542238000.image/png",
        specification: {},
        tradeType: "dutch",
        status: "live",
        startPrice: 5000,
        endPrice: 2000,
        timeStepHours: 0,
        timeStepMinutes: 5,
        dutchStepQuantity: 2,
        dutchDuration: 10,
        stepPrice: 500,
        buynowPrice: 0,
        reservePrice: 0,
        antiSnipTimeStep: 0,
        isBidAgentEnabled: false,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-23T22:51:00.000Z",
        autoRestart: true,
        restartSize: 10,
        auctionVisibility: true,
        createdAt: "2023-10-17T11:29:35.454Z",
        isPublished: true,
        antiSnipTimeToAdd: 0,
        symbol: "Manmohan.Ujala",
        symbolValue: "PND",
        folderId: "",
        currentBidPrice: 5000,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: true,
        antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
      },
      {
        id: "2923dd64-a66d-4f21-83f6-558b0b9d80bd",
        assetId: "d33e7268-c5db-4250-b5f7-979d67e5cb63",
        name: "Product Name",
        auctionName: "Item name",
        createdBy: "Chandan Tiwari",
        description: "Auction description testing",
        assetImage: [
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Product Name-1697542060000.image/jpeg",
        ],
        assetIcon:
          "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/Product Name-1697542060000.image/png",
        specification: {},
        tradeType: "classic",
        status: "live",
        startPrice: 500,
        endPrice: 0,
        timeStepHours: 0,
        timeStepMinutes: 0,
        dutchStepQuantity: 0,
        dutchDuration: 0,
        stepPrice: 100,
        buynowPrice: 5000,
        reservePrice: 0,
        antiSnipTimeStep: 120,
        isBidAgentEnabled: true,
        startDateAndTime: "Sept 15, 2023",
        endTime: "2023-10-24T11:27:00.000Z",
        autoRestart: true,
        restartSize: 10,
        auctionVisibility: true,
        createdAt: "2023-10-17T11:27:40.961Z",
        isPublished: true,
        antiSnipTimeToAdd: 120,
        symbol: "Manmohan.Ujala",
        symbolValue: "SYM",
        folderId: "",
        currentBidPrice: 500,
        finalPrice: 0,
        totalBid: 0,
        userBidPrice: 0,
        highestBidPrice: null,
        isWatchlist: true,
        antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
      },
    ],
  };
  // console.log(completedAuctions);
  // const rendererTimeLeft = useCallback(
  //   ({ days, hours, minutes, seconds, completed }: Json, id: string) => {
  //     if (completed) {
  //       setCompletedAUctions((prev: any) => ({ ...prev, [id]: true }));
  //     }
  //     return (
  //       <span>
  //         {!!days ? days + "d " : ""}
  //         {!!hours ? hours + "h " : ""}
  //         {minutes}m {seconds}s
  //       </span>
  //     );
  //   },
  //   []
  // );
  const handelOpenDocList = useCallback(
    (index: number) => {
      openDocNumber === index ? setOpenDocNumber(-1) : setOpenDocNumber(index);
    },
    [openDocNumber]
  );

  const handelApprove = useCallback(() => {
    setApprovalVisible(true);
  }, []);

  const handelReject = useCallback(() => {
    setRejectVisible(true);
  }, []);
  const handelHistory = useCallback(() => {
    setAuctionHistory(true);
  }, []);

  const handeCloseApproval = useCallback(() => {
    setApprovalVisible(false);
  }, []);

  const handeCloseReject = useCallback(() => {
    setRejectVisible(false);
  }, []);

  const handeCloseHistory = useCallback(() => {
    setAuctionHistory(false);
  }, []);

  const tableRows = useMemo(() => {
    if (!AuctionData.data) return [];
    const rows: any = [];
    AuctionData.data?.forEach((items: any, index: number) => {
      if (items) {
        let row: any = {};
        AUCTION_TABLE_HEADER.forEach(({ format, key }) => {
          // row.tradeType = items.tradeType ?? "--";
          row.id = items.id;
          // const { status } = items;
          // row.price = 123;
          // const { status } = items;
          if (key === "actions") {
            const value = () => (
              <div className="auction-actions">
                <i onClick={handelHistory} className="ri-history-line"></i>
                <Button
                  label={"Approve"}
                  className="approve"
                  handleClick={handelApprove}
                  disabled={buttonDisable}
                />
                <Button
                  label={"Reject"}
                  className="reject"
                  handleClick={handelReject}
                  disabled={buttonDisable}
                />
              </div>
            );
            return (row[key] = value);
          }
          if (format === "jsx" && key === "endTime") {
            const value = () => (
              // <div className="flex-center">
              //   <div className="auction__table-date-container auction-date-flex-center">
              //     <RenderProgresBar endTime={items.endTime} status={status} />
              //     {status === "completed" && "Auction ended"}
              //     {status === "yet-to-start" && "Yet to start"}
              //     {status === "live" && (
              //       <CountdownTimer
              //         dateTime={items.endTime}
              //         renderer={(timeValue) =>
              //           rendererTimeLeft(timeValue, items.id)
              //         }
              //       />
              //     )}
              //   </div>
              // </div>
              <>
                Sept 15, 2023
                <div className="startTime">03:20PM</div>
              </>
            );
            return (row[key] = value);
          }
          if (key === "supportingDoc") {
            const value = () => (
              <div className="circleDocs">
                <div className="circleDoc">
                  <div className="circleDocS">
                    <img src={pdfType} alt="" />
                  </div>
                </div>

                <div className="circleDoc">
                  <div className="circleDocS">
                    <img src={xslType} alt="" />
                  </div>
                </div>

                {/* <div className="circleDoc">
                  <div className="circleDocS">
                    <img src={pdfType} alt="" />
                  </div>
                </div> */}

                <div
                  className="circleDoc moreDoc"
                  onClick={() => handelOpenDocList(index)}
                >
                  <div className="circleDocS">+5</div>
                </div>
                {index === openDocNumber && (
                  <div className="moreDoc-list">
                    <div className="arrow"></div>
                    <div className="moreDoc-list-wrapper">
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={pdfType} alt="" />
                        </div>
                        File Name <span></span> 3.2MB
                      </div>
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={xslType} alt="" />
                        </div>
                        File Name <span></span> 5.7MB
                      </div>
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={xslType} alt="" />
                        </div>
                        File Name <span></span> 4.4MB
                      </div>
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={pdfType} alt="" />
                        </div>
                        File Name <span></span> 2.8MB
                      </div>
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={xslType} alt="" />
                        </div>
                        File Name <span></span> 3.1MB
                      </div>
                      <div className="moreDoc--list">
                        <div className="moreDoc--list-img">
                          <img src={pdfType} alt="" />
                        </div>
                        File Name <span></span> 3.2MB
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
            return (row[key] = value);
          }
          if (key === "startDateAndTime") {
            const value = () => (
              <>
                {items[key]}
                <div className="startTime">03:20PM</div>
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [AuctionData.data]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      // <ReactResponsiveTable
      //   isLoaded={true}
      //   isLoading={false}
      //   numberOfList={6}
      //   column={AUCTION_TABLE_HEADER}
      //   rows={tableRows}
      //   height="calc(100vh - 285px)"
      //   showSearch={true}
      //   showFilter={true}
      // />
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={AUCTION_TABLE_HEADER}
        // height="calc(100vh - 285px)"
        showSearch={true}
        showFilter={true}
      />
    );
  }, [tableRows]);

  return (
    <div className="super-admin-wrapper">
      {/* <Header title="Auction" /> */}
      {renderSuperAdminTable}
      <Modal
        isOpen={approveVisible}
        modalName={"d"}
        closeModal={handeCloseApproval}
        className="approval-auction-detail"
      >
        <AuctionDetail />
      </Modal>
      <Modal
        isOpen={rejectVisible}
        modalName={"d"}
        closeModal={handeCloseReject}
        className="reject-auction-detail"
      >
        <RejectModal />
      </Modal>
      <Modal
        isOpen={auctionHistory}
        modalName={""}
        className="auction-history"
        closeModal={handeCloseHistory}
      >
        <History />
      </Modal>
    </div>
  );
};
