import { useMobileAuthentication } from "../../store/hooks";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "hooks";
import { useMemo, useState } from "react";
import { ErrorIcons, SuccessIcon } from "../status";
// import success from "../../../../assets/icon/success.gif";
import image from "../../../../assets/images/approve.svg";
import "./mobile-approve.scss";
import { Loader } from "@storybook";

const MobileApprove = () => {
  const [searchParams] = useSearchParams();
  const { errorNotification } = useNotification();
  const { mobileLinkAction } = useMobileAuthentication();
  const [linkExpired, setLinkExpired] = useState(false);
  const [linkApproved, setLinkApproved] = useState(false);
  const [isLoading, setIsLoading] = useState<any>({});
  const appendValueToString = (appendString: string, actualValue: string) => {
    return appendString + actualValue.trim();
  };

  const handleClickAction = async (action: string) => {
    setIsLoading((prev: any) => {
      return {
        ...prev, [action]: true
      }
    });
    const code =
      searchParams.get("loginCode") ?? searchParams.get("biometricCode");
    const phone = searchParams.get("phone");
    const country = searchParams.get("countryCode");
    let countryCode = appendValueToString("+", country ?? "");

    if (code && phone && countryCode) {
      const payload = {
        type: "phoneCode",
        code: code,
        choice: action,
        countryCode: countryCode,
        phone: phone,
      };
      const response: any = await mobileLinkAction(payload);
      if (!response?.status) {
        setLinkExpired(true);
      } else {
        if (response?.response?.data?.status === "approved") {
          setLinkApproved(true);
        } else {
          setLinkExpired(true);
        }
      }
    } else {
      errorNotification("Invalid code");
    }
    setIsLoading((prev: any) => {
      return {
        ...prev, [action]: false
      }
    });
  };

  const renderSuccessScreen = useMemo(() => {
    return (
      <div className="mobile-link">
        <div className="mobile-verification__success">
          {/* <img loading="lazy" src={success} alt="" /> */}
          <div className="mobile-verification__svg-height">
            <SuccessIcon />
          </div>
          <div className="mobile-verification__success-message">
            Logged in successfully
          </div>
          <div className="mobile-approve__actions-close">
            <button onClick={() => {
              setLinkApproved(false);
              setLinkExpired(false)
            }}>Close</button>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderErrorScreen = useMemo(() => {
    return (
      <div className="mobile-link">
        <div className="mobile-verification__error">
          <div className="mobile-verification__svg-height">
            <ErrorIcons />
          </div>
          <div className="mobile-verification__success-message">
            Login denied or Link expired
          </div>
          <div className="mobile-approve__actions-close">
            <button onClick={() => {
              setLinkApproved(false);
              setLinkExpired(false)
            }}>Close</button>
          </div>
        </div>
      </div>
    );
  }, []);

  if (linkExpired) return <>{renderErrorScreen}</>;
  if (linkApproved) return <>{renderSuccessScreen}</>;
  return (
    <div className="mobile-link">
      <div className="mobile-approve">
        <div className="mobile-approve__container">
          <div className="mobile-approve__header">
            Login approval
          </div>
          <div className="mobile-approve__image">
            <img
              loading="lazy"
              className="MobileApprove__logo"
              src={image}
              alt="logo"
            />
          </div>
          <p className="mobile-approve__description">
            Click approve if it is you trying to login into <strong>Liquidity super admin</strong>.
          </p>
          <div className="mobile-approve__actions">
            <button onClick={() => handleClickAction("accept")}> {isLoading?.accept ? <Loader dimension={20} /> : "Approve"}</button>
            <button onClick={() => handleClickAction("reject")}>{isLoading?.reject ? <Loader dimension={20} /> : "Deny"}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileApprove;
