export const API_URL = {
  LOGIN: "login",
  USER_LOGIN: "/super-admin",
  ACCOUNTLIST: "/accounts",
  MODULE: "/access-modules",
  ROLES: "/roles",
  USERS: "/users",
  UPDATE_ACCOUNT_STATUS: "/account-lock",
  COOWNER_LIST: "/co-owners",
  GOOGLELOGIN: "/google-SSO",
  EMAIL_INVITE: "/email-invite",
  CLIENT_WEBAUTHN: "/device-biometric",
  VerifyPhone: "/verify-phone",
  EmailCodes: "/verify-otp",
  userVerifyLink: "/user-login",
  WebAuthLogin: "/TBD",
  VERIFY_EMAIL_MOBILE: "/verify-email-mobile",
  USER_DETAILS: "/user-details",
  SIMPLICI_TOKEN: "/simplici-token",
  ACTIVITY_LOG: "/activity-log",
  USER_LIST: "/user-list",
  ASSETS: "/assets",
  ASSET_HISTORY: "/assetHistory",
  ASSET_HISTORY_READ: "/assetHistoryReadStatus",
  exchangeSba7: "",
  chatDetails: "/user-chat-info",
  chatHistory: "/message-history",
  userDvp:"/user-dvp",
  userAcessToken:"/user-access-token",
  bundleDetails:"/bundle-details",
  version:"/app-version",
  FIREBASE: "/firebase",
  REVENUE: "/revenue",
};
