import { Loader, ReactResponsiveTable } from "@storybook";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ACCOUNT_TABLE_HEADER } from "views/accounts/store/constant";
import { OnboardingFlowStatus } from "../onboardingFlowStatus";
import { useAccountApis } from "views/accounts/store/hooks";
import { Json } from "types";
import { EllipsisMenu } from "@storybook/EllipsisMenu";
import "./accountList.scss";
import { useNotification } from "hooks";
import { useUserPermission } from "hooks/user-permission";
import { AccountDataState, FilterState } from "../../store/state";

type Props = {
  handleClick: (id: string) => void;
  accessToken: string;
  handleSessionClick?: any;
  sendDataToParent:(loading:boolean)=>void;
  isLoading: boolean;
};

export const AccountList: FC<Props> = ({
  handleClick,
  handleSessionClick,
  sendDataToParent,
  isLoading
}) => {
  const AccountListOptions = [
    {
      label: "Co-owner/Authorized user",
      iconClass: "ri-team-line",
      onClick: (e: any, id: string) => {
        e.stopPropagation();
        handleClick(id);
      },
    },
  ];
  const [AccountListData, setAccountListData] =
    useRecoilState(AccountDataState);
  const [accountLockId, setAccountLockedId] = useState<string | null>(null);
  const { successNotification } = useNotification();
  const buttonDisable = useUserPermission();
  const filters = useRecoilValue(FilterState);
  const {
    getAuctionList,
    data: auctionData,
    loading: accountListLoading,
  } = useAccountApis();

  const { lockUnlockAccounts, loading: lockAccountLoading } = useAccountApis();

 

  useEffect(()=>{
     sendDataToParent(accountListLoading);
  },[accountListLoading])

  useEffect(() => {
     const { immediate, ..._filters } = filters;
    getAuctionList({
      ..._filters,
    });
  }, [filters]);

  useEffect(() => {
    setAccountListData(auctionData);
  }, [auctionData]);

  const getUserType = (type: string) => {
    if (type === "business") {
      return (
        <>
          <i className="ri ri-briefcase-fill"></i>
          Entity
        </>
      );
    } else {
      return (
        <>
          <i className="ri ri-user-3-fill"></i>
          Individual
        </>
      );
    }
  };

  const getUserStatus = (status: string) => {
    if (status === "completed") {
      return <i className="ri ri-checkbox-circle-fill"></i>;
    } else if (status === "rejected") {
      return <i className="ri ri-close-circle-fill"></i>;
    } else {
      return <i className="ri ri-time-fill"></i>;
    }
  };

  const convertJson = (onboardingStatus: Json): Json => {
    if (!Object.keys(onboardingStatus).length) return [];
    const result: Json = {};
    onboardingStatus?.forEach((item: Json) => {
      result[item?.stepId] = item?.status ?? "processing";
    });
    return result;
  };

  const getInitials = useCallback((name: any) => {
    if (!name) return "--";
    const words = name.split(" ");
    let initials =
      words[0]?.charAt(0).toUpperCase() +
      (words.length > 1
        ? words[words.length - 1]?.charAt(0).toUpperCase()
        : "");
    return initials;
  }, []);

  const handleUpdateAccountList = (sessionId: string, lockAction: boolean) => {
    const myNextList = [...AccountListData];
    const artwork: any = myNextList?.find(
      (a: Json) => a?.sessionId === sessionId
    );
    artwork.isAccountLocked = lockAction;
    setAccountListData(myNextList);
    setAccountLockedId("");
    successNotification(
      `Account ${lockAction ? "locked" : "unlocked"} successfully`
    );
  };

  const updateAccountStatus = (
    e: any,
    sessionId: string,
    lockAction: boolean
  ) => {
    e.stopPropagation();
    if (lockAccountLoading) return;
    setAccountLockedId(sessionId);
    lockUnlockAccounts(sessionId, {
      type: "user",
      isLocked: lockAction,
    }).then((res: any) => {
      if (res?.success) {
        handleUpdateAccountList(sessionId, lockAction);
      }
    });
  };

  const renderAccountList = useMemo(() => {
    if (!AccountListData) return [];
    const rows: any = [];
    AccountListData?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        ACCOUNT_TABLE_HEADER.forEach(({ format, key }) => {
          row.id = items?.sessionId;
          if (format === "jsx" && key === "company") {
            const value = () => (
              <div className="account-conpany-name">
                <span className="name-initials">
                  {getInitials(items?.name)}
                </span>
                <div className="company-details-general">
                  <p className="name">{items?.name}</p>
                  <span className="account-location">
                    <p>{items?.city}</p>
                    <p>{items?.state}</p>
                  </span>
                </div>
              </div>
            );

            return (row[key] = value);
          }

          if (format === "jsx" && key === "email") {
            const value = () => (
              <div className="account-details-personal">
                <p className="email">{items?.email ?? "--"}</p>
                <p className="phone">
                  {items?.countryCode} {items?.mobile}
                </p>
              </div>
            );

            return (row[key] = value);
          }

          if (format === "jsx" && key === "OnboardingStatus") {
            const value = () => (
              <>
                <div className="session-status">
                  <OnboardingFlowStatus
                    fullStatus={convertJson(items?.onboardingStatus)}
                  />
                </div>
              </>
            );
            return (row[key] = value);
          }

          if (key === "type") {
            const value = () => (
              <div className="account-type">
                {getUserType(items?.type?.toLowerCase())}
              </div>
            );
            return (row[key] = value);
          }

          if (key === "status") {
            const value = () => (
              <div className="account-status">
                {getUserStatus(items?.status?.toLowerCase())}
                {items.status}
              </div>
            );
            return (row[key] = value);
          }

          if (key === "actions") {
            const value = () => (
              <div
                className={`account-actions accountdropdown-list ${
                  buttonDisable ? "disable" : ""
                }`}
              >
                {accountLockId?.includes(items?.sessionId) &&
                lockAccountLoading ? (
                  <Loader dimension={16} className="loader-blue" />
                ) : (
                  <i
                    className={`ri ${
                      items?.isAccountLocked
                        ? "ri-lock-2-line"
                        : "ri-lock-unlock-line"
                    } ${buttonDisable ? "disablebtn" : ""}`}
                    onClick={(e) =>
                      updateAccountStatus(
                        e,
                        items?.sessionId,
                        !items?.isAccountLocked
                      )
                    }
                  ></i>
                )}
                <EllipsisMenu
                  buttons={AccountListOptions}
                  sessionId={items?.sessionId}
                />
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });

        rows.push(row);
      }
    });

    return rows;
  }, [AccountListData, lockAccountLoading, accountLockId]);

  const renderSuperAdminTable = useMemo(() => {
    return (
      <ReactResponsiveTable
        className="account__container-body"
        rows={renderAccountList}
        isLoading={accountListLoading || isLoading}
        column={ACCOUNT_TABLE_HEADER}
        height="calc(100vh - 175px)"
        showSearch={false}
        showFilter={false}
        handelRowClick={(data: any, e: any) => {
          handleSessionClick(data?.id ?? "");
          e.stopPropagation();
        }}
      />
    );
  }, [renderAccountList, accountListLoading, isLoading]);

  return (
    <>
      <div className="account-wrapper">{renderSuperAdminTable}</div>
    </>
  );
};
