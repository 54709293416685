import { Button, CountryCode, ICountries, Input } from "@storybook";
import "./inviteUser.scss";
import { useCallback, useEffect, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { inviteRoleId, inviteUserState, userListState } from "../store";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
}

const initialFormData: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  countryCode: "+1",
};

export const InviteUser = () => {
  const roleid = useRecoilValue(inviteRoleId);
  const { get: getUsers } = useNetwork();
  const {
    post: InviteUserData,
    data: inviteUserData,
    apiResponse,
  } = useNetwork();

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [emailError, setEmailError] = useState<string>("");
  const { successNotification, errorNotification } = useNotification();
  const setVisibleInvite = useSetRecoilState(inviteUserState);
  const setUserList = useSetRecoilState(userListState);

  const validateEmail = (email: string): boolean => {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const changeInputHandle = useCallback(
    (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const re = /^[0-9]*$/;
      if (name === "phoneNumber" && !re.test(value)) {
        return;
      }
      const alphabeticRegex = /^[A-Za-z]+$/;
      if (
        ["firstName", "lastName"].includes(name) &&
        !alphabeticRegex.test(value) &&
        value !== ""
      ) {
        return;
      }
      setFormData((pre: any) => {
        let preState = { ...pre, [name]: value };
        return preState;
      });

      if (name === "email") {
        if (!validateEmail(value)) {
          setEmailError("Please enter a valid email address.");
        } else {
          setEmailError("");
        }
      }
    },
    [setFormData]
  );

  const handleInvite = useCallback(() => {
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      countryCode: formData.countryCode,
      phone: formData.phoneNumber,
      roles: [
        {
          role: roleid,
        },
      ],
    };
    if (emailError) {
      errorNotification("Please enter a valid email");
      return;
    }
    InviteUserData(API_URL.USERS, payload);
  }, [
    InviteUserData,
    emailError,
    errorNotification,
    formData.countryCode,
    formData.email,
    formData.firstName,
    formData.lastName,
    formData.phoneNumber,
    roleid,
  ]);

  useEffect(() => {
    if (inviteUserData?.data && apiResponse?.status === 200) {
      getUsers(API_URL.USERS).then((res: any) => {
        setUserList(res?.apiData?.data);
      });
      successNotification("Invite sent successfully");
      setVisibleInvite(false);
    } else {
      errorNotification(inviteUserData?.message);
    }
  }, [
    errorNotification,
    getUsers,
    inviteUserData,
    setVisibleInvite,
    successNotification,
  ]);

  const handleCountryCode = useCallback((e: ICountries) => {
    const value = e.label;
    setFormData((prevState) => {
      const newState = { ...prevState, countryCode: value };
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="inviteUser">
      <h3>Invite user </h3>
      <p>Assign roles to users for streamlined access</p>
      <div className="invite-name">
        <Input
          label={"First Name"}
          inputType={"text"}
          placeholder={"Enter first name"}
          handleChange={(e) => changeInputHandle("firstName", e)}
          value={formData.firstName}
          maxLength={30}
        />
        <Input
          label={"Last Name"}
          inputType={"text"}
          placeholder={"Enter last name"}
          handleChange={(e) => changeInputHandle("lastName", e)}
          value={formData.lastName}
          maxLength={30}
        />
      </div>

      <Input
        label={"Email"}
        inputType={"text"}
        placeholder={"Enter email"}
        handleChange={(e) => changeInputHandle("email", e)}
        value={formData.email}
      />
      <div className="inviteUser__phone-header">Phone Number</div>
      <div className="inviteUser__phone-number">
        <CountryCode handleChangeCountry={handleCountryCode} />
        <input
          type="tel"
          minLength={6}
          maxLength={10}
          className={"inviteUser__phone-input"}
          onChange={(e) => changeInputHandle("phoneNumber", e)}
          value={formData.phoneNumber}
          placeholder="XXXXXXXXXX"
          name="phone"
          autoComplete="tel"
        />
      </div>
      <div className="footer-btn">
        <Button
          label={"Cancel"}
          className="button__outline"
          handleClick={() => setVisibleInvite(false)}
        />
        <Button
          label={"Invite"}
          className="button__filled--primary"
          handleClick={handleInvite}
          disabled={
            formData.phoneNumber === "" ||
            formData.email === "" ||
            formData.firstName === "" ||
            formData.lastName === ""
          }
        />
      </div>
    </div>
  );
};
