export const MINT_TABLE_HEADER = [
  {
    label: "Name/Symbol ",
    key: "name",
    format: "jsx",
    className: "",
    width: "30%",
  },

  {
    label: "Issuer Name",
    key: "issuer",
    format: "jsx",
    width: "18%",
  },
  {
    label: "Type",
    key: "sbaType",
    format: "jsx",
    width: "18%",
  },
  {
    label: "# of loans",
    key: "numberOfLoans",
    format: "jsx",
    width: "18%",
  },
  {
    label: "Actions",
    key: "actions",
    format: "jsx",
    width: "26%",
  },
];

export const LOAN_NAME: any = {
  whole_loans: "Whole Loans",
  current_guaranteed_loan_balance: "Gov. Guaranteed Portions",
};

export const Sba_Details = {
  data: {
    id: "d636c03c-4123-47cc-88dd-cf128f40de61",
    name: "LION Technologies",
    symbol: "AKJS",
    price: "7703.7143",
    logo: "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/sba7/666ff11c4349464d4f78f549/045f5851-386c-491e-9ec8-41cb72c67643.png",
    type: "sba7",
    userId: "666ff11c4349464d4f78f549",
    sba7Data: {
      coverImage: {
        id: "52f7cf23-f312-4e54-98ef-72a1ba8e490c",
        type: "jpeg",
      },
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
      sbaType: "POOL",
      currentLoanBalance: 300926.34,
      currentYield: 9,
      totalWeightedLoanBalance: 27083.3706,
      loanType: "whole_loans",
    },
    folderId: "55e97e32-2a92-4821-be19-66b272681109",
    createdAt: "2024-06-25T05:43:52.542Z",
    updatedAt: "2024-06-25T05:43:52.542Z",
    dataRoomFiles: [
      {
        id: "dee97f85-bd77-4089-ae16-33cbb7dff34f",
        folderId: "55e97e32-2a92-4821-be19-66b272681109",
        docName: "ServiceBook.xlsx",
        fileName: "1e3f4710-d6f9-4fb6-a51d-93a3566e58d8.xlsx",
        fileUrl:
          "https://api.stage.satschel.com/v1/url/get-doc?documentId=dee97f85-bd77-4089-ae16-33cbb7dff34f&action=download",
        uploadType: "BIDSHEET",
        metadata: {
          fileJSON: [
            {
              lender_loan_number: "1111111",
              gp_number: "4196379100",
              gp_prefix: "PLP",
              borrower_name: "LION TECHNOLOGIES, LLC",
              address: "7844 Rosecrans Avenue Suite E",
              borrower_city: "Paramount",
              borrower_state: "CA",
              borrower_zip: "90723",
              naics_code: "541519",
              sba_authorization_date: "2022-07-25",
              note_date: "2022-08-01",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-17",
              final_disbursement_date: "2022-08-17",
              first_payment_date: "2022-09-01",
              paid_to_date: "2022-11-01",
              maturity_date: "2032-08-01",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "86%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24512.39",
              payment_amount: "315.4",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Fresno",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "180599",
              total_no_of_employees: "1",
              no_of_jobs_created: "10",
              no_of_jobs_retained: "10",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "4.75%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111112",
              gp_number: "4204069102",
              gp_prefix: "PLP",
              borrower_name: "DD QUALITY TIRES AND RIMS LLC",
              address: "3355 Stone Mountain HWY Suite C",
              borrower_city: "Snellville",
              borrower_state: "GA",
              borrower_zip: "30078",
              naics_code: "441340",
              sba_authorization_date: "2022-07-27",
              note_date: "2022-08-02",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-18",
              final_disbursement_date: "2022-08-18",
              first_payment_date: "2022-09-02",
              paid_to_date: "2022-11-02",
              maturity_date: "2032-08-02",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "16200",
              current_loan_balance: "15884.41",
              payment_amount: "204.38",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "65072.33",
              total_no_of_employees: "4",
              no_of_jobs_created: "5",
              no_of_jobs_retained: "4",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "4.75%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111113",
              gp_number: "4208349100",
              gp_prefix: "PLP",
              borrower_name: "USYKES TRUCKING LLC",
              address: "6504 Deseo Apartment #201C",
              borrower_city: "Irving",
              borrower_state: "TX",
              borrower_zip: "75039",
              naics_code: "484121",
              sba_authorization_date: "2022-07-29",
              note_date: "2022-08-03",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-17",
              final_disbursement_date: "2022-08-17",
              first_payment_date: "2022-09-03",
              paid_to_date: "2022-10-25",
              maturity_date: "2032-08-03",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24468.13",
              payment_amount: "315.53",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "132000",
              total_no_of_employees: "3",
              no_of_jobs_created: "3",
              no_of_jobs_retained: "3",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "4.75%",
              settlement_date: "2022-11-30",
            },
            {
              lender_loan_number: "1111114",
              gp_number: "4199549109",
              gp_prefix: "PLP",
              borrower_name: "UBIQPLUS, INC.",
              address: "15 West 38TH Street #542",
              borrower_city: "New York",
              borrower_state: "NY",
              borrower_zip: "10018",
              naics_code: "541930",
              sba_authorization_date: "2022-07-26",
              note_date: "2022-08-15",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-15",
              paid_to_date: "2022-10-17",
              maturity_date: "2032-08-15",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24680.52",
              payment_amount: "315.77",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "C-Corporation",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: "586",
              business_annual_revenues: "199683",
              total_no_of_employees: "4",
              no_of_jobs_created: "0",
              no_of_jobs_retained: "1",
              primary_owner_gender: "Female",
              primary_owner_veteran_status: "Non-Veteran",
              primary_owner_race: "Asian",
              primary_owner_ethnicity: "Not Hispanic or Latino",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "4.75%",
              settlement_date: "2022-12-05",
            },
            {
              lender_loan_number: "1111115",
              gp_number: "4213769102",
              gp_prefix: "PLP",
              borrower_name: "SSALI PRODUCTIONS, LLC",
              address: "62 KELSEY AVENUE",
              borrower_city: "Huntington Station",
              borrower_state: "NY",
              borrower_zip: "11746",
              naics_code: "711110",
              sba_authorization_date: "2022-08-01",
              note_date: "2022-08-15",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-15",
              paid_to_date: "2022-11-07",
              maturity_date: "2032-08-15",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "21000",
              current_loan_balance: "20511.47",
              payment_amount: "265.35",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "84017",
              total_no_of_employees: "1",
              no_of_jobs_created: "1",
              no_of_jobs_retained: "1",
              primary_owner_gender: "Male",
              primary_owner_veteran_status: "Non-Veteran",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Hispanic or Latino",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-12-05",
            },
            {
              lender_loan_number: "1111116",
              gp_number: "4222849104",
              gp_prefix: "PLP",
              borrower_name: "CARDINAL AUTO WASH LLC",
              address: "8660 Mentor Avenue",
              borrower_city: "Mentor",
              borrower_state: "OH",
              borrower_zip: "44060",
              naics_code: "811192",
              sba_authorization_date: "2022-08-05",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24696.07",
              payment_amount: "316.12",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Fresno",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "326433.33",
              total_no_of_employees: "10",
              no_of_jobs_created: "2",
              no_of_jobs_retained: "10",
              primary_owner_gender: "Male",
              primary_owner_veteran_status: "Non-Veteran",
              primary_owner_race: "White",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-12-05",
            },
            {
              lender_loan_number: "1111117",
              gp_number: "4223589100",
              gp_prefix: "PLP",
              borrower_name: "CLOSEWRITE  PROPERTY PRESERVATION INC",
              address: "3109 Marshall Avenue",
              borrower_city: "Newport News",
              borrower_state: "VA",
              borrower_zip: "23607",
              naics_code: "236118",
              sba_authorization_date: "2022-08-08",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24696.52",
              payment_amount: "316.13",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "C-Corporation",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: "698",
              business_annual_revenues: "128296.67",
              total_no_of_employees: "3",
              no_of_jobs_created: "3",
              no_of_jobs_retained: "3",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111118",
              gp_number: "4223649105",
              gp_prefix: "PLP",
              borrower_name: "ALEX FRANKS",
              address: "19464 Martin Luther King Drive",
              borrower_city: "State Line",
              borrower_state: "MS",
              borrower_zip: "39362",
              naics_code: "113210",
              sba_authorization_date: "2022-08-08",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-20",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24718.43",
              payment_amount: "316.25",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Sole Proprietorship",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: "674",
              business_annual_revenues: "189229",
              total_no_of_employees: "1",
              no_of_jobs_created: "2",
              no_of_jobs_retained: "3",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-30",
            },
            {
              lender_loan_number: "1111119",
              gp_number: "4226179101",
              gp_prefix: "PLP",
              borrower_name: "JACOBGWC, INC.",
              address: "910 SKOKIE BOULEVARD STE 115",
              borrower_city: "Northbrook",
              borrower_state: "IL",
              borrower_zip: "60062",
              naics_code: "541219",
              sba_authorization_date: "2022-08-09",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "20300",
              current_loan_balance: "20053.2",
              payment_amount: "256.69",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Fresno",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "C-Corporation",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "81541.67",
              total_no_of_employees: "1",
              no_of_jobs_created: "1",
              no_of_jobs_retained: "1",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111120",
              gp_number: "4226979105",
              gp_prefix: "PLP",
              borrower_name: "LOUBRIEL & ASSOCIATES REALTY, INC",
              address: "8816 Jamaica Avenue",
              borrower_city: "Woodhaven",
              borrower_state: "NY",
              borrower_zip: "11421",
              naics_code: "531210",
              sba_authorization_date: "2022-08-09",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24696.34",
              payment_amount: "316.13",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "yes",
              franchise_name: "Realty Executives",
              franchise_code: "S1408",
              business_organization_code: "C-Corporation",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "151824",
              total_no_of_employees: "2",
              no_of_jobs_created: "2",
              no_of_jobs_retained: "4",
              primary_owner_gender: "Male",
              primary_owner_veteran_status: "Non-Veteran",
              primary_owner_race: "White",
              primary_owner_ethnicity: "Hispanic or Latino",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111811",
              gp_number: "4230759101",
              gp_prefix: "PLP",
              borrower_name: "GODFREDO HERNANDEZ",
              address: "7626 Leopard Street",
              borrower_city: "Corpus Christi",
              borrower_state: "TX",
              borrower_zip: "78409",
              naics_code: "484121",
              sba_authorization_date: "2022-08-11",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "23600",
              current_loan_balance: "23313.91",
              payment_amount: "298.43",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Sole Proprietorship",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "94771",
              total_no_of_employees: "2",
              no_of_jobs_created: "0",
              no_of_jobs_retained: "2",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111812",
              gp_number: "4232539109",
              gp_prefix: "PLP",
              borrower_name: "FEEL GOOD PILATES LLC",
              address: "201 East 16th Street #3A",
              borrower_city: "New York",
              borrower_state: "NY",
              borrower_zip: "10003",
              naics_code: "713940",
              sba_authorization_date: "2022-08-11",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-18",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "24300",
              current_loan_balance: "24004.57",
              payment_amount: "307.27",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Limited Liability Company",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "97514.33",
              total_no_of_employees: "1",
              no_of_jobs_created: "1",
              no_of_jobs_retained: "1",
              primary_owner_gender: "Female",
              primary_owner_veteran_status: "Non-Veteran",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Hispanic or Latino",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-11-29",
            },
            {
              lender_loan_number: "1111813",
              gp_number: "4236159110",
              gp_prefix: "PLP",
              borrower_name: "MICHAEL OKPALA",
              address: "8630 Fenton Street Suite 932",
              borrower_city: "Silver Spring",
              borrower_state: "MD",
              borrower_zip: "20705",
              naics_code: "541110",
              sba_authorization_date: "2022-08-15",
              note_date: "2022-08-18",
              sba_fee_payment_date: "NAP",
              first_disbursement_date: "2022-08-24",
              final_disbursement_date: "2022-08-24",
              first_payment_date: "2022-09-18",
              paid_to_date: "2022-10-17",
              maturity_date: "2032-08-18",
              reset_frequency: "Q",
              payment_frequency: "M",
              accrual_basis: "365/365",
              sba_guarantee_percentage: "85%",
              lifetime_cap: "NAP",
              lifetime_floor: "NAP",
              original_loan_amount: "25000",
              current_loan_balance: "24690.38",
              payment_amount: "316.13",
              base_rate: "PRIME",
              gross_margin: "2.75%",
              "current_rate_a-o_last_reset": "9.00%",
              sba_office_code: "Little Rock",
              guaranteed_variable: "yes",
              "un-guaranteed_variable": "yes",
              next_adjustment_date: "2023-01-01",
              has_franchise: "no",
              franchise_name: null,
              franchise_code: null,
              business_organization_code: "Sole Proprietorship",
              is_existing_business_code: "1",
              dscr: null,
              ltv: null,
              average_fico: null,
              business_annual_revenues: "230333.33",
              total_no_of_employees: "3",
              no_of_jobs_created: "0",
              no_of_jobs_retained: "3",
              primary_owner_gender: "Not Disclosed",
              primary_owner_veteran_status: "Not Disclosed",
              primary_owner_race: "Not Disclosed",
              primary_owner_ethnicity: "Not Disclosed",
              miscellaneous_loan_comments: null,
              loan_seller_name: "Northeast Bank",
              loan_originator_name: "Northeast Bank",
              ongoing_sba_fees: "-",
              prime_rate_as_shown_on_note: "5.50%",
              settlement_date: "2022-12-05",
            },
          ],
        },
        fileSize: 12421,
      },
      {
        id: "1fb6a740-f327-4f89-8886-a15d3abd75d6",
        folderId: "55e97e32-2a92-4821-be19-66b272681109",
        docName: "VIN.xlsx",
        fileName: "10144e85-ac08-4b15-83c0-eea4858874fb.xlsx",
        fileUrl:
          "https://api.stage.satschel.com/v1/url/get-doc?documentId=1fb6a740-f327-4f89-8886-a15d3abd75d6&action=download",
        uploadType: "SBA1502",
        metadata: {
          fileJSON: [
            {
              "SBA GP Number (ten digits only)": "1111111",
              "Lender Loan Number": "1111111",
              "Next Installment Due Date (mm/dd/yyyy)": "09/23/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.00%",
              "Guar. Portion Interest": "78.44",
              "Guar. Portion Principal": "0.00",
              "Guar. Portion Pymt or Fee ": "78.44",
              "From \n(mm/dd/yyyy)": "08/24/2023",
              "To \n(mm/dd/yyyy)": "09/08/2023",
              "# of Days": "15",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "19087.42",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111112",
              "Lender Loan Number": "1111112",
              "Next Installment Due Date (mm/dd/yyyy)": "11/22/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "571.95",
              "Guar. Portion Principal": "1176.94",
              "Guar. Portion Pymt or Fee ": "1748.89",
              "From \n(mm/dd/yyyy)": "10/07/2023",
              "To \n(mm/dd/yyyy)": "10/23/2023",
              "# of Days": "16",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "126116.18",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111113",
              "Lender Loan Number": "1111113",
              "Next Installment Due Date (mm/dd/yyyy)": "11/17/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "84.81",
              "Guar. Portion Principal": "201.21",
              "Guar. Portion Pymt or Fee ": "286.02",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/17/2023",
              "# of Days": "15",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "19932.15",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111114",
              "Lender Loan Number": "1111114",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "158.69",
              "Guar. Portion Principal": "120.44",
              "Guar. Portion Pymt or Fee ": "279.13",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/30/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "20061.29",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111115",
              "Lender Loan Number": "1111115",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "158.69",
              "Guar. Portion Principal": "120.44",
              "Guar. Portion Pymt or Fee ": "279.13",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/30/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "20061.29",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111116",
              "Lender Loan Number": "1111116",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "158.69",
              "Guar. Portion Principal": "120.44",
              "Guar. Portion Pymt or Fee ": "279.13",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/30/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "20061.29",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111117",
              "Lender Loan Number": "1111117",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "1414.82",
              "Guar. Portion Principal": "1046.98",
              "Guar. Portion Pymt or Fee ": "2461.80",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/30/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "178887.10",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111118",
              "Lender Loan Number": "1111118",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "160.35",
              "Guar. Portion Principal": "118.66",
              "Guar. Portion Pymt or Fee ": "279.01",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/30/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "20273.84",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111119",
              "Lender Loan Number": "1111119",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "163.50",
              "Guar. Portion Principal": "115.22",
              "Guar. Portion Pymt or Fee ": "278.72",
              "From \n(mm/dd/yyyy)": "10/02/2023",
              "To \n(mm/dd/yyyy)": "10/31/2023",
              "# of Days": "29",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "19960.87",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111120",
              "Lender Loan Number": "1111120",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "140.92",
              "Guar. Portion Principal": "139.57",
              "Guar. Portion Pymt or Fee ": "280.49",
              "From \n(mm/dd/yyyy)": "10/06/2023",
              "To \n(mm/dd/yyyy)": "10/31/2023",
              "# of Days": "25",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "19932.55",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111811",
              "Lender Loan Number": "1111811",
              "Next Installment Due Date (mm/dd/yyyy)": "11/23/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "11.25%",
              "Guar. Portion Interest": "260.41",
              "Guar. Portion Principal": "0.00",
              "Guar. Portion Pymt or Fee ": "0.00",
              "From \n(mm/dd/yyyy)": "08/25/2023",
              "To \n(mm/dd/yyyy)": "09/22/2023",
              "# of Days": "28",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "30175.00",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111812",
              "Lender Loan Number": "1111812",
              "Next Installment Due Date (mm/dd/yyyy)": "11/23/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "10.25%",
              "Guar. Portion Interest": "144.05",
              "Guar. Portion Principal": "0.00",
              "Guar. Portion Pymt or Fee ": "144.05",
              "From \n(mm/dd/yyyy)": "09/22/2023",
              "To \n(mm/dd/yyyy)": "10/09/2023",
              "# of Days": "17",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "30175.00",
              "Remittance Penalty (if any)": "0.00",
            },
            {
              "SBA GP Number (ten digits only)": "1111813",
              "Lender Loan Number": "1111813",
              "Next Installment Due Date (mm/dd/yyyy)": "11/30/2023",
              "Status (4 - 9 or blank if N/A)": null,
              "Amount Disbursed this Period on Total Loan": null,
              "Amount Undisbursed on Total Loan": null,
              "Interest Rate (%)": "11.25%",
              "Guar. Portion Interest": "1374.90",
              "Guar. Portion Principal": "0.00",
              "Guar. Portion Pymt or Fee ": "0.00",
              "From \n(mm/dd/yyyy)": "09/08/2023",
              "To \n(mm/dd/yyyy)": "10/18/2023",
              "# of Days": "40",
              "Calendar Basis": "365",
              "Guar. Portion Closing Balance": "111520.00",
              "Remittance Penalty (if any)": "0.00",
            },
          ],
        },
        fileSize: 35516,
      },
    ],
    noOfLoans: 13,
    portfolios: {
      id: "e42aeea0-e1b1-4324-99fe-43ae95ce3535",
      userId: "666ff11c4349464d4f78f549",
      assetId: "d636c03c-4123-47cc-88dd-cf128f40de61",
      quantity: 0,
      lastTradedPrice: "7703.7143",
      createdAt: "2024-06-25T05:43:52.546Z",
      updatedAt: "2024-06-25T05:43:52.591Z",
      deletedAt: null,
    },
    issuerDetails: {
      _id: "666ff11c4349464d4f78f549",
      firstName: "Manmohan",
      lastName: "Ujala",
    },
    ownerDetails: {
      _id: "666ff11c4349464d4f78f549",
      firstName: "Manmohan",
      lastName: "Ujala",
    },
  },
};

export const FILE_EXTENSION_URL: any = {
  pdf: "https://storage.googleapis.com/satschel-assets-public/assets/logo/pdf-logo-c001.svg",
  doc: "https://storage.googleapis.com/satschel-assets-public/assets/logo/doc-logo-c001.svg",
  docx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/docx-logo-c001.svg",
  csv: "https://storage.googleapis.com/satschel-assets-public/assets/logo/csv-logo-c001.svg",
  ppt: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DPPT.png",
  xls: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLS.png",
  xlsx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLSX.png",
  png: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1PNG.png",
  jpeg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPEG.png",
  jpg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPG.png",
};
// AUCITON NAME -STRING
// CREATED BY - STRING
// START PRICE - STRING
// START DATE AND TIME - STRING
// AUCTION ENDS IN  -- JSX
// SUPPORTING DOCUMENTS -- JSX
// ACTIONS ---JSX

export const dataFilter = [
  {
    header: "Type",
    key: "sbaType",
    options: [
      { label: "Individual", value: "INDIVIDUAL" },
      { label: "Pool", value: "POOL" },
      { label: "Orderbook Series", value: "ORDERBOOK_SERIES" },
    ],
  },
];


export const ASSET_TYPE =  "manufactured_home_loans";

export const ASSET_TYPE_TEXT ="Manufactured home loan";
